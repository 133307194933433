import axios from "axios";
import { API } from "../config/api";

axios.defaults.headers.common["authorization"] = process.env.REACT_APP_BASIC_AUTH;
let token = localStorage.getItem("token");
if(token){
axios.defaults.headers.common["x-access-token"] = token;
}
const headersApplicationJson = {
  "Content-Type": "application/json",
};

const axiosHeadersApplicationJson = {
  "Content-Type": "application/json",
  "x-access-token": localStorage.getItem("token"),
};

// Login Service

export const adminLoginService = (params) =>
  axios.post(API.ADMIN_LOGIN, params, { headers: headersApplicationJson });

// Distributor Services
export const getDistributorService = (params) => {
  const url = new URL(`${API.GET_DISTRIBUTOR}?`);
  const searchParams = url.searchParams;
  searchParams.set("limit", params.limit);
  searchParams.set("page", params.page);
  params.search && searchParams.set("distributorName", params.search);
  params.city && searchParams.set("city", params.city);
  params.type !== "" && params.type && searchParams.set("type", params.type);
  params.sort_key !== "" &&
    params.sort_key &&
    searchParams.set("sort_key", params.sort_key);

  return axios.get(url, { headers: headersApplicationJson });
};
export const addDistributorService = (params) => {
  let token = localStorage.getItem("token");
  let header = {
    "Content-Type": "application/json",
    "x-access-token": token,
  };
  return axios.post(API.ADD_DISTRIBUTOR, params, { headers: header });
};

export const updateDistributorService = (params) => {
  let token = localStorage.getItem("token");
  let header = {
    "Content-Type": "application/json",
    "x-access-token": token,
  };
  return axios.post(API.UPDATE_DISTRIBUTOR, params, { headers: header });
};

export const deleteDistributorService = (params) => {
  let token = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",

    "x-access-token": token,
  };
  return axios
    .post(API.DELETE_DISTRIBUTOR, params, { headers: headers })
    .then((res) => {
      if (
        res.data.error.errorCode === 2 &&
        res.data.error.responseMessage === "Unauthorized access."
      ) {
        localStorage.removeItem("token");
        window.location.href = "/";
      } else {
        return res;
      }
    });
};

// Baker Services

export const getBakerService = (params) => {
  const url = new URL(`${API.GET_BAKER}?`);
  const searchParams = url.searchParams;
  params && params.limit && searchParams.set("limit", params.limit);
  params && params.page && searchParams.set("page", params.page);
  params && params.search && searchParams.set("bakeryList", params.search);
  params && params.type && searchParams.set("type", params.type);
  params && params.sort_key && searchParams.set("sort_key", params.sort_key);

  return axios.get(url, { headers: headersApplicationJson })
 

};


  

export const deleteBakerService = (params) => {
  let token = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",

    "x-access-token": token,
  };
  return axios
    .post(API.DELETE_BAKER, params, { headers: headers })
    .then((res) => {
      if (
        res.data.error.errorCode === 2 &&
        res.data.error.responseMessage === "Unauthorized access."
      ) {
        localStorage.removeItem("token");
        window.location.href = "/";
      } else {
        return res;
      }
    });
};
export const addBakerService = (params) =>
  axios.post(API.ADD_BAKER, params, { headers: headersApplicationJson });

export const editBakerService = (params, id) =>
  axios.post(API.UPDATE_BAKER, params, { headers: headersApplicationJson });

// Feedback Services
export const getFeedbackService = (params) => {
  let token = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",

    "x-access-token": token,
  };
  return axios.post(API.GET_FEEDBACK, params, { headers: headers }).then((res) => {
    if (
      res.data.error.errorCode === 2 &&
      res.data.error.responseMessage === "Unauthorized access."
    ) {
      localStorage.removeItem("token");
      window.location.href = "/";
    } else {
      return res;
    }
  });
};

// Product Services
export const getProductService = (params) => {
  let token = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",

    "x-access-token": token,
  };
  // const url = new URL(`${API.GET_PRODUCT}?`);
  // const searchParams = url.searchParams;
  // searchParams.set('limit',params.limit)
  // searchParams.set('page',params.page)
  return axios.post(API.GET_PRODUCT, params, { headers: headers });
};

export const deleteProductService = (params) => {
  let token = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",

    "x-access-token": token,
  };
  return axios.post(API.DELETE_PRODUCT, params, { headers: headers });
};




export const addProductService = (params) => {
  let token = localStorage.getItem("token");
  let headers = {
    "Content-Type": "multipart/form-data",

    "x-access-token": token,
  };
  return axios.post(API.ADD_PRODUCT, params, { headers: headers });
};

export const updateProductService = (params) => {
  let token = localStorage.getItem("token");
  let headers = {
    "Content-Type": "multipart/form-data",

    "x-access-token": token,
  };
  return axios.post(API.UPDATE_PRODUCT, params, { headers: headers });
};

// Recipe Services
export const getRecipeService = (params) => {
  let token = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",

    "x-access-token": token,
  };
  return axios.post(API.GET_RECIPE, params, { headers: headers });
};

export const addRecipeService = (params) => {
  let token = localStorage.getItem("token");
  let headers = {
    "Content-Type": "multipart/form-data",

    "x-access-token": token,
  };
  return axios.post(API.ADD_RECIPE, params, { headers: headers });
};

export const deleteRecipeService = (params) => {
  let token = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",

    "x-access-token": token,
  };
  return axios.post(API.DELETE_RECIPE, params, { headers: headers });
};

export const updateRecipeService = (params) => {
  let token = localStorage.getItem("token");
  let headers = {
    "Content-Type": "multipart/form-data",

    "x-access-token": token,
  };
  return axios.post(API.UPDATE_RECIPE, params, { headers: headers });
};

// Product Category Services

export const addProductCategoryService = (params) => {
  let token = localStorage.getItem("token");
  let headers = {
    "Content-Type": "multipart/form-data",

    "x-access-token": token,
  };
  return axios.post(API.ADD_PRODUCT_CATEGORY, params, { headers: headers });
};
export const getProductCategoryDetailsService = (params) => {
  return axios.post(API.GET_PRODUCT_CATEGORY_DETAILS, params, {
    headers: headersApplicationJson,
  });
};

export const updateProductCategoryService = (params) => {
  let token = localStorage.getItem("token");
  let headers = {
    "Content-Type": "multipart/form-data",

    "x-access-token": token,
  };
  return axios.post(API.UPDATE_PRODUCT_CATEGORY, params, { headers: headers });
};

export const uploadFileService = (params) => {
  let token = localStorage.getItem("token");
  let headers = {
    "Content-Type": "multipart/form-data",

    "x-access-token": token,
  };
  return axios.post(API.UPLOAD_FILE, params, { headers: headers });
};

export const uploadCakeDsignFileService = (params) => {
  let token = localStorage.getItem("token");
  let headers = {
    "Content-Type": "multipart/form-data",

    "x-access-token": token,
  };
  return axios.post(API.CAKE_DESIGN_UPLOAD_FILE, params, { headers: headers });
};

export const uploadToolkitFileService = (params) => {
  let token = localStorage.getItem("token");
  let headers = {
    "Content-Type": "multipart/form-data",

    "x-access-token": token,
  };
  return axios.post(API.UPLOAD_TOOLKIT_FILE, params, { headers: headers });
};


export const deleteCakeDsignFileService = (params) => {
  let token = localStorage.getItem("token");
  let headers = {
    "Content-Type": "multipart/form-data",

    "x-access-token": token,
  };
  return axios.delete(API.CAKE_DESIGN_DELETE_FILE + params, { headers: headers });
};

export const getProductCategoryService = (params = {}) => {
  let headers = {
    "Content-Type": "application/x-www-form-urlencoded",
  };
  if (params.hasOwnProperty("limit")) {
    let url = new URL(`${API.GET_PRODUCT_CATEGORY}?`);
    const searchParams = url.searchParams;
    searchParams.set("limit", params.limit);
    searchParams.set("page", params.page);
    params.search && searchParams.set("search", params.search);
    params.type && searchParams.set("type", params.type);
    params.sort_key && searchParams.set("sort_key", params.sort_key);
    return axios.post(url, searchParams, { headers: headers });
  } else {
    let url = new URL(`${API.GET_PRODUCT_CATEGORY}`);
    return axios.post(url, { headers: headers });
  }
};

export const deleteProductCategoryService = (params) => {
  let token = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",

    "x-access-token": token,
  };
  return axios.post(API.DELETE_PRODUCT_CATEGORY, params, { headers: headers });
};

// Pillsbury chef Services

export const getPillsburyChefsService = (params) => {
  let headers = {
    "Content-Type": "application/json",
  };
  const url = new URL(`${API.GET_PILLSBURY_CHEF}?`);
  const searchParams = url.searchParams;
  params && params.limit && searchParams.set("limit", params.limit);
  params && params.page && searchParams.set("page", params.page);
  params && params.search && searchParams.set("name", params.search);
  params && params.type && searchParams.set("type", params.type);
  params && params.sort_key && searchParams.set("sort_key", params.sort_key);

  return axios.get(url, { headers: headers });
};

export const addPillsburyChefsService = (params) => {
  let token = localStorage.getItem("token");
  let headers = {
    "Content-Type": "multipart/form-data",

    "x-access-token": token,
  };
  return axios.post(API.ADD_PILLSBURY_CHEF, params, { headers: headers });
};

export const updatePillsburyChefsService = (params) => {
  let token = localStorage.getItem("token");
  let headers = {
    "Content-Type": "multipart/form-data",

    "x-access-token": token,
  };
  return axios.post(API.UPDATE_PILLSBURY_CHEF, params, { headers: headers });
};

export const deleteChefService = (params) => {
  let token = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",

    "x-access-token": token,
  };
  return axios.post(API.DELETE_PILLSBURY_CHEF, params, { headers: headers });
};

// Notification Services

export const getNotificationService = (params) => {
  let token = localStorage.getItem("token");

  let headers = {
    "Content-Type": "application/json",
    "x-access-token": token,
  };

  const url = new URL(`${API.GET_NOTIFICATION}?`);
  const searchParams = url.searchParams;
  searchParams.set("limit", params.limit);
  searchParams.set("page", params.page);

  return axios.post(url,params, { headers: headers });
};

export const sendNotificationService = (params) => {
  let token = localStorage.getItem("token");

  let headers = {
    "Content-Type": "application/json",
    "x-access-token": token,
  };
  return axios.post(API.SEND_NOTIFICATION, params, { headers: headers });
};

export const deleteNotificatiobService = (params) => {
  let token = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",

    "x-access-token": token,
  };
  return axios.post(API.DELETE_NOTIFICATION, params, { headers: headers });
};

// User Service
export const getUserListService = (query) => {
  let token = localStorage.getItem("token");

  let headers = {
    "Content-Type": "application/json",
    "x-access-token": token,
  };
  return axios.post(API.GET_USERS, query, { headers: headers }).then((res) => {
    if (
      res.data.error.errorCode === 2 &&
      res.data.error.responseMessage === "Unauthorized access."
    ) {
      localStorage.removeItem("token");
      window.location.href = "/";
    } else {
      return res;
    }
  });
};

// Used bakery Service

export const getUsedBakeryService = (params) =>
  axios.post(API.GET_USED_BAKERY);




// Get city service

export const getCityService = (params) =>
  axios.post(API.GET_CITY, {}, { headers: headersApplicationJson });

// Dashboard Services

export const getAnalysisCountService = (params) =>
  axios.post(API.GET_ANALYSIS_COUNT, {}, { headers: headersApplicationJson });

export const getDashboardCountService = (params) =>
  axios.post(API.GET_DASHBOARD_COUNT, params, { headers: headersApplicationJson });

export const getDashboardCountForUserService = (params) =>
  axios.post(
    API.GET_DASHBOARD_COUNT_FOR_USER,
    {},
    { headers: headersApplicationJson }
  );
export const getDashboardRecipeVideoPlayCountService = (params) =>
  axios.post(
    API.GET_DASHBOARD_RECIPE_PLAY_COUNT,
    {},
    { headers: headersApplicationJson }
  );

export const getDashboardSlotCountService = (params) =>
  axios.post(
    API.GET_DASHBOARD_SLOT_COUNT,
    {},
    { headers: headersApplicationJson }
  );

export const getRecipeDashBoardDataService = (params) =>
  axios.post(
    API.GET_RECIPE_DASHBOARD_DATA,
    params,
    { headers: headersApplicationJson }
  );

  


export const getProductDashBoardDataService = (params) =>
  axios.post(
    API.GET_PRODUCT_DASHBOARD_DATA,
    params,
    { headers: headersApplicationJson }
);


export const getMasterDataService = (params) => axios.get(API.GET_MASTER_DATA + params, { headers: headersApplicationJson });

export const getAppointmentDataService = (params) => axios.post(API.GET_APPOINTMENT_DATA, params, { headers: headersApplicationJson });

export const getChurnRateService = (params) => axios.get(API.GET_CHURN_RATE + params, { headers: headersApplicationJson });

export const getRetentionService = (params) => axios.get(API.GET_RETENTION_RATE + params, { headers: headersApplicationJson });

export const getGrowthRateService = (params) => axios.get(API.GET_GROWTH_RATE + params, { headers: headersApplicationJson });

export const getDashboardSlotCountMonthlyService = () => axios.post(API.GET_DASHBOARD_SLOT_COUNT_MONTHLY , { headers: headersApplicationJson });


export const getASMListService = (query) => {
  return axios
    .post(API.GET_ASM, query, { headers: axiosHeadersApplicationJson })
    .then((res) => {
      if (
        res.data.error.errorCode === 2 &&
        res.data.error.responseMessage === "Unauthorized access."
      ) {
        localStorage.removeItem("token");
        window.location.href = "/";
      } else {
        return res;
      }
    });
};

export const createASMService = (params) => {
  return axios
    .post(API.CREATE_ASM, params, { headers: axiosHeadersApplicationJson })
    .then((res) => {
      if (
        res.data.error.errorCode === 2 &&
        res.data.error.responseMessage === "Unauthorized access."
      ) {
        localStorage.removeItem("token");
        window.location.href = "/";
      } else {
        return res;
      }
    });
};

export const updateASMService = (params) => {
  return axios
    .post(API.UPDATE_ASM, params, { headers: axiosHeadersApplicationJson })
    .then((res) => {
      if (
        res.data.error.errorCode === 2 &&
        res.data.error.responseMessage === "Unauthorized access."
      ) {
        localStorage.removeItem("token");
        window.location.href = "/";
      } else {
        return res;
      }
    });
};

export const deleteASMService = (params) => {
  return axios
    .post(API.DELETE_ASM, params, { headers: axiosHeadersApplicationJson })
    // .then((res) => {
    //   if (
    //     res.data.error.errorCode === 2 &&
    //     res.data.error.responseMessage === "Unauthorized access."
    //   ) {
    //     localStorage.removeItem("token");
    //     window.location.href = "/";
    //   } else {
    //     return res;
    //   }
    // });
};
// City service
export const getCityListService = (query) => {
  return axios
    .post(API.GET_CITY, query, { headers: axiosHeadersApplicationJson })
    .then((res) => {
      if (
        res.data.error.errorCode === 2 &&
        res.data.error.responseMessage === "Unauthorized access."
      ) {
        localStorage.removeItem("token");
        window.location.href = "/";
      } else {
        return res;
      }
    });
};

export const createCityService = (params) => {
  return axios
    .post(API.CREATE_CITY, params, { headers: axiosHeadersApplicationJson })
    .then((res) => {
      if (
        res.data.error.errorCode === 2 &&
        res.data.error.responseMessage === "Unauthorized access."
      ) {
        localStorage.removeItem("token");
        window.location.href = "/";
      } else {
        return res;
      }
    });
};

export const updateCityService = (params) => {
  return axios
    .post(API.UPDATE_CITY, params, { headers: axiosHeadersApplicationJson })
    .then((res) => {
      if (
        res.data.error.errorCode === 2 &&
        res.data.error.responseMessage === "Unauthorized access."
      ) {
        localStorage.removeItem("token");
        window.location.href = "/";
      } else {
        return res;
      }
    });
};

export const deleteCityService = (params) => {
  return axios
    .post(API.DELETE_CITY, params, { headers: axiosHeadersApplicationJson })
    .then((res) => {
      if (
        res.data.error.errorCode === 2 &&
        res.data.error.responseMessage === "Unauthorized access."
      ) {
        localStorage.removeItem("token");
        window.location.href = "/";
      } else {
        return res;
      }
    });
};

// ASM service

export const getSOListService = (query) => {
  return axios
    .post(API.GET_SO, query, { headers: axiosHeadersApplicationJson })
    .then((res) => {
      if (
        res.data.error.errorCode === 2 &&
        res.data.error.responseMessage === "Unauthorized access."
      ) {
        localStorage.removeItem("token");
        window.location.href = "/";
      } else {
        return res;
      }
    });
};

export const createSOService = (params) => {
  return axios
    .post(API.CREATE_SO, params, { headers: axiosHeadersApplicationJson })
    .then((res) => {
      if (
        res.data.error.errorCode === 2 &&
        res.data.error.responseMessage === "Unauthorized access."
      ) {
        localStorage.removeItem("token");
        window.location.href = "/";
      } else {
        return res;
      }
    });
};

export const updateSOService = (params) => {
  return axios
    .post(API.UPDATE_SO, params, { headers: axiosHeadersApplicationJson })
    .then((res) => {
      if (
        res.data.error.errorCode === 2 &&
        res.data.error.responseMessage === "Unauthorized access."
      ) {
        localStorage.removeItem("token");
        window.location.href = "/";
      } else {
        return res;
      }
    });
};



// Faq services

export const getFaqService = (params) => {
  const url = new URL(`${API.GET_FAQ}?`);
  const searchParams = url.searchParams;
  searchParams.set("limit", params.limit);
  searchParams.set("page", params.page);
  params.search && searchParams.set("search", params.search);
  params.type && searchParams.set("type", params.type);
  params.sort_key && searchParams.set("sort_key", params.sort_key);

  let headers = {
    "Content-Type": "application/x-www-form-urlencoded",
  };
  return axios.post(url, searchParams, { headers: headers });
};

export const addFaqService = (params) => {
  let token = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",

    "x-access-token": token,
  };
  return axios.post(API.ADD_FAQ, params, { headers: headers });
};

export const deleteFaqService = (params) => {
  let token = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",

    "x-access-token": token,
  };
  return axios.post(API.DELETE_FAQ, params, { headers: headers });
};

export const updateFaqService = (params) => {
  let token = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",

    "x-access-token": token,
  };
  return axios.post(API.UPDATE_FAQ, params, { headers: headers });
};



export const translateContentService = (params) => {
  let token = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",

    "x-access-token": token,
  };
  return axios.post(API.TRANSLATE_CONTENT, params, { headers: headers });
};


export const getProductByIdService = (params) => {
  let token = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",

    "x-access-token": token,
  };
  return axios.post(API.GET_PRODUCT_BY_ID, params, { headers: headers });
};


export const getDesignCategoryService = (params) => {
  let token = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",

    "x-access-token": token,
  };
  return axios.get(API.GET_DESIGN_CATEGORY + params, { headers: headers });
};


export const getDesignCategoryListService = (params) => {
  let token = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",

    "x-access-token": token,
  };
  return axios.get(API.GET_DESIGN_CATEGORY_LIST + params, { headers: headers });
};

export const getToolkitDesignCategoryService = (params) => {
  let token = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",

    "x-access-token": token,
  };
  return axios.get(API.GET_TOOLKIT_DESIGN_CATEGORY + params, { headers: headers });
};


export const getDesignSubCategoryService = (params) => {
  let token = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",

    "x-access-token": token,
  };
  return axios.get(API.GET_DESIGN_SUB_CATEGORY + params, { headers: headers });
};


export const createDesignCategoryService = (params) => {
  let token = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",

    "x-access-token": token,
  };
  return axios.post(API.CREATE_DESIGN_CATEGORY, params, { headers: headers });
};

export const createToolkitDesignCategoryService = (params) => {
  let token = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",

    "x-access-token": token,
  };
  return axios.post(API.CREATE_TOOLKIT_DESIGN_CATEGORY, params, { headers: headers });
};


export const createDesignSubCategoryService = (params) => {
  let token = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",

    "x-access-token": token,
  };
  return axios.post(API.CREATE_DESIGN_SUB_CATEGORY, params, { headers: headers });
};


export const deleteDesignCategoryService = (params) => {
  let token = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",

    "x-access-token": token,
  };
  return axios.post(API.DELETE_DESIGN_CATEGORY, params, { headers: headers });
};

export const deleteToolkintDesignCategoryService = (params) => {
  let token = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",

    "x-access-token": token,
  };
  return axios.post(API.DELETE_TOOLKIT_DESIGN_CATEGORY, params, { headers: headers });
};

export const getDesignCategoryDetailService = (params) => {
  let token = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",

    "x-access-token": token,
  };
  return axios.get(API.DESIGN_CATEGORY_DETAIL + params, { headers: headers });
};

export const getToolkitDesignCategoryDetailService = (params) => {
  let token = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",

    "x-access-token": token,
  };
  return axios.get(API.TOOLKIT_DESIGN_CATEGORY_DETAIL + params, { headers: headers });
};


export const getDropDownCatsService = (params) => {
  let token = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",

    "x-access-token": token,
  };
  return axios.get(API.GET_DROP_DWONS_CAT + params, { headers: headers });
};

export const getDropDownCatsForToolkitService = (params) => {
  let token = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",

    "x-access-token": token,
  };
  return axios.get(API.GET_DROP_DWONS_CAT_FOR_TOOLKIT + params, { headers: headers });
};


export const getProductUsedListService = (params) => {
  let token = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",

    "x-access-token": token,
  };
  return axios.get(API.GET_PRODUCT_USED_LIST + params, { headers: headers });
};


export const getCakeDesignsService = (params) => {
  let token = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",

    "x-access-token": token,
  };
  return axios.post(API.GET_CAKE_DESIGNS, params, { headers: headers });
};


export const updateDesignCategoryService = (params) => {
  let token = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",

    "x-access-token": token,
  };
  return axios.post(API.UPDATE_DESIGN_CATEGORY, params, { headers: headers });
};

export const updateToolkitDesignCategoryService = (params) => {
  let token = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",

    "x-access-token": token,
  };
  return axios.post(API.UPDATE_TOOLKIT_DESIGN_CATEGORY, params, { headers: headers });
};

export const updateStatusDesignCategoryService = (params) => {
  let token = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",

    "x-access-token": token,
  };
  return axios.post(API.UPDATE_STATUS_DESIGN_CATEGORY, params, { headers: headers });
};

export const updateStatusToolkitDesignCategoryService = (params) => {
  let token = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",

    "x-access-token": token,
  };
  return axios.post(API.UPDATE_STATUS_TOOLKIT_DESIGN_CATEGORY, params, { headers: headers });
};


export const deleteDesignSubCategoryService = (params) => {
  let token = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",

    "x-access-token": token,
  };
  return axios.post(API.DELETE_DESIGN_SUB_CATEGORY, params, { headers: headers });
};


export const createCakeDesignService = (params) => {
  let token = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",

    "x-access-token": token,
  };
  return axios.post(API.CREATE_CAKE_DESIGN, params, { headers: headers });
};

export const createToolkitDesignService = (params) => {
  let token = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",

    "x-access-token": token,
  };
  return axios.post(API.CREATE_TOOLKIT_DESIGN, params, { headers: headers });
};


export const updateCakeDesignService = (params) => {
  let token = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",

    "x-access-token": token,
  };
  return axios.post(API.UPDATE_CAKE_DESIGN, params, { headers: headers });
};

export const updateToolkitDesignService = (params) => {
  let token = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",

    "x-access-token": token,
  };
  return axios.post(API.UPDATE_TOOLKIT_DESIGN, params, { headers: headers });
};

export const deleteCakeDesignService = (params) => {
  let token = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",

    "x-access-token": token,
  };
  return axios.post(API.DELETE_CAKE_DESIGN, params, { headers: headers });
};

export const deleteToolkitDesignService = (params) => {
  let token = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",

    "x-access-token": token,
  };
  return axios.post(API.DELETE_TOOLKIT_DESIGN, params, { headers: headers });
};


export const getCakeDesignDetailByIdService = (params) => {
  let token = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",

    "x-access-token": token,
  };
  return axios.get(API.GET_CAKE_DESING_DETAIL_BY_ID + params, { headers: headers });
};

export const getToolkitDesignDetailByIdService = (params) => {
  let token = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",

    "x-access-token": token,
  };
  return axios.get(API.GET_TOOLKIT_DESIGN_DETAIL + params, { headers: headers });
};

export const getToolkitDesignService = (params) => {
  let token = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",

    "x-access-token": token,
  };
  return axios.post(API.GET_TOOLKIT_DESIGN, params, { headers: headers });
};


export const getToolkitDesignDetailByIdServiceWithToken = (params, token) => {
  let headers = {
    "Content-Type": "application/json",
    "x-access-token": token,
  };
  return axios.get(API.GET_TOOLKIT_DESIGN_DETAIL + params, { headers: headers });
};


export const uploadFileServiceWithToken = (params, token) => {
  let headers = {
    "Content-Type": "multipart/form-data",
    "x-access-token": token,
  };
  return axios.post(API.UPLOAD_FILE, params, { headers: headers });
};


export const toolkitDesignFavoriteWithToken = (params, token) => {
  let headers = {
    "Content-Type": "multipart/form-data",
    "x-access-token": token,
  };
  return axios.post(API.TOOLKIT_DESIGN_FAVORITE, params, { headers: headers });
};


export const getAllSoReportService = (params) => {
  let headers = {
    "Content-Type": "multipart/form-data",
    "x-access-token": token,
  };
  return axios.get(API.GET_ALL_SO_REPORTS + params, { headers: headers });
};


export const getSoListService = (params) => {
  let headers = {
    "Content-Type": "multipart/form-data",
    "x-access-token": token,
  };
  return axios.get(API.GET_SO_LIST + params, { headers: headers });
};


export const uploadSoReportService = (params) => {
  let headers = {
    "Content-Type": "multipart/form-data",
    "x-access-token": token,
  };
  return axios.post(API.UPLOAD_SO_REPORT, params, { headers: headers });
};


export const getJCService = () => {
  let headers = {
    "Content-Type": "multipart/form-data",
    "x-access-token": token,
  };
  return axios.get(API.GET_JCS, { headers: headers });
};

export const getSoListServiceByJcNameService = params => {
  let headers = {
    "Content-Type": "application/json",
    "x-access-token": token,
  };
  return axios.post(API.GET_ALL_SO_REPORTS_BY_JC, params, { headers: headers });
};

export const addSOService = params => {
  let headers = {
    "Content-Type": "application/json",
    "x-access-token": token,
  };
  return axios.post(API.ADD_SO, params, { headers: headers });
};

export const editSOService = params => {
  let headers = {
    "Content-Type": "application/json",
    "x-access-token": token,
  };
  return axios.post(API.EDIT_SO, params, { headers: headers });
};

export const getSODetailService = params => {
  let headers = {
    "Content-Type": "application/json",
    "x-access-token": token,
  };
  return axios.get(API.GET_SO_DETAIL + params, { headers: headers });
};

export const getActiveUserByCityService = params => {
  let headers = {
    "Content-Type": "application/json",
    "x-access-token": token,
  };
  return axios.get(API.GET_ACTIVE_USER_BY_CITY + params, { headers: headers });
};

export const getActiveUserByMonthsService = params => {
  let headers = {
    "Content-Type": "application/json",
    "x-access-token": token,
  };
  return axios.get(API.GET_ACTIVE_USER_BY_MONTHS + params, { headers: headers });
};


export const deleteSOService = (params) => {
  let headers = {
    "Content-Type": "application/json",
    "x-access-token": token,
  };
  return axios.post(API.DELETE_SO, params, { headers })
};