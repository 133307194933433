
const Dropdown=({style,list,title,value,showValue,bindValue,setSelectedValue,setError})=>{

    return (
        <select
            className={style ? style : 'form-select mt-3 smaller-font options'}
            placeholder={title}  
            value={value}
            aria-label="Default select example" 
            onChange={(e) => (setSelectedValue(e.currentTarget.value), setError(""))} >
        
        <option selected>{title}</option>
            {list && list.map((e, i) => (
                <option  key={i} value={e[bindValue]} >{e[showValue]}
                </option>
            ))}
        
        </select>
    )
}

export default Dropdown