import { useEffect, useState } from "react";
import pageLoaderImage from "../../assets/images/bouncing-circles.svg";
import ArrowDown from "../../assets/images/arrow-down.svg";
import ArrowUp from "../../assets/images/arrow-up.svg";
import { getDashboardCountService, getRecipeDashBoardDataService } from "../../services/userService";



const RecipeAnalytics = () => {
    const [list, setList] = useState([]);
    const [pageLoader, setPageLoader] = useState(true);
    const [sortKey, setSortKey] = useState("");
    const [type, setType] = useState("");


    const getList = () => {
      let params = {
        sortKey,
        type
      }
        getRecipeDashBoardDataService(params).then((res) => {
            if(res.data.statusCode){
                setList(res.data.responseData.result);
            }
            setPageLoader(false)
        });
    };


    useEffect(() => {
        getList()
    }, [type])

    if(pageLoader){
      return(
          <div className="page_loader"><img src={pageLoaderImage} /></div>
      )
  }else{

    return(
        <div className="dash_section mt-5">
            <div className="">
                <div className="d-flex">
                    <h5 className="mb-0 me-2">Recipe Analytics</h5>
                </div>

                <table className="table table-responsive">
                <thead>
                    <tr className="medium-font font-14px">
                      <th>ID</th>
                      <th>
                        Recipe Name
                        <span className="arrows pointer">
                          <img onClick={() => (setSortKey("name"), setType(1))} src={ArrowUp} alt="arrow-up" height={10}width={10}/>
                          <img onClick={() => (setSortKey("name"), setType(-1))} src={ArrowDown} alt="arrow-up" height={10}width={10}/>
                        </span>
                      </th>
                      
                      {/* <th>
                        Recipe Skip Average Time(seconds)
                        <span className="arrows pointer">
                          <img onClick={() => (setSortKey("recipeSkipTime"), setType(1))} src={ArrowUp} alt="arrow-up" height={10}width={10}/>
                          <img onClick={() => (setSortKey("recipeSkipTime"), setType(-1))} src={ArrowDown} alt="arrow-up" height={10}width={10}/>
                        </span>
                      </th> */}
                      <th>
                        Recipe Text View Average Time(seconds)
                        <span className="arrows pointer">
                          <img onClick={() => (setSortKey("recipeTextViewTime"), setType(1))} src={ArrowUp} alt="arrow-up" height={10}width={10}/>
                          <img onClick={() => (setSortKey("recipeTextViewTime"), setType(-1))} src={ArrowDown} alt="arrow-up" height={10}width={10}/>
                        </span>
                      </th>
                      <th>
                        Recipe Video Play Average Time(seconds)
                        <span className="arrows pointer">
                          <img onClick={() => (setSortKey("recipeVideoPlayTime"), setType(1))} src={ArrowUp} alt="arrow-up" height={10}width={10}/>
                          <img onClick={() => (setSortKey("recipeVideoPlayTime"), setType(-1))} src={ArrowDown} alt="arrow-up" height={10}width={10}/>
                        </span>
                      </th>
                      <th>
                        Recipe Text View Count
                        <span className="arrows pointer">
                          <img onClick={() => (setSortKey("recipeTextViewCount"), setType(1))} src={ArrowUp} alt="arrow-up" height={10}width={10}/>
                          <img onClick={() => (setSortKey("recipeTextViewCount"), setType(-1))} src={ArrowDown} alt="arrow-up" height={10}width={10}/>
                        </span>
                      </th>
                      <th>
                        Recipe Video Play Count
                        <span className="arrows pointer">
                          <img onClick={() => (setSortKey("recipeVideoPlayCount"), setType(1))} src={ArrowUp} alt="arrow-up" height={10}width={10}/>
                          <img onClick={() => (setSortKey("recipeVideoPlayCount"), setType(-1))} src={ArrowDown} alt="arrow-up" height={10}width={10}/>
                        </span>
                      </th>
                      <th>
                        Recipe Notification Text View Count
                        <span className="arrows pointer">
                          <img onClick={() => (setSortKey("recipeNotificationTextViewCount"), setType(1))} src={ArrowUp} alt="arrow-up" height={10}width={10}/>
                          <img onClick={() => (setSortKey("recipeNotificationTextViewCount"), setType(-1))} src={ArrowDown} alt="arrow-up" height={10}width={10}/>
                        </span>
                      </th>
                      <th>
                        Recipe User Count
                        <span className="arrows pointer">
                          <img onClick={() => (setSortKey("recipeUserCount"), setType(1))} src={ArrowUp} alt="arrow-up" height={10}width={10}/>
                          <img onClick={() => (setSortKey("recipeUserCount"), setType(-1))} src={ArrowDown} alt="arrow-up" height={10}width={10}/>
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {list &&
                      list.map((item, index) => (
                        <tr>
                          <td>{index + 1}</td>

                          <td>{item.name}</td>
                          {/* <td>{item.recipeSkipTime}</td> */}
                          <td>{item.recipeTextViewTime}</td>
                          <td>{item.recipeVideoPlayTime}</td>
                          <td>{item.recipeTextViewCount}</td>
                          <td>{item.recipeVideoPlayCount}</td>
                          <td>{item.recipeNotificationTextViewCount}</td>
                          <td>{item.recipeUserCount}</td>
                        </tr>
                      ))}
                      {list.length ? '' : <tr><td colSpan={8} className="no_table">No Data</td></tr>}
                  </tbody>

               
                </table>
            </div>

        </div>
    )
}
}

export default RecipeAnalytics;