import { useEffect, useState } from "react";
import Dropdown from "./../../Hooks/Dropdown";
import DeleteModal from "./../../Hooks/DeleteModal";
import Table from "react-bootstrap/Table";
import EditIcon from "../../assets/images/edit (5).svg";
import DeleteIcon from "../../assets/images/trash (1).svg";
import ModalLogo from "../../assets/images/briefcase (1).svg";
import {
  getASMListService,
  getCityService,
  updateASMService,
  deleteASMService,
  createASMService,
} from "../../services/userService";
import { Modal } from "react-bootstrap";
import CrossIcon from "../../assets/images/cross_icon.svg";
import { PageLoader } from "../svg";
import ReactPaginate from "react-paginate";
import SearchIcon from "../../assets/images/search (5).svg";
import ArrowDown from "../../assets/images/arrow-down.svg";
import ArrowUp from "../../assets/images/arrow-up.svg";

const ASM = () => {
  const [list, setList] = useState("");
  const [loader, setLoader] = useState(true);

  const [createModal, setCreateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);

  const [city, setCity] = useState("");
  const [cityErr, setCityErr] = useState("");

  const [id, setId] = useState("");
  const [cityList, setCityList] = useState([]);

  const [asmName, setASMName] = useState("");
  const [asmNameErr, setASMNameErr] = useState("");
  const [updateAsmNameErr, setUpdateASMNameErr] = useState("");

  const [updateASM, setUpdateASM] = useState("");

  const [search, setSearch] = useState("");
  const limit = 10;
  const [totalCount, setTotalCount] = useState(1);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);

  const [sortKey, setSortKey] = useState("");
  const [type, setType] = useState("");
  const [recordCount, setRecordCount] = useState(0);

  localStorage.removeItem("page");

  const arrowClick = (sortType, sortOrder) => {
    setSortKey(sortType);
    setType(sortOrder);
  };

  const handlePageClick = (event) => {
    let pageNumber = event.selected + 1;

    setPage(event.selected + 1);
    getASMList({
      limit: limit,
      page: pageNumber,
      ...(search !== "" && search !== null && { search: search }),
      ...(type !== "" && type !== null && { type: type }),
      ...(sortKey !== "" && sortKey !== null && { sort_key: sortKey }),
    });
  };

  const handleAddASM = () => {
    setCreateModal(true);
  };

  const getASMList = (query) => {
    if (!query) {
      query = {
        limit: limit,
        page: page,
        sort_key: sortKey,
        type: type,
      };
    }

    getASMListService(query).then((res) => {
      setList(res.data.responseData.result);
      setLoader(false);
      let count = res.data.responseData.count;
      setRecordCount(count);

      let numberOfPages = Math.ceil(count / 10);
      setTotalCount(numberOfPages);
    });
  };
  const getCityList = () => {
    getCityService().then((res) => {
      setCityList(res.data.responseData.result);
    });
  };

  const updateASMFunctionData = (event, key) => {
    setUpdateASM((prev) => ({ ...prev, [key]: event }));
  };

  useEffect(() => {
    getASMList();
    getCityList();
  }, [type, sortKey]);
  useEffect(() => {
    let _intervalTime;
    if (search !== "" && search !== null) {
      _intervalTime = setTimeout(() => {
        getASMList({
          limit: limit,
          page: 1,
          search: search,
        });
      }, 1500);
    } else {
      getASMList({
        limit: limit,
        page: 1,
      });
    }
    return () => {
      clearTimeout(_intervalTime);
    };
    // eslint-disable-next-line
  }, [search]);

  const deleteASM = (_id) => {
    setDeleteModal(true);
    setId(_id);
  };

  const updateASMClick = (item) => {
    setUpdateModal(true);
    setUpdateASM(item);
    setCity(item.asm_city_code);
  };

  const handleClose = () => {
    setDeleteModal(false);
    setCreateModal(false);
    setASMNameErr("");
    setCityErr("");
    setCity("");
    setASMName("");
  };

  const handleUpdateCloseFunction = (e) => {
    setUpdateModal(false);
  };

  // To prevent entering white spaces in add

  const handleKeyDown = (e) => {
    if (e.key === " " && asmName.length === 0) {
      e.preventDefault();
    }
  };

  // Add Validation
  const validation = () => {
    let validate = true;
    if (asmName == "") {
      setASMNameErr("Asm name is required");
      validate = false;
    } else if (asmName.length < 2) {
      setASMNameErr("Asm name should be between 2 to 30");
      validate = false;
    }
    if (city == "") {
      setCityErr("City is required");
      validate = false;
    }

    return validate;
  };

  // To prevent entering white spaces in update

  const handleUpdateKeyDown = (e) => {
    if (e.key === " " && updateASM.asm_name.length === 0) {
      e.preventDefault();
    }
  };

  // Update validation

  const updateValidation = () => {
    let validate = true;

    if (updateASM.asm_name === "") {
      setUpdateASMNameErr("Asm name is required");
      validate = false;
    }
    if (updateASM.asm_name.length < 2) {
      setUpdateASMNameErr("Asm name should be between 2 to 30");
      validate = false;
    }
    return validate;
  };

  //create ASM
  const handleAddClick = () => {
    if (validation()) {
      createASMService({ asm_name: asmName, asm_city_code: city }).then(
        (res) => {
          setCreateModal(false);
          getASMList();
        }
      );
    }
  };

  //delete ASM

  const handleDeleteClick = () => {
    deleteASMService({ _id: id }).then((res) => {
      setDeleteModal(false);
      getASMList();
    });
  };

  //update ASM

  const handleUpdateClick = () => {
    if (updateValidation()) {
      if (updateASM.asm_name && updateASM.asm_city_code) {
        updateASMService({
          asm_name: updateASM.asm_name,
          asm_city_code: city,
          _id: updateASM._id,
        }).then((res) => {
          setUpdateModal(false);
          getASMList();
        });
      }
    }
  };

  return (
    <>
      <div className="page_wrapper">
          <div className="container-box">

            <div className=" distributor-head">
              <div className="row mt-4">
                <div className="col-md-4">
                  <h4 className="semibold-font">ASM</h4>
                </div>

                <div className="col-md-5 ">
                  <img src={SearchIcon} className="search-icon" />

                  <input
                    type="text"
                    placeholder="Search by name"
                    name="search"
                    className=" search box-shadow light-italic-font"
                    value={search}
                    onChange={(e) => (setSearch(e.target.value), setPage(0))}
                  ></input>
                </div>

                <div className="col-md-2  ">
                  <button
                    className="add-btn text-white box-shadow  medium-font grey-font"
                    onClick={handleAddASM}
                  >
                    Add ASM
                  </button>
                </div>
              </div>
            </div>
            <Table className="table box-shadow">
              <thead>
                <tr>
                  <th>S. No</th>
                  <th>
                    ASM Name
                    <span className="arrows pointer">
                      <img
                        src={ArrowUp}
                        alt="arrow-up"
                        height={10}
                        width={10}
                        onClick={() => arrowClick("asm_name", 1)}
                      />
                      <img
                        src={ArrowDown}
                        alt="arrow-down "
                        height={10}
                        width={10}
                        onClick={() => arrowClick("asm_name", -1)}
                      />
                    </span>
                  </th>
                  <th>
                    City
                    <span className="arrows pointer">
                      <img
                        src={ArrowUp}
                        alt="arrow-up"
                        height={10}
                        width={10}
                        onClick={() => arrowClick("asm_city_code", 1)}
                      />
                      <img
                        src={ArrowDown}
                        alt="arrow-down "
                        height={10}
                        width={10}
                        onClick={() => arrowClick("asm_city_code", -1)}
                      />
                    </span>
                  </th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {list &&
                  list.map((item, index) => (
                    <tr>
                      <td>
                        {recordCount <= 10
                          ? index + 1
                          : limit * pageCount + index + 1}
                      </td>{" "}
                      <td>{item.asm_name}</td>
                      <td>{item.asm_city_code}</td>
                      <td>
                        <img
                          src={EditIcon}
                          alt="edit"
                          onClick={() => updateASMClick(item)}
                        />
                        <img
                          src={DeleteIcon}
                          alt="delete"
                          className="dlt-icon"
                          onClick={() => deleteASM(item._id)}
                        />
                      </td>
                    </tr>
                  ))}
                {list?.length ? (
                  ""
                ) : (
                  <tr className="text-center">
                    <td></td>

                    <td className="text-end no_data">
                      <p>No Data Found</p>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            {loader ? (
              <div className="loader_data">
                <PageLoader />
              </div>
            ) : (
              ""
            )}
            {recordCount > limit ? (
              <ReactPaginate
                previousLabel={"previous"}
                nextLabel={"next"}
                breakLabel={"..."}
                pageCount={totalCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={"pagination "}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
                hrefBuilder={(page, pageCount, selected) =>
                  setPageCount(selected)
                }
              />
            ) : null}
          </div>
      </div>

      {/* Delete Product Category Modal */}
      <DeleteModal
        deleteModal={deleteModal}
        title="ASM"
        Icon={CrossIcon}
        handleClose={handleClose}
        handleDeleteClick={handleDeleteClick}
      ></DeleteModal>

      {/* Create Product Category Modal */}

      <Modal show={createModal} onHide={handleClose} centered>
        <Modal.Header closeButton className="display"></Modal.Header>

        <Modal.Body>
          <div className="modal-box">
            <div className="text-center">
              <img src={ModalLogo} />
              <h5 className="semibold-font mt-3">
                Add Area Sales Manager (Asm)
              </h5>
            </div>
            <input
              type="text"
              placeholder="ASM NAME"
              maxLength={30}
              className="form-control mt-4 smaller-font"
              value={asmName}
              onKeyDown={handleKeyDown}
              onChange={(e) => (setASMName(e.target.value), setASMNameErr(""))}
            />
            <div className="error-message">{asmNameErr ? asmNameErr : ""}</div>

            <Dropdown
              list={cityList}
              title="CITY"
              value={city}
              showValue="city_name"
              bindValue="city_name"
              setSelectedValue={setCity}
              setError={setCityErr}
            ></Dropdown>
            <div className="error-message">{cityErr ? cityErr : ""}</div>

            <button
              className="add-modal-btn mt-3  btn-padding text-white"
              onClick={handleAddClick}
            >
              ADD
            </button>
          </div>
        </Modal.Body>
      </Modal>

      {/*Update ASM modal*/}

      <Modal show={updateModal} onHide={handleUpdateCloseFunction} centered>
        <Modal.Header closeButton className="display"></Modal.Header>

        <Modal.Body>
          <div className="modal-box">
            <div className="text-center">
              <img src={ModalLogo} />
              <h5 className="semibold-font mt-3">
                Update Area Sales Manager (Asm)
              </h5>
            </div>
            <input
              type="text"
              placeholder="ASM NAME"
              className="form-control mt-4 smaller-font"
              value={updateASM.asm_name}
              onKeyDown={handleUpdateKeyDown}
              onChange={(e) => (
                updateASMFunctionData(e.target.value, "asm_name"),
                setUpdateASMNameErr("")
              )}
            />
            <div className="error-message">
              {updateAsmNameErr ? updateAsmNameErr : ""}
            </div>

            <Dropdown
              list={cityList}
              title="CITY"
              value={city}
              showValue="city_name"
              bindValue="city_name"
              setSelectedValue={setCity}
            ></Dropdown>

            <button
              className="add-modal-btn mt-3  update-btn-padding text-white"
              onClick={handleUpdateClick}
            >
              UPDATE
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ASM;
