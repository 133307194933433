const NotFound = () => {
    return (
        <div id="error-page">
            <div className="error-content">
                <h2 >
                    404
                </h2>
                <h4 >
                    Opps! Page not found
                </h4>
                <p>
                    Sorry, the page you're looking for doesn't exist. If you think something is broken, report a problem.
                </p>
            </div>
        </div>

    )
}
export default NotFound;