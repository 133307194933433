import { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import EditIcon from "../../assets/images/edit (5).svg";
import DeleteIcon from "../../assets/images/trash (1).svg";
import { Link, useNavigate } from "react-router-dom";
import { getProductCategoryService, deleteProductCategoryService } from "../../services/userService";
import { Modal } from "react-bootstrap";
import CrossIcon from "../../assets/images/cross_icon.svg";
import { PageLoader } from "../svg";
import SearchIcon from "../../assets/images/search (5).svg";
import ReactPaginate from "react-paginate";
import ArrowDown from "../../assets/images/arrow-down.svg";
import ArrowUp from "../../assets/images/arrow-up.svg";





const ProductCategory = () => {
    const [list, setList] = useState("");
    const [search, setSearch] = useState('');

    const [loader, setLoader] = useState(true);
    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(1);
    const [totalCount, setTotalCount] = useState(1);
    const limit = 10;

    localStorage.removeItem('page');




    const [id, setId] = useState("");

    const [showDelete, setShowDelete] = useState(false);

    const [sortKey, setSortKey] = useState('');
    const [type, setType] = useState('');
    const [recordCount, setRecordCount] = useState(0);


    const navigate = useNavigate();

    const getCategoryList = (query) => {
        if(!query){
        query = {
                "limit": limit,
                "page": page,
                "sort_key": sortKey,
                "type": type
            }
        }

        getProductCategoryService(query).then((res) => {
            let count = res.data.responseData.count;
            setRecordCount(count);


            let numberOfPages = Math.ceil(count / 10);
            setTotalCount(numberOfPages);
            setList(res.data.responseData.result)
            setLoader(false)

        })
    }

    useEffect(() => {
        getCategoryList()
    }, [page,sortKey,type])
    useEffect(() => {
        let _intervalTime;
        if (search !== "" && search !== null) {
          _intervalTime = setTimeout(() => {
            getCategoryList({
              limit: limit,
              page: 1,
              search: search,
            });
          }, 1500);
        } else {
            getCategoryList({
            limit: limit,
            page: 1,
          });
        }
        return () => {
          clearTimeout(_intervalTime);
        };
        // eslint-disable-next-line
      }, [search]);

    const deleteCategory = (e) => {
        setId(e)
        setShowDelete(true)
    }

    const handleClose = () => {
        setShowDelete(false)

    }
    const handleDelete = () => {
        deleteProductCategoryService({ _id: id }).then((res) => {
            handleClose()
            getCategoryList()
            setPage(1)

        })

    }

    const handlePageClick = (event) => {
        let pageNumber = event.selected + 1;



        setPage(event.selected + 1)
        if (search !== "" && search !== null) {
            getCategoryList({
              limit: limit,
              page: pageNumber,
              search: search,
            });
        }else{
          getCategoryList({
            limit: limit,
            page: pageNumber,
          });
          }

    };


    const arrowClick = (sortType, sortOrder) => {
        setSortKey(sortType)
        setType(sortOrder)


    }

    return (
        <>

            <div className="page_wrapper">
                    <div className="container-box">

                        <div className="search_part d-flex align-items-center justify-content-between w-100 mt-3 mb-2">
                            <h4 className="semibold-font mb-0 ms-2">CATEGORY</h4>
                            <div className="cm_saerch">
                                <img src={SearchIcon} className="search-icon" />
                                <input
                                    type="text"
                                    placeholder="Search by name"
                                    name="search"
                                    className="search box-shadow light-italic-font"
                                    value={search}
                                    onChange={(e) => (setSearch(e.target.value))} />
                            </div>
                            <div className="btn_part">
                                <Link to="/product-categories/create" className="add-btn text-white box-shadow  medium-font grey-font text-decoration-none">Add Category</Link>
                            </div>
                        </div>


                        <Table className="table box-shadow">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Icon</th>
                                    <th>Image</th>
                                    <th>Category Name
                                        <span className="arrows pointer">
                                            <img src={ArrowUp} alt="arrow-up" height={10} width={10} onClick={() => arrowClick("category", 1)} />
                                            <img src={ArrowDown} alt="arrow-down " height={10} width={10} onClick={() => arrowClick("category", -1)} />
                                        </span>
                                    </th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {list && list.map((item, index) => (
                                    <tr>
                                        <td>{recordCount <= 10  ? index + 1 : (limit * pageCount) + index + 1}</td>
                                        <td><img src={item.product_category_icon_url} height={40} width={50} /></td>
                                        <td><img src={item.product_category_image} height={40} width={50} /></td>
                                        <td>{item.category}</td>
                                        <td>
                                            <Link to={`/product-categories/edit/${item._id}`}><img src={EditIcon} alt="edit" /></Link>
                                            <img src={DeleteIcon} alt="delete" className="dlt-icon" onClick={() => deleteCategory(item._id)} />
                                        </td>

                                    </tr>
                                ))}
                                  {list?.length ? "" :
                                            <tr className="text-center">
                                                <td></td>
                                                <td className="text-end no_data">
                                                    <p>No Data Found</p>
                                                </td>
                                            </tr>
                                        }
                            </tbody>

                        </Table>
                        {loader ? <div className='loader_data'><PageLoader /></div> : ""}
                        {recordCount>limit?<ReactPaginate
                            previousLabel={"previous"}
                            nextLabel={"next"}
                            breakLabel={"..."}
                            pageCount={totalCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={3}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination "}
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                            hrefBuilder={(page, pageCount, selected) =>
                                setPageCount(selected)
                            }
                        />:null}

                    </div>
            </div>

            <Modal show={showDelete} centered>
                <Modal.Header className="display"></Modal.Header>

                <Modal.Body>
                    <div className='modal-box'>
                        <div className="text-center">
                            <img src={CrossIcon} alt="cross-icon" />
                            <h5 className="semibold-font mt-3">Are you Sure?</h5>
                            <h6 className="regular-font ">Are you sure you want to delete <br></br>this Category from the list?</h6>
                            <button className="confirm-btn mt-3 text-white" onClick={handleDelete} >YES,DELETE</button>
                            <div>
                                <button className="cancel-btn mt-2 medium-font " onClick={handleClose}>Cancel</button>
                            </div>


                        </div>

                    </div>

                </Modal.Body>
            </Modal>

        </>

    )
}


export default ProductCategory;