import { createSlice } from '@reduxjs/toolkit';
import { SLICE_NAME } from '../constants/slicesConstant';

const initialState = {
  productData: [],
  formData:{}
}

export const productSlice = createSlice({
  name: SLICE_NAME?.PRODUCT_DATA,
  initialState,
  reducers: {
    newFormData : (state,action) => {
      state.formData = {...action?.payload};
    },
    setRecipes: (state,action) => {
      state.formData = [action?.payload]
    },
    
  },
})


export const { newFormData, setRecipes } = productSlice.actions

export default productSlice.reducer