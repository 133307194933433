import { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import Table from "react-bootstrap/Table";
import EditIcon from "../../assets/images/edit (5).svg";
import DeleteIcon from "../../assets/images/trash (1).svg";
import { Modal } from "react-bootstrap";
import Gloves from "../../assets/images/gloves (2).svg";
import CrossIcon from "../../assets/images/cross_icon.svg";
import {
  editBakerService,
  getASMListService,
  getBakerService,
  getDistributorService,
} from "../../services/userService";
import {
  addBakerService,
  deleteBakerService,
  getCityService,
  getPillsburyChefsService,
} from "../../services/userService";
import Notification from "../common/Notification";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { PageLoader } from "../svg";
import ReactPaginate from "react-paginate";
import SearchIcon from "../../assets/images/search (5).svg";
import ArrowDown from "../../assets/images/arrow-down.svg";
import ArrowUp from "../../assets/images/arrow-up.svg";
import camera from "../../assets/images/camera.svg";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

function RenderDropDpown(props) {
  let JSX = [];
  if (props) {
    return (
      <select
        className="form-select mt-3 smaller-font "
        placeholder="DISTRIBUTOR ID"
        aria-label="Default select example"
        onChange={props.distributorChange}
      >
        <option selected disabled className="options">
          DISTRIBUTOR
        </option>
        {props.distributorList &&
          props.distributorList.map((e, i) => (
            <option key={i} value={e.id} style={{ overflow: "scroll" }}>
              {e.name}
            </option>
          ))}{" "}
      </select>
    );
  } else return JSX;
}
const Bakers = () => {
  const [notificationStatus, setNotificationStatus] = useState(false);
    const [notificationProps, setNotificationProps] = useState({
        message: "",
        type: "info",
    });
  const [show, setShow] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [list, setList] = useState("");
  const [distributorIdlist, setDistributorIdList] = useState("");
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [city, setCity] = useState("");
  const [asm, setAsm] = useState("");

  const [distributorId, setDistributorId] = useState("");
  const [tempDistributorId, setTempDistributorId] = useState([]);

  const [displayEdit, setDisplayEdit] = useState(false);

  const [distributorIdErr, setDistributorIdErr] = useState("");

  const [nameErr, setNameErr] = useState("");
  const [addressErr, setAddressErr] = useState("");
  const [pinCodeErr, setPinCodeErr] = useState("");
  const [cityErr, setCityErr] = useState("");
  const [chefList, setChefList] = useState("");
  const [postedBy, setPostedBy] = useState("");
  const [postedByErr, setPostedByErr] = useState("");

  const [multiSelections, setMultiSelections] = useState([]);
  const [chefListName, setChefListName] = useState([]);

  const [loader, setLoader] = useState(true);

  const [totalCount, setTotalCount] = useState(1);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [id, setId] = useState("");
  const [search, setSearch] = useState("");
  const limit = 10;

  const [cityList, setCityList] = useState("");
  const [asmList, setAsmList] = useState("");

  const [distributorList, setDistributorList] = useState("");

  const [sortKey, setSortKey] = useState("");
  const [type, setType] = useState("");
  const [reward, setReward] = useState("");
  const [rewardErr, setRewardErr] = useState("");

  const [bakeryCategory, setBakeryCategory] = useState("");
  const [showImage, setShowImage] = useState("");
  const [file, setFile] = useState("");
  const [recordCount, setRecordCount] = useState(0);

  localStorage.removeItem("page");
  const handleClose = () => {
    setShow(false);
    setShowDelete(false);
    setName("");
    setNameErr("");
    setAddress("");
    setAddressErr("");
    setPinCode("");
    setPinCodeErr("");
    setCity("");
    setCityErr("");
    setId("");
    setDistributorId("");
    setDistributorIdErr("");
    setReward("");
    setBakeryCategory("");
    setRewardErr("");
    setShowImage("");
    setPostedBy("");
    setDisplayEdit(false);
    setAsm("");
  };

  const handleAdd = () => {
    setShow(true);
  };

  const handleDelete = (e) => {
    setId(e);
    setShowDelete(true);
  };
  const bakerList = (query) => {
    if (!query) {
      query = {
        limit: limit,
        page: page,
        sort_key: sortKey,
        type: type,
      };
    }

    getBakerService(query).then((res) => {
      let count = res.data.responseData.count;
      setRecordCount(count);

      let numberOfPages = Math.ceil(count / 10);
      setTotalCount(numberOfPages);

      setLoader(false);
      setList(res.data.responseData.result);
      let response = res.data.responseData.result;
      // setChefListName(response);

      setDistributorIdList(response);
    });
  };

  useEffect(() => {
    bakerList();
  }, [type, sortKey]);

  useEffect(() => {
    let _intervalTime;

    bakerList({
      limit: limit,
      page: 1,
      ...(search !== "" && search !== null && { search: search }),
      ...(type !== "" && type !== null && { type: type }),
      ...(sortKey !== "" && sortKey !== null && { sort_key: sortKey }),
    });
    return () => {
      clearTimeout(_intervalTime);
    };
    // eslint-disable-next-line
  }, [search]);
  useEffect(() => {
    if (chefList && distributorIdlist) {
      let chefListArray = chefList.map((item) => {
        let temp = { id: item._id, name: item.name };
        return temp;
      });
      let tempDistributorIdlist = [...distributorIdlist];
      distributorIdlist.map((item, index) => {
        if (item.pills_chef_id) {
          item.pills_chef_id.map((element, i) => {
            chefListArray.map((data, j) => {
              if (data.id === element) {
                tempDistributorIdlist[index].pills_chef_id.name = data.name;
              }
            });
          });

          setChefListName(tempDistributorIdlist);
        }
      });
    }
  }, [distributorIdlist, chefList]);

  const validation = () => {
    let validate = true;
    var pinRegex = /^(\d{4}|\d{6})$/;

    if (name === "") {
      setNameErr("Name is required");
      validate = false;
    } else if (name.length < 2) {
      setNameErr("Name should be between 2 to 50");
      validate = false;
    }

    if (address === "") {
      setAddressErr("Address is required");
      validate = false;
    }
    if (city === "") {
      setCityErr("City is required");
      validate = false;
    }
    if (pinCode === "") {
      setPinCodeErr("Pin Code is required");
      validate = false;
    } else if (!pinRegex.test(pinCode)) {
      setPinCodeErr("Pin Code is not valid");
      validate = false;
    }

    if (distributorId === "" || distributorId.length === 0) {
      setDistributorIdErr("Distributor is required");
      validate = false;
    }
    if (reward.length > 5) {
      setRewardErr("Reward points should be less than 6");
      validate = false;
    }

    return validate;
  };

  function getFormData(object) {
    const formData = new FormData();
    Object.keys(object).forEach(async (key) => {
      formData.append(key, object[key]);
    });
    return formData;
  }

  const handleClick = () => {
    if (validation()) {
      let params = {
        name: name.trim(),
        address: address.trim(),
        pincode: pinCode.trim(),
        city: city.trim(),
        reward_points: reward,
        type: bakeryCategory,
        bakery_reward_icon: file,
        ...(postedBy !== "" && { pills_chef_id: postedBy }),
        ...(asm !== "" && { asm_name: asm }),
      };

      if (id) {
        params._id = id;
        if (tempDistributorId === distributorId) {
          let temp = [];
          temp.push(distributorId);
          params.distributor_ids = temp;
        }

        editBakerService(getFormData(params)).then((res) => {
          if (res.data.error.errorCode == 5) {
            setNameErr(res.data.error.errors[0].message);
          } else {
            handleClose();
            bakerList();
            setNotificationProps({
              message: "Updated successfully",
              type: "success",
            });
            setNotificationStatus(true);
          }
        });
      } else {
        let temp = [];
        temp.push(distributorId);
        params.distributor_ids = temp;

        addBakerService(getFormData(params)).then((res) => {
          if (res.data.error && res.data.error.errors) {
            setNameErr(res.data.error.errors[0].message);
            setAddressErr(res.data.error.errors[1].message);
          } else {
            handleClose();
          }

          bakerList();
        });
      }
    }
  };

  const handlePageClick = (event) => {
    let pageNumber = event.selected + 1;

    setPage(event.selected + 1);
    bakerList({
      limit: limit,
      page: pageNumber,
      ...(search !== "" && search !== null && { search: search }),
      ...(type !== "" && type !== null && { type: type }),
      ...(sortKey !== "" && sortKey !== null && { sort_key: sortKey }),
    });
  };

  const deleteBaker = () => {
    deleteBakerService({ _id: id }).then((res) => {
      if (res.data.error.errorCode == 5) {
        NotificationManager.error(
          "Bakery can not delete because it is mapped with some user"
        );
      }
      handleClose();
      bakerList();
    });
  };

  const getCityList = () => {
    getCityService().then((res) => {
      setCityList(res.data.responseData.result);
    });
  };
  useEffect(() => {
    getCityList();
  }, []);
  const getAsmList = () => {
    getASMListService().then((res) => {
      setAsmList(res.data.responseData.result);
    });
  };
  useEffect(() => {
    getAsmList();
  }, []);

  const asmChange = (e) => {
    setAsm(e.currentTarget.value);
  };

  const cityChange = (e) => {
    setCity(e.currentTarget.value);
    setPinCode("");
    setDistributorId([]);
    setDisplayEdit(false);
    setCityErr("");
    let query = {
      limit: 1000,
      page: 1,
      city: e.currentTarget.value,
    };
    getDistributorService(query).then((res) => {
      setDistributorList(res.data.responseData.result);
    });
  };

  const handleEdit = (e) => {
    setId(e.id);

    setShow(true);
    setName(e.name);
    setAddress(e.address);
    setCity(e.city);
    setPinCode(e.pincode);
    let name = e.distributor_ids.map((item) => {
      setDisplayEdit(true);
      setDistributorId(item.name);
    });
    setReward(e.reward_points);
    setBakeryCategory(e.type);
    setPostedBy(e.pills_chef_id[0]);
    setShowImage(e.bakery_reward_icon);
    setAsm(e.asm_name);
  };

  const arrowClick = (sortType, sortOrder) => {
    setSortKey(sortType);
    setType(sortOrder);
  };

  const getPillsburyChefsList = () => {
    getPillsburyChefsService().then((res) => {
      setChefList(res.data.responseData.data);
    });
  };
  useEffect(() => {
    getPillsburyChefsList();
  }, []);

  const distributorChange = (e) => {
    if (distributorId !== e.currentTarget.value) {
      setTempDistributorId(e.currentTarget.value);
    }
    setDistributorId(e.currentTarget.value);

    setDisplayEdit(false);
    setDistributorIdErr("");
  };

  const handleImageChange = (e) => {
    const file = new File([e.target.files[0]], e.target.files[0].name, {
      type: e.target.files[0].type,
    });
    setFile(file);
    setShowImage(window.URL.createObjectURL(e.target.files[0]));
  };

  const preventMinus = (e) => {
    if (e.code === "Minus") {
      e.preventDefault();
    }
  };

  const nameChange = (e) => {
    setName(e.target.value);

    setNameErr("");
  };

  const rewardChange = (evt) => {
    const { value } = evt.target;

    // check if value includes a decimal point
    if (value.match(/\./g)) {
      const [, decimal] = value.split(".");

      // restrict value to only 2 decimal places
      if (decimal?.length > 2) {
        return;
      }
    }

    setReward(value);
  };

  return (
    <>
      <div className="page_wrapper">
          <div className="container-box">
            <NotificationContainer />

            <div className=" distributor-head">
              <div className="row mt-4">
                <div className="col-md-4">
                  <h4 className="semibold-font">BAKERIES</h4>
                </div>

                <div className="col-md-5 ">
                  <img src={SearchIcon} className="search-icon" />

                  <input
                    type="text"
                    placeholder="Search by name"
                    name="search"
                    className=" search box-shadow light-italic-font"
                    value={search}
                    onChange={(e) => (setSearch(e.target.value), setPage(0))}
                  ></input>
                </div>

                <div className="col-md-2  ">
                  <button
                    className="add-btn text-white box-shadow  medium-font grey-font"
                    onClick={handleAdd}
                  >
                    Add Bakery
                  </button>
                </div>
              </div>
            </div>
            <div className="table-scroll ">
              <Table className="table box-shadow mb-0 mt-4">
                <thead>
                  <tr className="medium-font font-14px">
                    <th>ID</th>
                    <th>
                      Bakery Name
                      <span className="arrows pointer">
                        <img
                          src={ArrowUp}
                          alt="arrow-up"
                          height={10}
                          width={10}
                          onClick={() => arrowClick("name", 1)}
                        />

                        <img
                          src={ArrowDown}
                          alt="arrow-down "
                          height={10}
                          width={10}
                          onClick={() => arrowClick("name", -1)}
                        />
                      </span>
                    </th>
                    <th className="text-center">
                      Type
                      <span className="arrows pointer">
                        <img
                          src={ArrowUp}
                          alt="arrow-up"
                          height={10}
                          width={10}
                          onClick={() => arrowClick("segment", 1)}
                        />

                        <img
                          src={ArrowDown}
                          alt="arrow-down "
                          height={10}
                          width={10}
                          onClick={() => arrowClick("segment", -1)}
                        />
                      </span>
                    </th>
                    <th>
                      ASM Territory
                      <span className="arrows pointer">
                        <img
                          src={ArrowUp}
                          alt="arrow-up"
                          height={10}
                          width={10}
                          onClick={() => arrowClick("asm_territory", 1)}
                        />

                        <img
                          src={ArrowDown}
                          alt="arrow-down "
                          height={10}
                          width={10}
                          onClick={() => arrowClick("asm_territory", -1)}
                        />
                      </span>
                    </th>
                    <th>ASM Name</th>

                    <th>
                      SO Name
                      <span className="arrows pointer">
                        <img
                          src={ArrowUp}
                          alt="arrow-up"
                          height={10}
                          width={10}
                          onClick={() => arrowClick("so_name", 1)}
                        />

                        <img
                          src={ArrowDown}
                          alt="arrow-down "
                          height={10}
                          width={10}
                          onClick={() => arrowClick("so_name", -1)}
                        />
                      </span>
                    </th>
                    <th className="th_width text-center">Distributor</th>
                    <th className="text-center">Chef</th>
                    <th>Rewards Image</th>

                    <th>Rewards Points</th>

                    <th>Bakery category</th>

                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {distributorIdlist.length > 0 ? (
                    chefListName &&
                    chefListName.map((item, index) => (
                      <tr className="regular-font font-14px">
                        <td>{limit * pageCount + index + 1}</td>

                        <td>{item.name}</td>
                        <td>{item.segment}</td>
                        <td>{item.asm_territory}</td>
                        <td>{item.asm_name}</td>
                        <td>{item.so_name}</td>
                        <td className="th_width">
                          {item.distributor_ids.map((ele) => (
                            <p>{ele.name}</p>
                          ))}
                        </td>
                        <td>{item.pills_chef_name}</td>
                        <td className="text-center ">
                          <span>
                            <img
                              src={item.bakery_reward_icon}
                              height={40}
                              width={40}
                            />
                          </span>
                        </td>
                        <td className="text-center">{item.reward_points}</td>

                        <td className="text-center">{item.type}</td>

                        <td>
                          <img
                            src={EditIcon}
                            alt="edit"
                            onClick={() => handleEdit(item)}
                          />
                          <img
                            src={DeleteIcon}
                            alt="delete"
                            className="dlt-icon"
                            onClick={() => handleDelete(item.id)}
                          />
                          {/* <img src={ViewIcon} alt="view" className="dlt-icon" /> */}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className="text-center">
                      <td></td>

                      <td className="text-end no_data">
                        <p>No Data Found</p>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>

            {loader ? (
              <div className="loader_data">
                <PageLoader />
              </div>
            ) : (
              ""
            )}
            <div className="mt-4">
              {recordCount > limit ? (
                <ReactPaginate
                  previousLabel={"previous"}
                  nextLabel={"next"}
                  breakLabel={"..."}
                  pageCount={totalCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination "}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                  hrefBuilder={(page, pageCount, selected) =>
                    setPageCount(selected)
                  }
                />
              ) : null}
            </div>
          </div>
      </div>

      {/*Add Bakers Modal*/}

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton className="display"></Modal.Header>

        <Modal.Body>
          <div className="modal-box">
            <div className="text-center">
              <img src={Gloves} alt="gloves" />
              <h5 className="semibold-font mt-3">
                {id ? "Update" : "Add"} Bakery
              </h5>
            </div>
            <div className="image-box image-upload margin-auto">
              <label htmlFor="file-input">
                {showImage ? (
                  <div className="">
                    <img src={showImage} className="img-preview " />
                  </div>
                ) : (
                  <div className="camera-img left-margin-20">
                    <img src={camera} className="" />
                  </div>
                )}
              </label>
              <input
                id="file-input"
                type="file"
                accept="image/*"
                onChange={handleImageChange}
              />
              {showImage ? (
                ""
              ) : (
                <p className="smaller-font font-light mt-2 text-center">
                  Upload Image{" "}
                </p>
              )}
            </div>
            <input
              type="text"
              placeholder="BAKERY NAME"
              className="form-control mt-4 smaller-font"
              value={name}
              maxLength={50}
              onChange={nameChange}
            />
            <div className="error-message">{nameErr ? nameErr : ""}</div>

            <input
              type="text"
              placeholder="ADDRESS"
              className="form-control mt-3 smaller-font"
              maxLength={70}
              value={address}
              onChange={(e) => (setAddress(e.target.value), setAddressErr(""))}
            />
            <div className="error-message">{addressErr ? addressErr : ""}</div>

            <select
              className="form-select mt-3 smaller-font options"
              placeholder="CITY"
              aria-label="Default select example"
              onChange={cityChange}
              value={city}
            >
              <option selected>CITY </option>

              {cityList &&
                cityList.map((e, i) => (
                  <option key={i} value={e.city_name}>
                    {e.city_name}
                  </option>
                ))}
            </select>
            <div className="error-message">{cityErr ? cityErr : ""}</div>
            <input
              type="text"
              placeholder="PIN CODE"
              className="form-control mt-3 smaller-font"
              value={pinCode}
              onChange={(e) => (setPinCode(e.target.value), setPinCodeErr(""))}
            />
            <div className="error-message">{pinCodeErr ? pinCodeErr : ""}</div>

            {typeof distributorId === "string" && displayEdit === true ? (
              <select
                className="form-select mt-3 smaller-font "
                placeholder="DISTRIBUTOR ID"
                aria-label="Default select example"
              >
                <option selected className="options">
                  {distributorId}
                </option>
              </select>
            ) : typeof distributorId === "object" ? (
              <RenderDropDpown
                distributorChange={distributorChange}
                distributorList={distributorList}
              />
            ) : (
              <select
                className="form-select mt-3 smaller-font "
                placeholder="DISTRIBUTOR ID"
                aria-label="Default select example"
                value={distributorId}
                onChange={distributorChange}
              >
                <option selected disabled className="options">
                  DISTRIBUTOR
                </option>
                {distributorList &&
                  distributorList.map((e, i) => (
                    <option key={i} value={e.id} style={{ overflow: "scroll" }}>
                      {e.name}
                    </option>
                  ))}
              </select>
            )}
            <div className="error-message">
              {distributorIdErr ? distributorIdErr : ""}
            </div>

            <select
                className="form-select mt-3 smaller-font options" 
                placeholder="CHEF NAME"  
                value={postedBy}
                aria-label="Default select example" 
                onChange={(e) => setPostedBy(e.currentTarget.value)} >
            
            <option selected>CHEF NAME</option>
                {chefList && chefList.map((item, i) => (
                    <option key={i} value={item._id} >{item.name}</option>
                ))}
            </select>

            <input
              type="number"
              min="0"
              onKeyPress={preventMinus}
              placeholder="REWARD POINTS"
              className="form-control mt-3 smaller-font"
              value={reward}
              onChange={rewardChange}
            />
            <div className="error-message">{rewardErr ? rewardErr : ""}</div>

            <select
              className="form-select mt-3 smaller-font "
              placeholder="DISTRIBUTOR ID"
              aria-label="Default select example"
              value={bakeryCategory}
              onChange={(e) => setBakeryCategory(e.target.value)}
            >
              <option selected className="options">
                BAKERY CATEGORY{" "}
              </option>

              <option value="Gold" style={{ overflow: "scroll" }}>
                Gold
              </option>
              <option value="Silver" style={{ overflow: "scroll" }}>
                Silver
              </option>
              <option value="Platinum" style={{ overflow: "scroll" }}>
                Platinum
              </option>
            </select>
            <select
              className="form-select mt-3 smaller-font options"
              placeholder="ASM"
              aria-label="Default select example"
              onChange={asmChange}
              value={asm}
            >
              <option selected>ASM </option>

              {asmList &&
                asmList.map((e, i) => (
                  <option key={i} value={e.asm_name}>
                    {e.asm_name}
                  </option>
                ))}
            </select>

            {/* <input
              type="text"
              placeholder="ASM NAME"
              className="form-control mt-4 smaller-font"
              value={asm}
              onChange={asmChange}
            /> */}

            <button
              onClick={handleClick}
              className={`${
                id
                  ? "add-modal-btn mt-3  update-btn-padding text-white"
                  : "add-modal-btn mt-3  add-btn-padding text-white"
              }`}
            >
              {id ? "UPDATE" : "ADD"}{" "}
            </button>
          </div>
        </Modal.Body>
      </Modal>

      {/*Delete Baker Modal*/}

      <Modal show={showDelete} onHide={handleClose} centered>
        <Modal.Header className="display"></Modal.Header>

        <Modal.Body>
          <div className="modal-box">
            <div className="text-center">
              <img src={CrossIcon} alt="cross-icon" />
              <h5 className="semibold-font mt-3">Are you Sure?</h5>
              <h6 className="regular-font ">
                Are you sure you want to delete <br></br>this Bakery from the
                list?
              </h6>
              <button
                className="confirm-btn mt-3 text-white"
                onClick={deleteBaker}
              >
                YES,DELETE
              </button>
              <div>
                <button
                  className="cancel-btn mt-2 medium-font "
                  onClick={handleClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {notificationStatus && createPortal(<Notification {...notificationProps} />, document.querySelector("#notification-root"))}

    </>
  );
};

export default Bakers;
