import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Spinner, Table } from "react-bootstrap";
import DeleteModal from "../common/DeleteModal";
import {
  deleteProductService,
  getProductService,
} from "../../services/userService";
import ReactPaginate from "react-paginate";
import Notification from "../common/Notification";
import { createPortal } from "react-dom";
import { PageLoader } from "../svg";
import SearchIcon from "../../assets/images/search (5).svg";
import DeleteIcon from "../../assets/images/trash (1).svg";
import ViewIcon from "../../assets/images/view.svg";
import EditIcon from "../../assets/images/edit (5).svg";

const Products = () => {
  const navigate = useNavigate();
  const [totalCount, setTotalCount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(10);
  const [deleteId, setDeleteId] = useState('');
  const [page, setPage] = useState(1);
  const [recordCount, setRecordCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [notificationProps, setNotificationProps] = useState({message: "",type: "info",});
  const [notificationStatus, setNotificationStatus] = useState(false);
  localStorage.removeItem('page');


  const getProducts = async (query) => {
    try {
      setData([]);
      setLoading(true);
      let res = await getProductService(query);
      let count = res.data?.responseData.count;
      setRecordCount(count);
      if (res?.data?.statusCode === 1) {
        setLoading(false);
        let numberOfPages = Math.ceil(count / limit);
        setTotalCount(numberOfPages);
        setData(res.data.responseData.result);
      }else{
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const deleteProduct = async () => {
    if(deleteId!==''){
   try {
    let res = await deleteProductService({ _id: deleteId });
    if(res?.data?.statusCode===1){
      setDeleteId('');
      setNotificationProps({
        message: res?.data?.responseData?.message,
        type: "success",
      });
      setNotificationStatus(false);
      setDeleteId('');
      getProducts({
        limit: limit,
        page: page,
        isAdmin:1
      });
    }else{
      setNotificationProps({
        message: 'Something is wrong please try again later.',
        type: "error",
      });
      setDeleteId('');
      setNotificationStatus(true);
    }
   } catch (error) {
    setDeleteId('');
    setNotificationProps({
      message: 'Something is wrong.',
      type: "error",
    });
    setNotificationStatus(true);
   } 
  }
  };

  useEffect(() => {
    let _intervalTime;
    if (search !== "" && search !== null) {
      _intervalTime = setTimeout(() => {
        getProducts({
          limit: limit,
          page: 1,
          name: search,
          isAdmin: 1
        });
      }, 2500);
    } else {
      getProducts({
        limit: limit,
        page: 1,
        isAdmin: 1
      });
    }
    return () => {
      clearTimeout(_intervalTime);
    };
    // eslint-disable-next-line
  }, [search]);

  const handlePageClick = (event) => {
    let pageNumber = event.selected + 1;
    setPage(event.selected + 1);
    if (search !== "" && search !== null) {
      getProducts({
        limit: limit,
        page: pageNumber,
        name: search,
        isAdmin: 1
      });
  }else{
    getProducts({
      limit: limit,
      page: pageNumber,
      isAdmin: 1
    });
    }
  };

  const viewProductHandler = (data) => {
    navigate("/viewProduct", { state: data });
  };

  const handleClose = () => {
    setDeleteId('');
  };

  return (
    <>
      <div className="page_wrapper">
          <div className="container-box">
            <div className="top-head mt-3 mb-2">
               <div className="search_part d-flex align-items-center" >
                 <h4 className="semibold-font mb-0 ms-2">PRODUCTS</h4>
                 <>
                 <img src={SearchIcon} className="search-icon" alt={'search-icon'}/>
                 <input
                   type="text"
                   placeholder="Search by name"
                   name="search"
                   className="search box-shadow"
                   value={search}
                   onChange={(e) => (setSearch(e.target.value))}
                 ></input>
               </>
               </div>
               <div className="btn_part">
                  <Link to={'/products/create'} className="add-btn text-white box-shadow  medium-font grey-font text-decoration-none">
                    Add Product
                  </Link>
               </div>
            </div>

            <Table className="table box-shadow">
              <thead>
                <tr className="medium-font ">
                  <th>ID</th>
                  <th>Image</th>
                  <th>Name</th>
                  <th>Category</th>
                  <th>Type</th>
                  <th>Price</th>
                  <th>Net Weight</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {loading?<tr><td colSpan={7}><div className="text-center">
                <div className="loader_data"> <PageLoader /></div>
              </div></td></tr>:null}
                {data?.length>0 ?
                  data.map((item, index) => (
                    <tr className="regular-font font-14px">
                      <td>{page !== 0 ? (page - 1) * 10 + index + 1: index + 1}</td>
                      <td>
                        <img
                          src={item?.product_image}
                          width={50}
                          height={40}
                          alt={item?.name}
                        />
                      </td>
                      <td className="name-width">{item.name}</td>
                      <td>{item.product_category_name}</td>
                      <td>{item.dietary}</td>
                      <td>{item.price}</td>
                      <td>{item.net_weight}</td>
                      <td className="action-width">
                      <Link to={`/products/edit/${item._id}`}>
                        <img
                          src={EditIcon}
                          className="dlt-icon"
                          alt="edit"
                        />
                        </Link>
                        {(deleteId === item?._id)? (
                        <Spinner className="product-spinner " animation="border" />
                    ) : (
                      <img
                          src={DeleteIcon}
                          onClick={() => setDeleteId(item?._id)}
                          alt="delete"
                          className="dlt-icon"
                        />
                    )}
                        <Link to={`/products/detail/${item?._id}`}>
                          <img
                            src={ViewIcon}
                            alt="view"
                            className="dlt-icon" />
                        </Link>

                        <Link className="ul_btn" to={`/products/edit-language/${item._id}`}>
                          <img src={EditIcon} className="dlt-icon" alt="edit" />
                          <small>Language</small>
                        </Link>
                      </td>
                    </tr>
                  )):!loading?<tr><td colSpan={8} className="text-center">Data not found.</td></tr>:null}
              </tbody>
            </Table>
          

            {recordCount>limit?<ReactPaginate
              previousLabel={"previous"}
              nextLabel={"next"}
              breakLabel={"..."}
              pageCount={totalCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"pagination "}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
              hrefBuilder={(page, pageCount, selected) =>
                setPageCount(selected)
              }
            />:null}
          </div>
        {notificationStatus &&
          createPortal(
            <Notification {...notificationProps} />,
            document.querySelector("#notification-root")
          )}

   {deleteId!==''?createPortal(<DeleteModal 
            delete={deleteProduct}
            close={handleClose}
            status={deleteId!==''?true:false}
            message={'Are you sure you want to delete this product from the list?'}
            />,
            document.querySelector("#notification-root")
          ):null}
      </div>
    </>
  );
};

export default Products;
