import React, { useEffect } from 'react';
  import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  // minified version is also included
  // import 'react-toastify/dist/ReactToastify.min.css';

  const Notification = (props) => {

    useEffect(()=>{
        let defaultProps = {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            };

            switch (props?.type) {
                case 'warn':
                    toast.warn(props?.message,{...defaultProps});
                    break;
                case 'info':
                    toast.info(props?.message,{...defaultProps});
                    break;
                case 'success':
                    toast.success(props?.message,{...defaultProps});
                    break;
                case 'error':
                    toast.error(props?.message,{...defaultProps});
                    break;
                default:
                    toast(props?.message,{...defaultProps})
                    break;
            }
   // eslint-disable-next-line
    },[props?.message])
  

    return <ToastContainer />;
  }

export default Notification;