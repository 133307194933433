import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Select from "react-select";

import {
    uploadFileService,
    translateContentService,
    getDesignSubCategoryService,
    getDesignCategoryService,
    createCakeDesignService,
    getCakeDesignDetailByIdService,
    updateCakeDesignService
} from "../../services/userService";

import { Spinner } from "react-bootstrap";
import { getFileNameFromUrl, langOptions } from "../helper";


const DesignApprovalFormLang = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    const [name, setName] = useState('');
    const [translated_name, setTranslated_name] = useState({});
    const [product_used, setProduct_used] = useState('');
    const [translated_product_used, setTranslated_Product_used] = useState('');
    const [description, setDescription] = useState('');
    const [translated_description, setTranslated_description] = useState({});

    const [btnLoader, setBtnLoader] = useState(false);

    const [nameErr, setNameErr] = useState('');
    const [product_usedErr, setProduct_usedErr] = useState('');
    const [descriptionErr, setDescriptionErr] = useState('');
    const [activeLang, setActiveLang] = useState(langOptions[0]);



    const getCakeDesignDetail = () => {
        let query = `/?cake_id=${id}`
        getCakeDesignDetailByIdService(query).then((res) => {
            let _res = res.data.responseData.data;
            setName(_res.name);
            setTranslated_name(_res.translated_name);
            setProduct_used(_res.product_used);
            setTranslated_Product_used(_res.translated_product_used);
            setDescription(_res.description);
            setTranslated_description(_res.translated_description);

          
       
            
        })
    }


    useEffect(() => {
        setName(translated_name[activeLang.value]);
        //setProduct_used(translated_product_used[activeLang.value]);
        setDescription(translated_description[activeLang.value]); 
    }, [activeLang])


    useEffect(() => {
        if(id){
            getCakeDesignDetail()
        }
    }, [id])



    const handleValidate = () => {
        let validate = true;

        if (!name) {
            setNameErr('Name is required');
            validate = false;
        }

        // if (!product_used) {
        //     setProduct_usedErr('Product used is required');
        //     validate = false;
        // }

        if (!description) {
            setDescriptionErr('Description use is required');
            validate = false;
        }

        return validate;
    }


    const handleSubmit = async () => {
        if (handleValidate()) {
            try {
                setBtnLoader(true);



                let params = {
                    _id: id,
                    translated_name: JSON.stringify({...translated_name, [activeLang.value]: name}),
                    translated_description: JSON.stringify({...translated_description, [activeLang.value]: description}),
                }

                const _res = await updateCakeDesignService(params);
                setBtnLoader(false);
                if (_res.data.statusCode) {
                    navigate("/design-approval");
                }
                
            } catch (error) {
                console.error("Error:", error);
            }
        }
    };


 



    

    return (
        <>
            <div className="page_wrapper">
                    <div className="container-box">

                        <div className="d-flex justify-content-between align-items-center">
                            <h4 className="semibold-font mt-5">Update Design Approval Language</h4>
                            <div className="lang_select">
                                <Select
                                    options={langOptions}
                                    value={activeLang}
                                    onChange={e => setActiveLang(e)} />
                            </div>
                        </div>
                       
                        <div className="tab-box position-relative">
                            <div className="add-product-box">
                                <div className="row">
                                    <div className="col-md-1"></div>
                                    <div className="col-md-5">
                                        <div className="form-group">
                                            <label>Name</label>
                                            <input
                                                className="form-control"
                                                value={name}
                                                onChange={e => (setName(e.target.value), setNameErr(''))}
                                                type="text" />

                                            {nameErr ? <div className="error-message">{nameErr}</div> : ''}
                                        </div>

                                        {/* <div className="form-group">
                                            <label>Product Used</label>
                                            <input
                                                className="form-control"
                                                value={product_used}
                                                onChange={e => (setProduct_used(e.target.value), setProduct_usedErr(''))}
                                                type="text" />

                                            {product_usedErr ? <div className="error-message">{product_usedErr}</div> : ''}
                                        </div> */}


                                        

                                        

                                        <div className="form-group">
                                            <label>Description</label>
                                            <textarea
                                                rows="4"
                                                cols="50"
                                                placeholder="Description"
                                                className="form-control mt-2"
                                                value={description}
                                                onChange={(e) => (setDescription(e.target.value), setDescriptionErr(''))}></textarea>
                                                {descriptionErr ? <div className="cm_err">{descriptionErr}</div> : ''}
                                        </div>

                                     

                                        <div className="mt-5">
                                            <Link className="btn btn-dark me-4 px-5" to="/design-approval">CANCEL</Link>
                                            <span className="btn btn-primary me-4 px-5" onClick={btnLoader ? null : handleSubmit}>{btnLoader ? <Spinner className="spiner" animation="border" /> : ''} Save</span>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
            </div>
        </>
    )
}
export default DesignApprovalFormLang;