import React from 'react'
import { Modal } from "react-bootstrap";
import CrossIcon from "../../assets/images/cross_icon.svg";

 const DeleteModal = (props) => {
    return(
        <Modal show={props?.status} centered>
        <Modal.Header className="display"></Modal.Header>
        <Modal.Body>
          <div className="modal-box">
            <div className="text-center">
              <img src={CrossIcon} alt="cross-icon" />
              <h5 className="semibold-font mt-3">Are you Sure?</h5>
              <h6 className="regular-font ">
                {props?.message}
              </h6>
              <button
                className="confirm-btn mt-3 text-white"
                onClick={()=>props?.delete()}
              >
                YES,DELETE
              </button>
              <div>
                <button
                  className="cancel-btn mt-2 medium-font "
                  onClick={()=>props?.close()}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    )
}

export default DeleteModal;