import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Select from "react-select";

import {
    uploadFileService,
    uploadCakeDsignFileService,
    deleteCakeDsignFileService,
    translateContentService,
    getDesignSubCategoryService,
    getDesignCategoryService,
    createCakeDesignService,
    getCakeDesignDetailByIdService,
    updateCakeDesignService,
    getDropDownCatsService,
    getProductUsedListService
} from "../../services/userService";

import { Modal, Spinner } from "react-bootstrap";
import { getFileNameFromUrl } from "../helper";
import DeleteIcon from "../../assets/images/trash (1).svg";


const DesignApprovalForm = (key) => {
    const navigate = useNavigate();
    const { id } = useParams();

    const [name, setName] = useState('');
    const [translated_name, setTranslated_name] = useState({});
    const [design_url, setDesign_url] = useState('');
    const [design_url_view, setDesign_url_view] = useState('');
    const [design_urls_view, setDesign_urls_view] = useState([]);
    const [design_urls_old, setDesign_urls_old] = useState([]);
    const [designFile, setDesignFile] = useState('');
    const [product_used, setProduct_used] = useState('');
    const [translated_product_used, setTranslated_Product_used] = useState('');
    const [category_id, setCategory_id] = useState('');
    const [subcategory_id, setSubcategory_id] = useState('');
    const [description, setDescription] = useState('');
    const [translated_description, setTranslated_description] = useState({});

    const [design_urlErr, setDesign_urlErr] = useState('');

    const [catList, setCatList] = useState([]);
    const [subCatList, setSubCatList] = useState([]);
    const [btnLoader, setBtnLoader] = useState(false);


    const [nameErr, setNameErr] = useState('');
    const [product_usedErr, setProduct_usedErr] = useState('');
    const [category_idErr, setCategory_idErr] = useState('');
    const [subcategory_idErr, setSubcategory_idErr] = useState('');
    const [descriptionErr, setDescriptionErr] = useState('');

    const [productUsedList, setProductUsedList] = useState([]);
    const [productCatList, setProductCatList] = useState([]);
    const [ocassionCatList, setOcassionCatList] = useState([]);
    const [flavouredCatList, setFlavouredCatList] = useState([]);
    const [productCat, setProductCat] = useState('');
    const [productCatErr, setProductCatErr] = useState([]);
    const [ocassionCat, setOcassionCat] = useState([]);
    const [flavouredCat, setFlavouredCat] = useState([]);
    const [confirmDeleteModal, setConfirmDeleteModal] = useState('');
    const [confirmApproveModal, setConfirmApproveModal] = useState(false);


    useEffect(() => {
        const fetchCategories = async (categoryType, setState) => {
            const query = `?category_type=${categoryType}`;
            const res = await getDropDownCatsService(query);
            if (res.data.statusCode) {
                setState(res.data.responseData.data);
            }
        };
        const getProductUsedList = async (categoryType, setState) => {
            const query = `?category_type=${categoryType}`;
            const res = await getProductUsedListService(query);
            if (res.data.statusCode) {
                setState(res.data.responseData.data);
            }
        };
    
        fetchCategories(1, setProductCatList);
        fetchCategories(2, setOcassionCatList);
        fetchCategories(3, setFlavouredCatList);
        getProductUsedList(4, setProductUsedList);
    }, []);



    const getCakeDesignDetail = () => {
        const query = `/?cake_id=${id}`;
        getCakeDesignDetailByIdService(query).then((res) => {
            const responseData = res.data.responseData.data;
    
            setName(responseData.name);
            setTranslated_name(responseData.translated_name);
            setDescription(responseData.description);
            setTranslated_description(responseData.translated_description);
            setDesign_urls_old(responseData.design_url);
            setProduct_used({label: responseData.product_used.name, value: responseData.product_used._id});
    
            const sortCategories = (categories) => {
                return categories.sort((a, b) => a.label.localeCompare(b.label));
            };
    
            const productCategories = sortCategories(responseData.category
                .filter(category => category.category_type === 1)
                .map(category => ({ label: category.category_name, value: category._id }))
            );
    
            const occasionCategories = sortCategories(responseData.category
                .filter(category => category.category_type === 2)
                .map(category => ({ label: category.category_name, value: category._id }))
            );
            const flavouredCategories = sortCategories(responseData.category
                .filter(category => category.category_type === 3)
                .map(category => ({ label: category.category_name, value: category._id }))
            );
            setProductCat(productCategories[0]);
            setOcassionCat(occasionCategories);
            setFlavouredCat(flavouredCategories);
        });
    };


    useEffect(() => {
        if(id){
            getCakeDesignDetail()
        }
    }, [id])



    const handleFileChange = e => {
        let files = e.target.files;
        let fileArray = [];
    
        setDesign_urlErr('');
    
        for (let i = 0; i < files.length; i++) {
            let reader = new FileReader();
            let file = files[i];
            fileArray.push(file);
    
            reader.addEventListener('load', () => {
                setDesign_urls_view(prevState => [...prevState, reader.result]);
            }, false);
            if (file) { 
                reader.readAsDataURL(file);
            }
        }
        setDesignFile(fileArray);
    }


  
    const getDesignCategory = () => {
        let query = ``;
        getDesignCategoryService(query).then(res => {
            if(res.data.statusCode){
                setCatList(res.data.responseData.data);
            }
        })
    }

    const getDesignSubCategory = () => {
        let query = `?category_id=${category_id.value}`;
        getDesignSubCategoryService(query).then(res => {
            if(res.data.statusCode){
                setSubCatList(res.data.responseData.data);
            }
        })
    }

    useEffect(() => {
        if(category_id.value){
            getDesignSubCategory()
        }

    }, [category_id])



    useEffect(() => {
        getDesignCategory()
    }, [])




    const handleValidate = () => {
        let validate = true;

        if (!name) {
            setNameErr('Name is required');
            validate = false;
        }

        if (!product_used) {
            setProduct_usedErr('Product used is required');
            validate = false;
        }

        if (!productCat.value) {
            setProductCatErr('Product category is required');
            validate = false;
        }

        if (!description) {
            setDescriptionErr('Description is required');
            validate = false;
        }
        if (design_urls_old.length == 0 && design_urls_view.length == 0) {
            setDesign_urlErr('Photo is required');
            validate = false;
        }

        return validate;
    }


    const handleApprove = async () => {
        let params = {
            _id: id,
            status: 1
        }
        setConfirmApproveModal(false);
        const _res = await updateCakeDesignService(params);
        if (_res.data.statusCode) {
            navigate("/design-approval");
        }
    
    }


    const handleSubmit = async () => {
        if (handleValidate()) {
            try {
                setBtnLoader(true);


                const _name = await translateContentService({ text: name });
                const _translated_name = _name.data.responseData.data;


                const _description = await translateContentService({ text: description });
                const _translated_description = _description.data.responseData.data;


                let _designFile = [];

                if (designFile && designFile.length > 0) {
                    for (let i = 0; i < designFile.length; i++) {
                        const formData = new FormData();
                        formData.append("file", designFile[i]);
                        const _designFileResponse = await uploadCakeDsignFileService(formData);
                        _designFile.push(_designFileResponse.data.responseData.file_url);
                    }
                }

                let all_cats = [...ocassionCat, ...flavouredCat];
                if(productCat.value){
                    all_cats.push(productCat);
                }

                let params = {
                    name,
                    description,
                    translated_name: JSON.stringify(_translated_name),
                    translated_description: JSON.stringify(_translated_description),
                    product_used: product_used.value,
                    category_id: all_cats.map(j => j.value),
                }

                if(_designFile.length){
                    params.design_url = [..._designFile, ...design_urls_old]
                }else{
                    params.design_url = design_urls_old
                }

                if(id){
                    params._id = id
                }

                let _service = id ? updateCakeDesignService : createCakeDesignService;
                const _res = await _service(params);
                setBtnLoader(false);
                if (_res.data.statusCode) {
                    navigate("/design-approval");
                }
                
            } catch (error) {
                console.error("Error:", error);
            }
        }
    };

    const handleBannerDelete = (item, index) => {
            let _design_urls_old = [...design_urls_old];
            if(_design_urls_old.includes(item)){
                let old_index = _design_urls_old.indexOf(item)
                _design_urls_old.splice(old_index, 1);
                setDesign_urls_old(_design_urls_old);
            }else{
                let _designFile = [...designFile];
                let _design_urls_view = [...design_urls_view];

                _designFile.splice(index, 1);
                _design_urls_view.splice(index, 1);

                setDesign_urls_view(_design_urls_view);
                setDesignFile(_designFile);
            }

            setConfirmDeleteModal('')
            let query = `/?cakeDesignId=${id}&designUrlId=${item._id}`;

            deleteCakeDsignFileService(query).then(res => {

            })

       

    }


    const downloadFile = async (url) => {
        try {
          const response = await fetch(url);
          const blob = await response.blob();
      
          // Extract file name from URL
          const urlParts = url.split('/');
          const fileName = urlParts[urlParts.length - 1];
      
          // Create a link and trigger download
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } catch (error) {
          console.error('Error downloading the file', error);
        }
      };
      


    return (
        <>
            <div className="page_wrapper">
                    <div className="container-box">

                        <div className="d-flex justify-content-between align-items-center">
                            <h4 className="semibold-font mt-5">{id ? "Update" : "Add"} Design Approval</h4>
                        </div>
                       
                        <div className="tab-box position-relative">
                            <div className="add-product-box">
                                <div className="row">
                                    <div className="col-md-1"></div>
                                    <div className="col-md-5">
                                        <div className="form-group">
                                            <label>Name</label>
                                            <input
                                                className="form-control"
                                                value={name}
                                                onChange={e => (setName(e.target.value), setNameErr(''))}
                                                type="text" />

                                            {nameErr ? <div className="error-message">{nameErr}</div> : ''}
                                        </div>


                                        <div className="form-group">
                                            <label>Select Product Used</label>
                                            <Select
                                                options={productUsedList.map(i => ({label: i.name, value: i._id}))}
                                                value={product_used}
                                                onChange={e => (setProduct_used(e), setProduct_usedErr(''))} />

                                            {product_usedErr ? <div className="error-message">{product_usedErr}</div> : ''}
                                        </div>


                                        <div className="form-group">
                                            <label>Select Product Category</label>
                                            <Select
                                                options={productCatList.map(i => ({label: i.category_name, value: i._id}))}
                                                value={productCat}
                                                onChange={e => (setProductCat(e), setProductCatErr(''))} />

                                            {productCatErr ? <div className="error-message">{productCatErr}</div> : ''}
                                        </div>


                                        <div className="form-group">
                                            <label>Select Seasonal Collection</label>
                                            <Select
                                                isMulti
                                                options={ocassionCatList.map(i => ({label: i.category_name, value: i._id}))}
                                                value={ocassionCat}
                                                onChange={e => setOcassionCat(e)} />

                                            {category_idErr ? <div className="error-message">{category_idErr}</div> : ''}
                                        </div>


                                        <div className="form-group">
                                            <label>Select Flavoured Category</label>
                                            <Select
                                                isMulti
                                                options={flavouredCatList.map(i => ({label: i.category_name, value: i._id}))}
                                                value={flavouredCat}
                                                onChange={e => setFlavouredCat(e)} />

                                            {category_idErr ? <div className="error-message">{category_idErr}</div> : ''}
                                        </div>
                                        

                                        <div className="form-group">
                                            <label>Description</label>
                                            <textarea
                                                rows="4"
                                                cols="50"
                                                placeholder="Tell bakers about your product."
                                                className="form-control mt-2"
                                                value={description}
                                                onChange={(e) => (setDescription(e.target.value), setDescriptionErr(''))}></textarea>
                                                {descriptionErr ? <div className="cm_err">{descriptionErr}</div> : ''}
                                            
                                        </div>

                                       

                                        <div className="mt-5">
                                            <Link className="btn btn-dark me-4 px-5" to="/design-approval">CANCEL</Link>
                                            <span className="btn btn-primary me-4 px-5" onClick={btnLoader ? null : handleSubmit}>{btnLoader ? <Spinner className="spiner" animation="border" /> : ''} Save</span>
                                        </div>

                                    </div>

                                    <div className="col-md-5">
                                        <div className="text-end mb-3">
                                            <span onClick={() => setConfirmApproveModal(true)} className="btn btn-success">Approve</span>
                                        </div>

                                        <div className="form-group">
                                            <label>Upload Photo</label>
                                            <input
                                                multiple
                                                className="form-control"
                                                onChange={handleFileChange}
                                                type="file" />
                                                {design_urlErr ? <div className="cm_err">{design_urlErr}</div> : ''}
                                        </div>


                                        {design_urls_old.length ? design_urls_old.map((item, i) => (
                                            <div className="desgin_banner text-center" key={item._id}>
                                                <span onClick={() => setConfirmDeleteModal({item, i})}><img src={DeleteIcon} /></span>
                                                <img className="img-fluid" src={item.url} />
                                                <button className="btn btn-primary btn-sm mt-2" onClick={() => downloadFile(item.url)}>Download</button>
                                            </div>
                                        )) : ''}


                                        {design_urls_view.length ? design_urls_view.map((item, i) => (
                                            <div className="desgin_banner text-center" key={i}>
                                                <span onClick={() => handleBannerDelete(item, i)}><img src={DeleteIcon} /></span>
                                                <img className="img-fluid" src={item} />
                                                <button className="btn btn-primary btn-sm mt-2" onClick={() => downloadFile(item.url)}>Download</button>
                                            </div>
                                        )) : ''}


                                        


                                        {/* {design_url_view ? <img className="img-fluid" src={design_url_view} /> : ''} */}
                                    </div>
                                </div>
                            </div>
                        </div>


                        <Modal show={confirmApproveModal} onHide={() => setConfirmApproveModal(false)}>
                            <Modal.Header closeButton>
                            <Modal.Title>Confirmation</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>Are you sure you want to Approve this?</Modal.Body>
                            <Modal.Footer>
                                <span className="btn btn-secondary" onClick={() => setConfirmApproveModal(false)}>No</span>
                                <span className="btn btn-primary" onClick={handleApprove}>Yes</span>
                            </Modal.Footer>
                        </Modal>


                        <Modal show={confirmDeleteModal.item} onHide={() => setConfirmDeleteModal('')}>
                            <Modal.Header closeButton>
                            <Modal.Title>Confirmation</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>Are you sure you want to delete this image?</Modal.Body>
                            <Modal.Footer>
                                <span className="btn btn-secondary" onClick={() => setConfirmDeleteModal('')}>No</span>
                                <span className="btn btn-primary" onClick={() => handleBannerDelete(confirmDeleteModal.item, confirmDeleteModal.i)}>Yes</span>
                            </Modal.Footer>
                        </Modal>

                    </div>
            </div>
        </>
    )
}
export default DesignApprovalForm;