import { getFeedbackService } from "../../services/userService";
import { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import ReactStars from "react-rating-stars-component";
import { PageLoader } from "../svg";
import dateFormat from 'dateformat';




function RenderFeedBack(props) {
  let JSX = [];
  let ratingCount;
  if (props.data.length) {
    let feedback=props.data

    {
    for(let i=0;i<=feedback.length;i++){
        ratingCount=10;
        if(feedback[i]){
            if(feedback[i] && feedback[i].rating){
                ratingCount=feedback[i].rating
                ratingCount=ratingCount.toString()

            }
              JSX.push(  <>
                <div className="col-md-4 mt-3">
                  <div className="feedback-card box-shadow">
                    <div className="row">
                      <div className="col-md-10">
                        <h6>{feedback[i].type}</h6>

                        {ratingCount !==10 ? (
                          <ReactStars
                            count={5}
                            size={24}
                            key={props.starsKey}
                            isHalf={true}
                            emptyIcon={<i className="far fa-star"></i>}
                            halfIcon={<i className="fa fa-star-half-alt"></i>}
                            fullIcon={<i className="fa fa-star"></i>}
                            activeColor="#ffd700"
                            value={ratingCount}
                            a11y={false}
                            edit={false}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-md-2">
                      </div>
                    </div>
    
                    <div>
                      {feedback[i].code ? (
                        <p className="smaller-font grey-text">
                          Pro ID: {feedback[i].code}
                        </p>
                      ) : (
                        ""
                      )}
                      <p>{feedback[i].discription}</p>
                      <p className="smaller-font grey-text">
                        By: {feedback[i].user?.name}
                      </p>
                      <p className="smaller-font grey-text">On: {dateFormat(feedback[i].createdAt," dS mmmm yyyy")}</p>


                      {props.loader ? (
                        <div className="loader_data">
                          <PageLoader />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </>)
        }
      
        }
    }
  }
  return JSX

}

const Feedbacks = () => {
  const [data, setData] = useState("");
  const [totalCount, setTotalCount] = useState(1);
  const [totalData, setTotalData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(6);
  const [loader, setLoader] = useState(true);
  const [starsKey, setStarsKey] = useState(Math.random());
  localStorage.removeItem('page');


  const clearForm = () => {
    setStarsKey(Math.random()); 
   
}

  const feedbackList = () => {
    getFeedbackService().then((res) => {
      setLoader(false);
      setData(res.data.responseData);
      let totalCount = 1;
      let length = res?.data?.responseData?.length;
      totalCount = Math.ceil(length / 6);
      setTotalCount(totalCount);
      setTotalData(res?.data?.responseData);
      let data = res?.data?.responseData;
      let finalData = data.slice(pageCount, pageCount + 6);
      setData(finalData);
    });
  };

  useEffect(() => {
    feedbackList();
  }, []);

  const handlePageClick = (event) => {
    let tempData = [];
    for (let i = 0; i < totalData.length; i += itemsPerPage) {
      const chunk = totalData.slice(i, i + itemsPerPage);
      tempData.push(chunk);
    }
    setPageCount(event.selected);
    clearForm()

    setData(tempData[event.selected]);
  };
  return (
    <>
      <div className="page_wrapper">
          <div className="container-box">

            <div className=" distributor-head">
              <div className="row mt-5">
                <div className="col-md-9 ">
                  <h4 className="semibold-font">FEEDBACKS</h4>
                </div>
              </div>
              <div className="row">
                <RenderFeedBack data={data} loader={loader} starsKey={starsKey} />
              </div>
              <div className="mt-4">
                <ReactPaginate
                  previousLabel={"previous"}
                  nextLabel={"next"}
                  breakLabel={"..."}
                  pageCount={totalCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination justify-content-center"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          </div>
      </div>
    </>
  );
};
export default Feedbacks;
