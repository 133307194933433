import { useEffect, useState } from "react";
import {Table, Spinner} from "react-bootstrap";
import Select from "react-select";
import { useParams, useNavigate } from 'react-router-dom';
import TextEditor from "../common/TextEditor";

import { getProductByIdService, updateProductService } from "../../services/userService";
import { langOptions } from "../helper";



function getFormData(object) {
    const formData = new FormData();
    Object.keys(object).forEach(async (key) => {
      formData.append(key, object[key]);
    });
    return formData;
  }

const EditProduct = props => {
    let { id } = useParams();
    const navigate = useNavigate();
    const [activeLang, setActiveLang] = useState(langOptions[0]);
    const [productData, setProductData] = useState('');
    const [btnLoader, setBtnLoader] = useState(false);
    const [name, setName] = useState("");
    const [ingredients, setIngredients] = useState("");
    const [product_desc, setProduct_desc] = useState("");
    const [features_details, setFeatures_details] = useState("");
    const [preparation, setPreparation] = useState("");
    const [recipe_ingredients, setRecipe_ingredients] = useState("");
    const [nameErr, setNameErr] = useState("");
    const [product_desErr, setProduct_descErr] = useState("");

    const [totalFat, setTotalFat] = useState("");
    const [totalFatList, setTotalFatList] = useState([]);
    const [carbohydrate, setCarbohydrate] = useState("");
    const [carbohydrateList, setCarbohydrateList] = useState([]);
    const [protein, setProtein] = useState("");
    const [proteinList, setProteinList] = useState([]);
    const [sodium, setSodium] = useState("");
    const [sodiumList, setSodiumList] = useState([]);
    const [nutrition_information, setNutrition_information] = useState({});


    const getProductDetailById = () => {
        let params = {productId: id}
        
        getProductByIdService(params).then(res => {
            if(res.data.statusCode){
                setProductData(res.data.responseData.result);
            }
        });
    }


    useEffect(() => {
        if(productData?.name){

            let _nutrition_information = {...productData.nutrition_information};

                if(_nutrition_information?.totalFatList?.length){
                    let _temp = _nutrition_information.totalFatList.map(item => ({
                        ...item,
                        desc: item.desc
                    }))
                    _nutrition_information.totalFatList = _temp
                }

                if(_nutrition_information?.carbohydrateList?.length){
                    let _temp = _nutrition_information.carbohydrateList.map(item => ({
                        ...item,
                        desc: item.desc
                    }))
                    _nutrition_information.carbohydrateList = _temp
                }

                if(_nutrition_information?.sodiumList?.length){
                    let _temp = _nutrition_information.sodiumList.map(item => ({
                        ...item,
                        desc: item.desc
                    }))
                    _nutrition_information.sodiumList = _temp
                }

                if(_nutrition_information?.proteinList?.length){
                    let _temp = _nutrition_information.proteinList.map(item => ({
                        ...item,
                        desc: item.desc
                    }))
                    _nutrition_information.proteinList = _temp
                }

                setNutrition_information(_nutrition_information);


                setTotalFat(_nutrition_information?.totalFat || '');
                setCarbohydrate(_nutrition_information?.carbohydrate || '');
                setProtein(_nutrition_information?.protein || '');
                setSodium(_nutrition_information?.sodium || '');
                setTotalFatList(_nutrition_information?.totalFatList || []);
                setCarbohydrateList(_nutrition_information?.carbohydrateList || []);
                setProteinList(_nutrition_information?.proteinList || []);
                setSodiumList(_nutrition_information?.sodiumList || []);



            setName(productData.translated_name[activeLang.value]);
            setProduct_desc(productData.product_desc[activeLang.value]);
            setIngredients(productData.ingredients[activeLang.value]);
            setRecipe_ingredients(productData.recipe_ingredients[activeLang.value]);
            setPreparation((productData?.preparation?.data || productData?.preparation)[activeLang.value]);
            setFeatures_details(productData.features_details[activeLang.value]);
        }

    }, [productData, activeLang])


    useEffect(() => {
        if(id){
            getProductDetailById(); 
        }

    }, [id])


    const handleFeaturesDetailsChange = (e, i) => {
        let _features_details = [...features_details];
        _features_details[i] = e.target.value;
        setFeatures_details(_features_details)
    }

    const handleRecipeIngredientsChange = (e, i) => {
        let _recipe_ingredients = [...recipe_ingredients];
        _recipe_ingredients[i].desc = e.target.value;
        setRecipe_ingredients(_recipe_ingredients)
    }

    const handlePreparationChange = (e, i) => {
        let _preparation = [...preparation];
        _preparation[i] = e.target.value;
        setPreparation(_preparation)
    }




    const handleSaveProduct = async () => {
        setBtnLoader(true);
        let _name = {
            ...productData.translated_name,
            [activeLang.value]: name
        };
        let _product_desc = {
            ...productData.product_desc,
            [activeLang.value]: product_desc
        };

        let _features_details = {
            ...productData.features_details,
            [activeLang.value]: features_details
        };

        let _recipe_ingredients = {
            ...productData.recipe_ingredients,
            [activeLang.value]: recipe_ingredients
        };
    
        let _preparation = {
            ...productData.preparation,
            data: {
                ...productData.preparation.data,
                [activeLang.value]: preparation
            }
        };


    
        let _updateData = {
          _id: id,
          features_details: JSON.stringify(_features_details),
          recipe_ingredients: JSON.stringify(_recipe_ingredients),
          preparation: JSON.stringify(_preparation),
          translated_name: JSON.stringify(_name),
          product_desc: JSON.stringify(_product_desc),
          nutrition_information: JSON.stringify({
            ...nutrition_information,
            totalFatList,
            carbohydrateList,
            proteinList,
            sodiumList,
          }),
        };


    
    
        try {
          let res = await updateProductService(getFormData(_updateData));
          setBtnLoader(false);
          if (res?.data?.statusCode === 1) {
            navigate("/products");
          }
        } catch (error) {
          console.log(error.message, 'error.messageerror.message')
        }
      };


      const handleChange2 = (e, _get, _set, index, key) => {
        let new_get = [..._get];
        new_get[index][key][activeLang.value] = e.target.value;
        _set(new_get);

    }


  

    return (
        <div className="page_wrapper">
            <div className="container-box">
                <div className="inner_page">

                <div className="d-flex justify-content-between align-items-center px-5 pt-5">
                    <h5 className="pt-3">Update Product Language</h5>
                    <div className="lang_select">
                        <Select
                            options={langOptions}
                            value={activeLang}
                            onChange={e => setActiveLang(e)} />
                    </div>
                </div>


                        
                        <div className="pro_box p-5">
                            <div className="container_fluid">
                                <div className="row">

                                    <div className="col-md-12">

                                        <div className="form-group mb-4">
                                            <label>Name</label>
                                            <input
                                                type="text"
                                                placeholder="Name of the product"
                                                className="form-control mb-1 mt-4"
                                                value={name}
                                                onChange={(e) => (setName(e.target.value), setNameErr(''))} />
                                                {nameErr ? <div className="cm_err">{nameErr}</div> : ''}
                                        </div>

                                       

                                        <div className="form-group mb-4">
                                        <label>Description</label>
                                            <textarea
                                                rows="4"
                                                cols="50"
                                                placeholder="Description"
                                                className="form-control mt-2"
                                                value={product_desc}
                                                onChange={(e) => (setProduct_desc(e.target.value), setProduct_descErr(''))}></textarea>
                                                {product_desErr ? <div className="cm_err">{product_desErr}</div> : ''}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="pro_box p-5">
                            <div className="">
                                <label>Ingredients</label>
                                <TextEditor
                                    handleChange={e => setIngredients(e)}
                                    defaultValue={ingredients}/>

                            </div>
                        </div>

                        <div className="pro_box p-5">
                            <div className="">

                                <label>PRODUCT FEATURES</label>

                                <table className="table box-shadow">
                                    <thead>
                                        <tr>
                                            <th>S. No</th>
                                            <th>Feature</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {features_details?.length > 0 ? features_details?.map((item, i) => (
                                            <tr key={i}>
                                                <td>{i + 1}.</td>
                                                <td className='w-75'>
                                                    <input
                                                        type="text"
                                                        onChange={e => handleFeaturesDetailsChange(e, i)}
                                                        value={item}
                                                        placeholder="Enter feature" 
                                                        className="form-control" />
                                                </td>
                                                
                                    
                                               
                                            </tr>
                                        ))
                                        : ""}
                                    </tbody>
                                </table>

                            </div>
                        </div>

                        <div className="pro_box p-5">
                            <div className="">
                                <label>Recipe Ingredients</label>
                                <div className="table-content">
                                    <table className="table box-shadow">
                                        <thead>
                                        <tr>
                                            <th>S. No</th>
                                            <th className="ml-3">Recipe Ingredient</th>

                                        </tr>
                                        </thead>
                                        <tbody>

                                        {recipe_ingredients?.length > 0
                                            ? recipe_ingredients?.map((item, i) => (
                                            
                                                <tr key={i}>
                                                    <td>{i + 1}.</td>
                                                    <td className='w-75'>
                                                        <input
                                                            type="text"
                                                            onChange={e => handleRecipeIngredientsChange(e, i)}
                                                            placeholder="Enter Recipe Ingredient" 
                                                            className="form-control"
                                                            value={item.desc}/>
                                                    </td>
                                            </tr>
                                            ))
                                            : ""}
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>


                        <div className="pro_box p-5">
                            <div className="">

                                <label>PREPARATION</label>


                                <table className="table box-shadow">
                                    <thead>
                                        <tr>
                                            <th>S. No</th>
                                            <th>Feature</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {preparation?.length > 0 ? preparation?.map((item, i) => (
                                            <tr key={i}>
                                                <td>{i + 1}.</td>
                                                <td className='w-75'>
                                                    <input
                                                        type="text"
                                                        onChange={e => handlePreparationChange(e, i)}
                                                        value={item}
                                                        placeholder="Enter feature" 
                                                        className="form-control" />
                                                </td>
                                                
                                    
                                            </tr>
                                        ))
                                        : ""}
                                    </tbody>
                                </table>

                            </div>
                        </div>

                        <div className="pro_box p-5">
                            <h3 className='page_box_title'>Nutrition Information</h3>

                            <div className="inner_rows">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h4 className="mb-o">Total Fat</h4>
                                            <div className="d-flex justify-content-between align-items-center">
                                            <div>
                                                <input
                                                        type="text"
                                                        value={totalFat}
                                                        disabled
                                                        className="form-control" />
                                            </div>
                                            </div>

                                            
                                            
                                        </div>

                                            {
                                                totalFatList.length ? (
                                                    <Table className=" box-shadow">
                                                        <tbody>
                                                            {
                                                                totalFatList?.map((item, i) => (
                                                                    <tr key={i}>
                                                                        <td className='w-75'>
                                                                            <input
                                                                                type="text"
                                                                                onChange={e => handleChange2(e, totalFatList, setTotalFatList, i, 'desc')}
                                                                                value={item.desc[activeLang.value]}
                                                                                placeholder="Enter nutrition" 
                                                                                className="form-control" />
                                                                        </td>
                                                                </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </Table>
                                                ) : ''
                                            }
                                    </div>

                                    <hr className="my-5" />


                                    <div className="inner_rows">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h4 className="mb-o">Carbohydrate</h4>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div>
                                                <input
                                                    type="text"
                                                    disabled
                                                    value={carbohydrate}
                                                    className="form-control" />
                                                </div>
                                                
                                            </div>
                                        
                                        </div>

                                        {
                                            carbohydrateList.length ? (
                                                <Table className=" box-shadow">
                                                    <tbody>
                                                        {
                                                            carbohydrateList?.map((item, i) => (
                                                                <tr key={i}>
                                                                    <td className='w-75'>
                                                                        <input
                                                                            type="text"
                                                                            onChange={e => handleChange2(e, carbohydrateList, setCarbohydrateList, i, 'desc')}
                                                                            value={item.desc[activeLang.value]}
                                                                            placeholder="Enter nutrition" 
                                                                            className="form-control" />
                                                                    </td>
                                                            </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </Table>
                                            ) : ''
                                        }
                                    </div>

                                    <hr className="my-5" />


                                    <div className="inner_rows">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h4 className="mb-o">Protein</h4>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div>
                                                <input
                                                    type="text"
                                                    value={protein}
                                                    disabled
                                                    className="form-control" />
                                                </div>
                                            </div>
                                            
                                        </div>


                                        {
                                            proteinList.length ? (
                                                <Table className=" box-shadow">
                                                    <tbody>
                                                        {
                                                            proteinList?.map((item, i) => (
                                                                <tr key={i}>
                                                                    <td className='w-75'>
                                                                        <input
                                                                            type="text"
                                                                            value={item.desc[activeLang.value]}
                                                                            onChange={e => handleChange2(e, proteinList, setProteinList, i, 'desc')}
                                                                            placeholder="Enter nutrition" 
                                                                            className="form-control" />
                                                                    </td>
                                                                    
                                                                   
                                                            </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </Table>
                                            ) : ''
                                        }
                                    </div>

                                    <hr className="my-5" />


                                    <div className="inner_rows">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h4 className="mb-o">Sodium</h4>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div>
                                                <input
                                                    type="text"
                                                    value={sodium}
                                                    disabled
                                                    className="form-control" />
                                                </div>
                                            </div>
                                            
                                            
                                        </div>

                                        {
                                            sodiumList.length ? (
                                                <Table className=" box-shadow">
                                                    <tbody>
                                                        {
                                                            sodiumList?.map((item, i) => (
                                                                <tr key={i}>
                                                                    <td className='w-75'>
                                                                        <input
                                                                            type="text"
                                                                            value={item.desc[activeLang.value]}
                                                                            onChange={e => handleChange2(e, sodiumList, setSodiumList, i, 'desc')}
                                                                            placeholder="Enter nutrition" 
                                                                            className="form-control" />
                                                                    </td>
                                                                    
                                                                  
                                                            </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </Table>
                                            ) : ''
                                        }
                                    </div>
                        </div>



                        


                        {
                            btnLoader ? <div className="text-center"><button className="btn btn-primary"><Spinner className="spiner" animation="border" />Requesting...</button></div>
                          : <div className="text-center"><button className="btn btn-primary" onClick={handleSaveProduct}>Save</button></div>
                        }
            
                </div>
            </div>
        </div>
    )
}

export default EditProduct;