export const isEmailValid = (email) => {
    let emailRegex = email.match(/\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/);

    if(emailRegex == null)
        return false;
    else
        return true;
}



export const isPasswordValid = (password) => {
    let passwordRegex = password.match(/^(?!.*\s)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/);

    if(passwordRegex == null)
        return false;
    else
        return true;
}

export const isJsonString = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export const isFileType = (file) => {
    if (file.type.indexOf("video") !== -1) {
        return "video";
      } else if (file.type.indexOf("image") !== -1) {
        return "image";
      }
}

export const getBase64= (file) =>{
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
    };
    reader.onerror = function (error) {
    };
 }
 
//  export const langOptions = [
//     {label: 'English', value: 'en'},
//     {label: 'Hindi', value: 'hi'},
//     {label: 'Tamil', value: 'ta'},
//     {label: 'Telugu', value: 'te'},
//     {label: 'Malayalam', value: 'ml'}
// ]

export const langOptions = [
    {label: 'English', value: 'en'},
    {label: 'Hindi - हिंदी', value: 'hi'},
    {label: 'Tamil - தமிழ்', value: 'ta'},
    {label: 'Telugu - తెలుగు', value: 'te'},
    {label: 'Malayalam - മലയാളം', value: 'ml'}
]



export const isValidName = (name) => /^[a-zA-Z]+(?: [a-zA-Z]+)*$/.test(name)

export const isValidPhoneNumber = (phoneNumber) => {
    const cleanedPhoneNumber = phoneNumber.replace(/\D/g, '');
    if (cleanedPhoneNumber.length !== 10) {
      return false;
    }
    return true;
  }


   

export const getFileNameFromUrl = (url) => {
    const parts = url.split('/');
    const fileName = parts[parts.length - 1];
    return fileName || '';
}

export const formattedNumber = (val) => new Intl.NumberFormat('en-US', {
    style: 'decimal'
  }).format(val);


  export const monthsList = [
    { id: 1, name: "January" },
    { id: 2, name: "February" },
    { id: 3, name: "March" },
    { id: 4, name: "April" },
    { id: 5, name: "May" },
    { id: 6, name: "June" },
    { id: 7, name: "July" },
    { id: 8, name: "August" },
    { id: 9, name: "September" },
    { id: 10, name: "October" },
    { id: 11, name: "November" },
    { id: 12, name: "December" }
  ];


  export const designAssetsList = [
    'posters/frames',
    'brochures',
    'tent cards',
    'danglers',
    'standees',
    'whatsapp post',
    'instagram/fb post',
    'whastsapp status',
    'instagram stroy',
    'food delivery app product images'
  ];