import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Select from "react-select";

import {
    getToolkitDesignDetailByIdService,
    updateToolkitDesignService,
} from "../../services/userService";

import { Spinner } from "react-bootstrap";
import { langOptions } from "../helper";


const MarkingToolkitDesignFormLang = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    const [name, setName] = useState('');
    const [translated_name, setTranslated_name] = useState({});
    const [btnLoader, setBtnLoader] = useState(false);
    const [nameErr, setNameErr] = useState('');
    const [activeLang, setActiveLang] = useState(langOptions[0]);



    const getCakeDesignDetail = () => {
        let query = `/?toolkit_id=${id}`
        getToolkitDesignDetailByIdService(query).then((res) => {
            let _res = res.data.responseData.data;
            setName(_res.name);
            setTranslated_name(_res.translated_name);
        })
    }


    useEffect(() => {
        setName(translated_name[activeLang.value]);
    }, [activeLang])


    useEffect(() => {
        if(id){
            getCakeDesignDetail()
        }
    }, [id])



    const handleValidate = () => {
        let validate = true;

        if (!name) {
            setNameErr('Name is required');
            validate = false;
        }

        return validate;
    }


    const handleSubmit = async () => {
        if (handleValidate()) {
            try {
                setBtnLoader(true);
                let params = {
                    _id: id,
                    translated_name: JSON.stringify({...translated_name, [activeLang.value]: name}),
                }
                const _res = await updateToolkitDesignService(params);
                setBtnLoader(false);
                if (_res.data.statusCode) {
                    navigate("/design-library");
                }
                
            } catch (error) {
                console.error("Error:", error);
            }
        }
    };


    return (
        <div className="page_wrapper">
            <div className="container-box">

                <div className="d-flex justify-content-between align-items-center">
                    <h4 className="semibold-font mt-5">UPDATE DESIGN LIBRARY LANGUAGE</h4>
                    <div className="lang_select">
                        <Select
                            options={langOptions}
                            value={activeLang}
                            onChange={e => setActiveLang(e)} />
                    </div>
                </div>
            
                <div className="tab-box position-relative">
                    <div className="add-product-box">
                        <div className="row">
                            <div className="col-md-1"></div>
                            <div className="col-md-5">
                                <div className="form-group">
                                    <label>Name</label>
                                    <input
                                        className="form-control"
                                        value={name}
                                        onChange={e => (setName(e.target.value), setNameErr(''))}
                                        type="text" />

                                    {nameErr ? <div className="error-message">{nameErr}</div> : ''}
                                </div>



                                <div className="mt-5">
                                    <Link className="btn btn-dark me-4 px-5" to="/marking-toolkit-designs">CANCEL</Link>
                                    <span className="btn btn-primary me-4 px-5" onClick={btnLoader ? null : handleSubmit}>{btnLoader ? <Spinner className="spiner" animation="border" /> : ''} Save</span>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
export default MarkingToolkitDesignFormLang;