import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Select from "react-select";
import camera from "../../../assets/images/camera.svg";
import EditIcon from "../../../assets/images/edit (5).svg";
import {
    addProductCategoryService,
    uploadFileService,
    updateProductCategoryService,
    getProductCategoryDetailsService,
    translateContentService
} from "../../../services/userService";
import { langOptions } from "../../helper";


const AddCategory = (key) => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [activeLang, setActiveLang] = useState(langOptions[0]);
    const [categoryId, setCategoryId] = useState("");
    const [categoryName, setCategoryName] = useState("");
    const [categoryNameErr, setCategoryNameErr] = useState("");
    const [uploadIcon, setUploadIcon] = useState("");
    const [uploadIconErr, setUploadIconErr] = useState("");
    const [file, setFile] = useState('');
    const [showImage, setShowImage] = useState('');
    const [showIcon, setShowIcon] = useState('');
    const [url, setURL] = useState('');
    const [translated_name, setTranslated_name] = useState({});




    const handleFileChange = e => {
        let reader = new FileReader();
        let file = e.target.files[0];
        setFile(file);
        
        reader.addEventListener('load', () => {
            setShowImage(reader.result);
        }, false);
        if (file) { reader.readAsDataURL(file) }
    }
    

    const handleIconChange = (e) => {
        setUploadIconErr("")
        if (e.target) {
            const file = new File([e.target.files[0]], e.target.files[0].name, {
                type: e.target.files[0].type
            });
            setUploadIcon(file);
            setShowIcon(window.URL.createObjectURL(e.target.files[0]))
        }



    }

    const handleUpload = () => {
        let formData = new FormData();
        formData.set("file", uploadIcon)

        uploadFileService(formData).then((res) => {
            setURL(res.data.responseData.file_url);
        })
    }


    const handleKeyDown = (e) => {
        if (e.key === " " && categoryName.length === 0) {
            e.preventDefault();
        }
    };


    const validation = () => {
        let validate = true;

        if (categoryName === "") {
            setCategoryNameErr("Category Name is required")
            validate = false;
        }
        if (categoryName.length < 2) {
            setCategoryNameErr("Category Name should be between 2 to 30 ")
            validate = false;

        }
        if (uploadIcon === "" && file==="") {
            setUploadIconErr("Icon is required")
            validate = false;
        }

        return validate;

    }

    const handleClick = async () => {
        if (validation()) {
            let formData = new FormData();

            formData.set("product_category_image", file);
            formData.set("product_category_icon_url", url);

            if(id){
                formData.set("_id", id);
                formData.set("translated_name", JSON.stringify({...translated_name, [activeLang.value]: categoryName}));
                
            }else{
                const categoryNames_res = await translateContentService({ text: categoryName });
                const categoryNames =  categoryNames_res.data.responseData.data;
                formData.set("translated_name", JSON.stringify(categoryNames));
                formData.set("category", categoryName);
            }

            let _service = id ? updateProductCategoryService : addProductCategoryService

            _service(formData).then((res) => {
                if(res.data.statusCode){
                    navigate("/product-categories")
                }
            })
            

        }

    }


    const categoryDetails = () => {
        let formData = new FormData();
        formData.set("_id", id)
            getProductCategoryDetailsService({ _id: id }).then((res) => {
            let response = res.data.responseData.result[0];
            setCategoryId(response._id)
            setCategoryName(response.category)
            setURL(response.product_category_icon_url)
            setFile(response.product_category_image)
            setShowImage(response.product_category_image)
            setShowIcon(response.product_category_icon_url)
            setTranslated_name(response.translated_name)
        })
    }

    useEffect(() => {
        if(id){
            categoryDetails()
        }
    }, [id])


    useEffect(() => {
        setCategoryName(translated_name[activeLang.value])
    }, [activeLang])


    return (
        <>
            <div className="page_wrapper">
                    <div className="container-box">
                        <div className="d-flex justify-content-between align-items-center">
                            <h4 className="semibold-font mt-5">{id ? "UPDATE" : "ADD"} CATEGORY</h4>
                            <div className="lang_select">
                                <Select
                                    options={langOptions}
                                    value={activeLang}
                                    onChange={e => setActiveLang(e)} />
                            </div>
                        </div>


                       
                        <div className="tab-box">
                            <div className="add-product-box">
                                <div className="row">
                                    <div className="col-md-3">

                                    <div className="cm_file_upload">
                                        <input onChange={handleFileChange} type="file" />

                                        {showImage ? <img className="cm_uploaded_file" src={showImage} /> : <img className="cm_upload_icon" src={camera} />}
                                        {showImage ? <img className="cm_edit_icon" src={EditIcon} /> : ''}

                                    </div>

                                    <h6 className="text-center mt-2">Image size: 160 x 170</h6>

                                    </div>
                                    <div className="col-md-9">
                                        
                                        <input type="text" placeholder="CATEGORY NAME" className="form-control product-box" onKeyDown={handleKeyDown}
                                            value={categoryName} onChange={(e) => (setCategoryName(e.target.value), setCategoryNameErr(''))}></input>
                                        <div className="error-message">{categoryNameErr ? categoryNameErr : ""}</div>

                                        <input type="file" placeholder="UPLOAD ICON" accept="image/*" className="form-control product-box change-placeholder"
                                            onChange={handleIconChange} />
                                        {showIcon ? <div className='icon-preview-box'><img
                                            src={showIcon} height={100} width={100} /></div> : ''}
                                        <div className="error-message">{uploadIconErr ? uploadIconErr : ""}</div>


                                        {
                                            showIcon ? <button className="upload-btn common-color" onClick={handleUpload}>UPLOAD</button> : ''
                                        }

                                        

                                       
                                        <div className="mt-5">
                                            <Link className="btn btn-dark me-4 px-5" to="/product-categories">CANCEL</Link>
                                            <button className="btn btn-primary px-5" onClick={handleClick}>SAVE</button>
                                        </div>







                                    </div>
                                </div>
                            </div>
                        </div>





                    </div>
            </div>
        </>
    )
}
export default AddCategory;