import { useEffect, useState } from "react";
import Select from "react-select";
import {Table, Spinner} from "react-bootstrap";
import {Link, useParams, useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import { createPortal } from "react-dom";
import EditorToolbar, { modules, formats } from "../common/EditorToolbar";
import "react-quill/dist/quill.snow.css";


import camera from "../../assets/images/camera.svg";
import DeleteIcon from "../../assets/images/trash_.svg";
import EditIcon from "../../assets/images/edit (5).svg";
import { addProductService, getProductByIdService, getProductCategoryService, translateContentService, updateProductService } from "../../services/userService";
import Notification from "../common/Notification";
import { isValidName } from "../helper";
import ImageCroper from "../common/ImageCroper";



const ProductForm = () => {
    let { id } = useParams();
    const navigate = useNavigate();
    const [notificationStatus, setNotificationStatus] = useState(false);
    const [notificationProps, setNotificationProps] = useState({
        message: "",
        type: "info",
    });
    const [categoryList, setCategoryList] = useState([]);

    const [name, setName] = useState('');
    const [category, setCategory] = useState('');
    const [net_weight, setNet_weight] = useState('');
    const [price, setPrice] = useState('');
    const [dietary, setDietary] = useState('Egg Free');
    const [product_image, setProduct_image] = useState('');
    const [product_image_view, setProduct_image_view] = useState('');
    const [pillsbury_logo, setPillsbury_logo] = useState(false);
    const [ingredients, setIngredients] = useState('');
    const [product_desc, setProduct_desc] = useState('');
    const [features_details, setFeatures_details] = useState([]);
    const [preparation, setPreparation] = useState([]);
    const [recipe_ingredients, setRecipe_ingredients] = useState([]);

    const [energy, setEnergy] = useState("");
    const [totalFat, setTotalFat] = useState("");
    const [totalFatList, setTotalFatList] = useState([]);
    const [carbohydrate, setCarbohydrate] = useState("");
    const [carbohydrateList, setCarbohydrateList] = useState([]);
    const [protein, setProtein] = useState("");
    const [proteinList, setProteinList] = useState([]);
    const [sodium, setSodium] = useState("");
    const [sodiumList, setSodiumList] = useState([]);

    const [energyErr, setEnergyErr] = useState("");
    const [totalFatErr, setTotalFatErr] = useState("");
    const [carbohydrateErr, setCarbohydrateErr] = useState("");
    const [proteinErr, setProteinErr] = useState("");
    const [sodiumErr, setSodiumErr] = useState("");


    const [product_imageErr, setProduct_imageErr] = useState('');
    const [nameErr, setNameErr] = useState('');
    const [categoryErr, setCategoryErr] = useState('');
    const [net_weightErr, setNet_weightErr] = useState('');
    const [priceErr, setPriceErr] = useState('');
    const [product_descErr, setProduct_descErr] = useState('');
    const [features_detailsErr, setFeatures_detailsErr] = useState('');
    const [ingredientsErr, setIngredientsErr] = useState('');
    const [recipe_ingredientsErr, setRecipe_ingredientsErr] = useState('');
    const [preparationErr, setPreparationErr] = useState('');

    const [btnLoader, setBtnLoader] = useState(false);
    const [baking_temperature, setBaking_temperature] = useState('');
    const [baking_time, setBaking_time] = useState('');


    const getProductDetailById = () => {
        let params = {productId: id}
        
        getProductByIdService(params).then(res => {
            if(res.data.statusCode){
                let _data = res.data.responseData.result;

                let _nutrition_information = {...res.data.responseData.result.nutrition_information};

                if(_nutrition_information?.totalFatList?.length){
                    let _temp = _nutrition_information.totalFatList.map(item => ({
                        ...item,
                        desc: item.desc.en
                    }))
                    _nutrition_information.totalFatList = _temp
                }

                if(_nutrition_information?.carbohydrateList?.length){
                    let _temp = _nutrition_information.carbohydrateList.map(item => ({
                        ...item,
                        desc: item.desc.en
                    }))
                    _nutrition_information.carbohydrateList = _temp
                }

                if(_nutrition_information?.sodiumList?.length){
                    let _temp = _nutrition_information.sodiumList.map(item => ({
                        ...item,
                        desc: item.desc.en
                    }))
                    _nutrition_information.sodiumList = _temp
                }

                if(_nutrition_information?.proteinList?.length){
                    let _temp = _nutrition_information.proteinList.map(item => ({
                        ...item,
                        desc: item.desc.en
                    }))
                    _nutrition_information.proteinList = _temp
                }

                setName(_data.name);
                setCategory({label: _data.categoryName, value: _data.categoryId});
                setNet_weight(_data.net_weight);
                setPrice(_data.price);
                setDietary(_data.dietary);
                setProduct_image_view(_data.product_image);
                setPillsbury_logo(_data.pillsbury_logo);
                setIngredients(_data?.ingredients?.en || _data?.ingredients);
                setProduct_desc(_data?.product_desc?.en || _data?.product_desc);
                setFeatures_details(_data?.features_details?.en || _data?.features_details);
                setRecipe_ingredients(_data?.recipe_ingredients?.en || []);
                setPreparation(_data?.preparation?.data?.en || []);
                setBaking_temperature(_data?.preparation?.baking_temperature);
                setBaking_time(_data?.preparation?.baking_time);
                setEnergy(_nutrition_information?.energy || '');
                setTotalFat(_nutrition_information?.totalFat || '');
                setCarbohydrate(_nutrition_information?.carbohydrate || '');
                setProtein(_nutrition_information?.protein || '');
                setSodium(_nutrition_information?.sodium || '');
                setTotalFatList(_nutrition_information?.totalFatList || []);
                setCarbohydrateList(_nutrition_information?.carbohydrateList || []);
                setProteinList(_nutrition_information?.proteinList || []);
                setSodiumList(_nutrition_information?.sodiumList || []);
            }
        });
    }

    useEffect(() => {
        if(id){
            getProductDetailById(); 
        }
    }, [id])




    const getCategoryList = () => {
          getProductCategoryService().then(res => {
            if(res.data.statusCode){
                const _categoryList = res.data.responseData.result?.map((item) => ({
                    value: item?._id,
                    label: item?.category,
                  })) 
                  setCategoryList(_categoryList)
            }
          });
      };

    useEffect(() => {
        getCategoryList()
    }, [])




    const handleAddItem = (type) => {
        if (type === 'features_details') {
            const newFeaturesDetails = [...features_details, ''];
            setFeatures_details(newFeaturesDetails);
        }

        if (type === 'recipe_ingredients') {
            const newRecipe_ingredients = [...recipe_ingredients, {desc: '', weight: ''}];
            setRecipe_ingredients(newRecipe_ingredients);
        }

        if (type === 'preparation') {
            const newPreparation = [...preparation, ''];
            setPreparation(newPreparation);
        }
    };
    
    const handleRemoveItem = (type, index) => {
        if (type === 'features_details') {
            const newFeaturesDetails = [...features_details];
            newFeaturesDetails.splice(index, 1);
            setFeatures_details(newFeaturesDetails);
        }

        if (type === 'recipe_ingredients') {
            const newRecipe_ingredients = [...recipe_ingredients];
            newRecipe_ingredients.splice(index, 1);
            setRecipe_ingredients(newRecipe_ingredients);
        }

        if (type === 'preparation') {
            const newPreparation = [...preparation];
            newPreparation.splice(index, 1);
            setPreparation(newPreparation);
        }
    };


    const handleFeaturesDetailsChange = (e, index) => {
        const newFeaturesDetails = [...features_details];
            setFeatures_detailsErr('');
            newFeaturesDetails[index] = e.target.value;
            setFeatures_details(newFeaturesDetails);
    }

    
    const handleRecipeIngredientsChange = (e, index) => {
        const newRecipe_ingredients = [...recipe_ingredients];
            setRecipe_ingredientsErr('');
            newRecipe_ingredients[index].desc = e.target.value;
            setRecipe_ingredients(newRecipe_ingredients);
    }

    const handleRecipeIngredientsChange2 = (e, index) => {
        const newRecipe_ingredients = [...recipe_ingredients];
            setRecipe_ingredientsErr('');
            newRecipe_ingredients[index].weight = e.target.value;
            setRecipe_ingredients(newRecipe_ingredients);
    }

    const handlePreparationChange = (e, index) => {
        const newPreparation = [...preparation];
            setPreparationErr('');
            newPreparation[index] = e.target.value;
            setPreparation(newPreparation);
    }


    const handleDeleteTotalFatItem = index => {
        const newTotalFatList = [...totalFatList];
            newTotalFatList.splice(index, 1);
            setTotalFatList(newTotalFatList);
    }

    const handleDeleteCarbohydrateItem = index => {
        const newCarbohydrateList = [...carbohydrateList];
            newCarbohydrateList.splice(index, 1);
            setCarbohydrateList(newCarbohydrateList);
    }

    const handleDeleteProteinItem = index => {
        const newProteinList = [...proteinList];
            newProteinList.splice(index, 1);
            setProteinList(newProteinList);
    }

    const handleDeleteSodiumItem = index => {
        const newSodiumList = [...sodiumList];
            newSodiumList.splice(index, 1);
            setSodiumList(newSodiumList);
    }



    const handleChange = (e, _get, _set, index, key) => {
        let new_get = [..._get];
        new_get[index][key] = e.target.value;
        _set(new_get);

    }




    const handleValidate = () => {
        let validate = true;
    
        if (!product_image_view) {
            setProduct_imageErr('Image is required');
            setNotificationStatus(true);
            setNotificationProps({type: "error", message: 'Image is required'});
            validate = false;
        }

        if (!name || name.trim() === '') {
            setNameErr('Name is required');
            setNotificationStatus(true);
            setNotificationProps({type: "error", message: 'Name is required'});
            validate = false;
        }
        // else if(!isValidName(name)){
        //     setNameErr('Numeric values and Special characters not allowed');
        //     setNotificationStatus(true);
        //     setNotificationProps({type: "error", message: 'Numeric values and Special characters not allowed'});
        //     validate = false;
        // }
        
        if (!category) {
            setCategoryErr('Category is required');
            setNotificationStatus(true);
            setNotificationProps({type: "error", message: 'Category is required'});
            validate = false;
        }

        if (!net_weight) {
            setNet_weightErr('Weight with unit is required');
            setNotificationStatus(true);
            setNotificationProps({type: "error", message: 'Weight with unit is required'});
            validate = false;
        }

        // if (!price) {
        //     setPriceErr('Price is required');
        //     setNotificationStatus(true);
        //     setNotificationProps({type: "error", message: 'Price is required'});
        //     validate = false;
        // }

        if (!product_desc) {
            setProduct_descErr('Description is required');
            setNotificationStatus(true);
            setNotificationProps({type: "error", message: 'Description is required'});
            validate = false;
        }

        if (!ingredients) {
            setIngredientsErr('Ingredients is required');
            setNotificationStatus(true);
            setNotificationProps({type: "error", message: 'Ingredients is required'});
            validate = false;
        }

        if (!energy) {
            setEnergyErr('Energy is required');
            setNotificationStatus(true);
            setNotificationProps({type: "error", message: 'Energy is required'});
            validate = false;
        }

        if (!totalFat) {
            setTotalFatErr('Fat is required');
            setNotificationStatus(true);
            setNotificationProps({type: "error", message: 'Fat is required'});
            validate = false;
        }

        if (!carbohydrate) {
            setCarbohydrateErr('Carbohydrate is required');
            setNotificationStatus(true);
            setNotificationProps({type: "error", message: 'Carbohydrate is required'});
            validate = false;
        }

        if (!protein) {
            setProteinErr('Protein is required');
            setNotificationStatus(true);
            setNotificationProps({type: "error", message: 'Protein is required'});
            validate = false;
        }

        if (!sodium) {
            setSodiumErr('Sodium is required');
            setNotificationStatus(true);
            setNotificationProps({type: "error", message: 'Sodium is required'});
            validate = false;
        }

        if (features_details.length == 0) {
            setFeatures_detailsErr('Features is required');
            setNotificationStatus(true);
            setNotificationProps({type: "error", message: 'Features is required'});
            validate = false;
        }else{
            const filteredFeaturesDetails = features_details.filter(item => item.trim() == '');
            if (filteredFeaturesDetails.length) {
                setFeatures_detailsErr('Features are required');
                setNotificationStatus(true);
                setNotificationProps({ type: "error", message: 'Features are required' });
            }
        }

        // if (recipe_ingredients.length == 0) {
        //     setRecipe_ingredientsErr('Recipe Ingredients are required');
        //     setNotificationStatus(true);
        //     setNotificationProps({type: "error", message: 'Recipe Ingredients are required'});
        //     validate = false;
        // }else{
        //     const filteredFecipe_ingredients = recipe_ingredients.filter(item => item?.desc?.trim() == '');
        //     if (filteredFecipe_ingredients.length) {
        //         setRecipe_ingredientsErr('Recipe Ingredients are required');
        //         setNotificationStatus(true);
        //         setNotificationProps({ type: "error", message: 'Recipe Ingredients are required' });
        //     }
        // }
        
        // if (preparation.length == 0) {
        //     setPreparationErr('Preparation is required');
        //     setNotificationStatus(true);
        //     setNotificationProps({type: "error", message: 'Preparation is required'});
        //     validate = false;
        // }else{
        //     const filteredPreparation = preparation.filter(item => item.trim() == '');
        //     if (filteredPreparation.length) {
        //         setPreparationErr('Preparation is required');
        //         setNotificationStatus(true);
        //         setNotificationProps({ type: "error", message: 'Preparation is required' });
        //     }
        // }

    
        return validate;
    }


    const handleSubmit = async e => {
        e.preventDefault();

        if(handleValidate()){

            let _product_desc;
            let _ingredients;
            let _name;
            let _features_details = [];
            let final_features_details = {};
            let _recipe_ingredients = [];
            let final_recipe_ingredients = {};
            let _preparation = [];
            let final_preparation = {};

            let _totalFatList = [];
            let _carbohydrateList = [];
            let _proteinList = [];
            let _sodiumList = [];


            setBtnLoader(true);
            try {
                let [trans_product_desc, trans_ingredients, trans_name] = await Promise.all([
                    translateContentService({ text: product_desc }),
                    translateContentService({ text: ingredients }),
                    translateContentService({ text: name })
                ]);

                _product_desc = trans_product_desc.data.responseData.data;
                _ingredients = trans_ingredients.data.responseData.data;
                _name = trans_name.data.responseData.data;

                for (const _feature of features_details) {
                    const res = await translateContentService({ text: _feature });
                    _features_details.push(res.data.responseData.data);
                }
                Object.keys(_features_details[0]).forEach(k => final_features_details[k] = _features_details.map(obj => obj[k]));


                let old_recipe_ingredients = [...recipe_ingredients];
                for (const _recipe of recipe_ingredients) {
                    const res = await translateContentService({ text: _recipe.desc });
                    _recipe_ingredients.push(res.data.responseData.data);
                }
                Object.keys(_recipe_ingredients[0]).forEach(k => final_recipe_ingredients[k] = _recipe_ingredients.map((obj, i) => ({desc: obj[k], weight: old_recipe_ingredients[i].weight})));
                
                
                for (const _preparationItem of preparation) {
                    const res = await translateContentService({ text: _preparationItem });
                    _preparation.push(res.data.responseData.data);
                }
                Object.keys(_preparation[0]).forEach(k => final_preparation[k] = _preparation.map(obj => obj[k]));



                for (const _list of totalFatList) {
                    let new_list_item = {..._list};
                    const res = await translateContentService({ text: _list.desc });
                    _totalFatList.push({value: new_list_item.value, desc: res.data.responseData.data });
                }


                for (const _list of carbohydrateList) {
                    let new_list_item = {..._list};
                    const res = await translateContentService({ text: _list.desc });
                    _carbohydrateList.push({value: new_list_item.value, desc: res.data.responseData.data });
                }



                for (const _list of proteinList) {
                    let new_list_item = {..._list};
                    const res = await translateContentService({ text: _list.desc });
                    _proteinList.push({value: new_list_item.value, desc: res.data.responseData.data });
                }



                for (const _list of sodiumList) {
                    let new_list_item = {..._list};
                    const res = await translateContentService({ text: _list.desc });
                    _sodiumList.push({value: new_list_item.value, desc: res.data.responseData.data });
                }


            



        } catch (error) {
            console.error("Error occurred:", error);
        }

        
        const formData = new FormData();

        if(id){
            formData.append('_id', id); 
        }

        let _service = id ? updateProductService : addProductService;

        formData.append('name', name);
        formData.append('net_weight', net_weight);
        formData.append('price', price || 0);
        formData.append('dietary', dietary);
        formData.append('pillsbury_logo', pillsbury_logo);
        formData.append('product_image', product_image);
        formData.append('category', category.value);
        formData.append('ingredients', JSON.stringify(_ingredients));
        formData.append('translated_name', JSON.stringify(_name));
        formData.append('product_desc', JSON.stringify(_product_desc));
        formData.append('features_details', JSON.stringify(final_features_details));
        formData.append('recipe_ingredients', JSON.stringify(final_recipe_ingredients));
        formData.append('preparation', JSON.stringify({baking_temperature, baking_time, data: final_preparation}));
        formData.append('nutrition_information', JSON.stringify({
            energy,
            totalFat,
            totalFatList: _totalFatList,
            carbohydrate,
            carbohydrateList: _carbohydrateList,
            protein,
            proteinList: _proteinList,
            sodium,
            sodiumList: _sodiumList,
        }));

        _service(formData).then(res => {
            if (res?.data?.statusCode) {
                setNotificationProps({
                  message: res?.data?.responseData?.message,
                  type: "success",
                });
                setBtnLoader(false);
                navigate("/products");
                setNotificationStatus(true);
                setBtnLoader(false);
              } else {
                setNotificationProps({
                  message: "Please fill all the details correctly.",
                  type: "error",
                });
                setNotificationStatus(true);
                setBtnLoader(false);
              }
          })




            let params = {
                name,
                net_weight,
                price,
                dietary,
                pillsbury_logo,
                product_image,
                category: category.value,
                ingredients: JSON.stringify(_ingredients),
                translated_name: JSON.stringify(_name),
                product_desc: JSON.stringify(_product_desc),
                features_details: JSON.stringify(final_features_details),
                preparation: JSON.stringify({baking_temperature, baking_time, data: final_preparation}),
                recipe_ingredients: JSON.stringify(final_recipe_ingredients),
                nutrition_information: JSON.stringify({
                    energy,
                    totalFat,
                    totalFatList: _totalFatList,
                    carbohydrate,
                    carbohydrateList: _carbohydrateList,
                    protein,
                    proteinList: _proteinList,
                    sodium,
                    sodiumList: _sodiumList,
                }),
              };

           
        }
    }
 

  return (
      <div className="page_wrapper">
          <div className="container-box">
            <div className="mt-5">
                <h4 className="semibold-font">{id ? 'Edit' : 'Create'} Product</h4>

                    <form onSubmit={handleSubmit}>
                        <div className='page_box'>
                            
                            <h3 className='page_box_title'>Product</h3>

                            <div className='row pb-5'>
                                <div className='col-lg-3'>
                                    <ImageCroper
                                        oldImg={product_image_view}
                                        cropCallback={data => (setProduct_image(data), setProduct_image_view(URL.createObjectURL(data)), setProduct_imageErr(''))} />
                                  
                                    {product_imageErr ? <div className="error-message text-center">{product_imageErr}</div> : ''}
                                

                                </div>
                                <div className='col-lg-5'>
                                    <div className="form-group">
                                        <label>Product Name<b className="cm_hash">*</b></label>
                                        <input
                                            className="form-control"
                                            value={name}
                                            onChange={e => (setName(e.target.value), setNameErr(''))}
                                            type="text" />
                                        
                                        {nameErr ? <span className="error-message">{nameErr}</span> : ''}
                                    </div>

                                    <div className="form-group">
                                        <label>Select Category<b className="cm_hash">*</b></label>
                                        <Select
                                            className="w-100 category-select grey-text mt-2"
                                            options={categoryList}
                                            value={category}
                                            onChange={e => (setCategory(e), setCategoryErr(''))}
                                            placeholder="Select..."/>

                                        {categoryErr ? <span className="error-message">{categoryErr}</span> : ''}
                                    </div>

                                    <div className="form-group">
                                        <label>Weight with unit<b className="cm_hash">*</b></label>
                                        <input
                                            value={net_weight}
                                            onChange={e => (setNet_weight(e.target.value), setNet_weightErr(''))}
                                            type="text"
                                            className="form-control mt-2"/>

                                        {net_weightErr ? <span className="error-message">{net_weightErr}</span> : ''}
                                    </div>

                                    <div className="form-group">
                                        <label>Price with currency type</label>
                                        <input
                                            type="text"
                                            value={price}
                                            onChange={e => (setPrice(e.target.value), setPriceErr(''))}
                                            className="form-control mt-2"/>
                                        
                                        {priceErr ? <span className="error-message">{priceErr}</span> : ''}
                                    </div>

                                    <div className="form-group">
                                        <label>Description<b className="cm_hash">*</b></label>
                                        <textarea
                                            rows="4"
                                            cols="50"
                                            value={product_desc}
                                            onChange={e => (setProduct_desc(e.target.value), setProduct_descErr(''))}
                                            placeholder="type..."
                                            className="form-control" />

                                        {product_descErr ? <span className="error-message">{product_descErr}</span> : ''}
                                        
                                    </div>


                                    <div className="form-group">
                                       <div className="row mt-2">
                                            <div className="col-md-6">
                                                <input
                                                    type="radio"
                                                    onChange={() => setDietary('Egg Free')}
                                                    checked={dietary == 'Egg Free'}
                                                    name="dietary"
                                                    value={"Egg Free"} />
                                                <label className="grey-text regular-font me-2 left-margin-10"> Egg Free</label>
                                            </div>
                                            <div className="col-md-6">
                                                <input
                                                    type="radio"
                                                    onChange={() => setDietary('Egg')}
                                                    checked={dietary == 'Egg'}
                                                    name="dietary"
                                                    value={"Egg"} />
                                                <label className="grey-text regular-font left-margin-10">Egg</label>
                                            </div>
                                        </div>
                                        
                                    </div>

                                    <div className="form-group">
                                        <input
                                                type="checkbox"
                                                checked={pillsbury_logo}
                                                onChange={() => setPillsbury_logo(!pillsbury_logo)}
                                                name="pillsbury_logo" />
                                            <label htmlFor="pillsbury_logo" className="grey-text regular-font left-margin-10 ">
                                                Want to use Pillsbury logo on product{" "}
                                            </label>
                                    </div>
                                </div>
                            </div>

                           <div className="row">
                            <div className="col-8"><hr /></div>
                           </div>

                            <h3 className='page_box_title'>Features</h3>

                            <div className='row pb-5'>
                                <div className='col-lg-8'>
                                <Table className=" box-shadow">
                                    <thead>
                                    <tr>
                                        <th>S. No</th>
                                        <th>Feature</th>
                                        <th>Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            features_details?.map((item, i) => (
                                                <tr key={i}>
                                                    <td>{i + 1}.</td>
                                                    <td className='w-75'>
                                                        <input
                                                            type="text"
                                                            value={item}
                                                            onChange={e => handleFeaturesDetailsChange(e, i)}
                                                            placeholder="Enter feature" 
                                                            className="form-control" />
                                                    </td>
                                                    

                                                    <td>
                                                        <span onClick={() => handleRemoveItem('features_details', i)}>
                                                            <img
                                                                src={DeleteIcon}
                                                                alt="delete" 
                                                                className="dlt-icon"
                                                                width={'30px'}
                                                                height={'30px'}/>
                                                        </span>
                                                    </td>
                                            </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>

                                {features_detailsErr ? <span className="error-message">{features_detailsErr}</span> : ''}

                                <div>
                                    <span onClick={() => handleAddItem('features_details')} className="btn btn-dark">+Add</span>
                                </div>
                                </div>
                            </div>

                            <div className="row">
                            <div className="col-8"><hr /></div>
                           </div>

                            <h3 className='page_box_title'>Ingredients</h3>

                            <div className='row pb-5'>
                                <div className='col-lg-8'>
                                    <div className="text-editor">
                                        <EditorToolbar />
                                        <ReactQuill
                                            theme="snow"
                                            value={ingredients}
                                            onChange={e => (setIngredients(e), setIngredientsErr(''))}
                                            placeholder={"Write something..."}
                                            modules={modules}
                                            formats={formats}
                                        />

                                    {ingredientsErr ? <span className="error-message">{ingredientsErr}</span> : ''}
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                            <div className="col-8"><hr /></div>
                           </div>

                            <h3 className='page_box_title'>Recipe Ingredients</h3>

                            <div className='row pb-5'>
                                <div className='col-lg-8'>
                                <Table className=" box-shadow">
                                    <thead>
                                    <tr>
                                        <th>S. No</th>
                                        <th>Recipe Ingredient</th>
                                        <th>Weight</th>
                                        <th>Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            recipe_ingredients?.map((item, i) => (
                                                <tr key={i}>
                                                    <td>{i + 1}.</td>
                                                    <td className='w-50'>
                                                        <input
                                                            onChange={e => handleRecipeIngredientsChange(e, i)}
                                                            value={item.desc}
                                                            type="text"
                                                            placeholder="Enter Recipe Ingredient" 
                                                            className="form-control" />
                                                    </td>

                                                    <td>
                                                        <input
                                                            type="text"
                                                            value={item.weight}
                                                            placeholder="Weight with unit"
                                                            onChange={e => handleRecipeIngredientsChange2(e, i)} 
                                                            className="form-control" />
                                                    </td>
                                                    

                                                    <td>
                                                        <span onClick={() => handleRemoveItem('recipe_ingredients', i)}>
                                                            <img
                                                                src={DeleteIcon}
                                                                alt="delete" 
                                                                className="dlt-icon"
                                                                width={'30px'}
                                                                height={'30px'}/>
                                                        </span>
                                                    </td>
                                            </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>

                                {recipe_ingredientsErr ? <span className="error-message">{recipe_ingredientsErr}</span> : ''}

                                <div>
                                    <span onClick={() => handleAddItem('recipe_ingredients')} className="btn btn-dark">+Add</span>
                                </div>
                                </div>
                            </div>

                            <div className="row">
                            <div className="col-8"><hr /></div>
                           </div>


                            <h3 className='page_box_title'>Nutrition Information</h3>

                            <div className='row pb-5'>
                                <div className='col-lg-8'>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h4 className="mb-0">Energy (kcal)</h4>
                                        <div className="">
                                            <input
                                                type="text"
                                                value={energy}
                                                onChange={e => (setEnergy(e.target.value), setEnergyErr(''))}
                                                placeholder="Enter Energy"
                                                className="form-control" />
                                                {energyErr ? <span className="error-message">{energyErr}</span> : ''}
                                        </div>
                                        
                                    </div>

                                    <hr className="my-5" />

                                    <div className="inner_rows">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h4 className="mb-o">Total Fat</h4>
                                            <div className="d-flex justify-content-between align-items-center">
                                               <div>
                                                <input
                                                        type="text"
                                                        disabled={!energy}
                                                        value={totalFat}
                                                        onChange={e => (setTotalFat(e.target.value), setTotalFatErr(''))}
                                                        className="form-control" />
                                                        {totalFatErr ? <span className="error-message">{totalFatErr}</span> : ''}
                                               </div>
                                               <div className="cm_add_more_div">
                                               {totalFat ? <span onClick={() => setTotalFatList([...totalFatList, {desc: '', value: ''}])} className="add-btn white-text btn-sm _tb-btn">+</span> : ''}
                                               </div>
                                            </div>

                                            
                                            
                                        </div>

                                            {
                                                totalFatList.length ? (
                                                    <Table className=" box-shadow">
                                                        <tbody>
                                                            {
                                                                totalFatList?.map((item, i) => (
                                                                    <tr key={i}>
                                                                        <td className='w-75'>
                                                                            <input
                                                                                type="text"
                                                                                onChange={e => handleChange(e, totalFatList, setTotalFatList, i, 'desc')}
                                                                                value={item.desc}
                                                                                placeholder="Enter nutrition" 
                                                                                className="form-control" />
                                                                        </td>
                                                                        
                                                                        <td>
                                                                            <input
                                                                                type="text"
                                                                                value={item.value}
                                                                                onChange={e => handleChange(e, totalFatList, setTotalFatList, i, 'value')}
                                                                                placeholder="0 g" 
                                                                                className="form-control" />
                                                                        </td>
            
                                                                        <td>
                                                                            <span onClick={() => handleDeleteTotalFatItem(i)}>
                                                                                <img
                                                                                    src={DeleteIcon}
                                                                                    alt="delete" 
                                                                                    className="dlt-icon"
                                                                                    width={'30px'}
                                                                                    height={'30px'}/>
                                                                            </span>
                                                                        </td>
                                                                </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </Table>
                                                ) : ''
                                            }
                                    </div>

                                    <hr className="my-5" />

                                    <div className="inner_rows">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h4 className="mb-o">Carbohydrate</h4>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div>
                                                <input
                                                    type="text"
                                                    disabled={!energy}
                                                    value={carbohydrate}
                                                    onChange={e => (setCarbohydrate(e.target.value), setCarbohydrateErr(''))}
                                                    className="form-control" />
                                                     {carbohydrateErr ? <span className="error-message">{carbohydrateErr}</span> : ''}
                                                </div>
                                                <div className="cm_add_more_div">
                                                {carbohydrate ? <span onClick={() => setCarbohydrateList([...carbohydrateList, {desc: '', value: ''}])} className="add-btn white-text btn-sm _tb-btn">+</span> : ''}
                                                </div>
                                                
                                            </div>
                                           
                                        </div>

                                        {
                                            carbohydrateList.length ? (
                                                <Table className=" box-shadow">
                                                    <tbody>
                                                        {
                                                            carbohydrateList?.map((item, i) => (
                                                                <tr key={i}>
                                                                    <td className='w-75'>
                                                                        <input
                                                                            type="text"
                                                                            onChange={e => handleChange(e, carbohydrateList, setCarbohydrateList, i, 'desc')}
                                                                            value={item.desc}
                                                                            placeholder="Enter nutrition" 
                                                                            className="form-control" />
                                                                    </td>
                                                                    
                                                                    <td>
                                                                        <input
                                                                            type="text"
                                                                            value={item.value}
                                                                            onChange={e => handleChange(e, carbohydrateList, setCarbohydrateList, i, 'value')}
                                                                            placeholder="0 g" 
                                                                            className="form-control" />
                                                                    </td>
        
                                                                    <td>
                                                                        <span onClick={() => handleDeleteCarbohydrateItem(i)}>
                                                                            <img
                                                                                src={DeleteIcon}
                                                                                alt="delete" 
                                                                                className="dlt-icon"
                                                                                width={'30px'}
                                                                                height={'30px'}/>
                                                                        </span>
                                                                    </td>
                                                            </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </Table>
                                            ) : ''
                                        }
                                    </div>

                                    <hr className="my-5" />

                                    <div className="inner_rows">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h4 className="mb-o">Protein</h4>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div>
                                                <input
                                                    type="text"
                                                    value={protein}
                                                    disabled={!energy}
                                                    onChange={e => (setProtein(e.target.value), setProteinErr(''))}
                                                    className="form-control" />
                                                    {proteinErr ? <span className="error-message">{proteinErr}</span> : ''}
                                                </div>
                                               <div className="cm_add_more_div">
                                               {protein ? <span onClick={() => setProteinList([...proteinList, {desc: '', value: ''}])} className="add-btn white-text btn-sm _tb-btn">+</span> : ''}
                                               </div>
                                            </div>
                                            
                                        </div>


                                        {
                                            proteinList.length ? (
                                                <Table className=" box-shadow">
                                                    <tbody>
                                                        {
                                                            proteinList?.map((item, i) => (
                                                                <tr key={i}>
                                                                    <td className='w-75'>
                                                                        <input
                                                                            type="text"
                                                                            value={item.desc}
                                                                            onChange={e => handleChange(e, proteinList, setProteinList, i, 'desc')}
                                                                            placeholder="Enter nutrition" 
                                                                            className="form-control" />
                                                                    </td>
                                                                    
                                                                    <td>
                                                                        <input
                                                                            type="text"
                                                                            value={item.value}
                                                                            onChange={e => handleChange(e, proteinList, setProteinList, i, 'value')}
                                                                            placeholder="0 g" 
                                                                            className="form-control" />
                                                                    </td>
        
                                                                    <td>
                                                                        <span onClick={() => handleDeleteProteinItem(i)}>
                                                                            <img
                                                                                src={DeleteIcon}
                                                                                alt="delete" 
                                                                                className="dlt-icon"
                                                                                width={'30px'}
                                                                                height={'30px'}/>
                                                                        </span>
                                                                    </td>
                                                            </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </Table>
                                            ) : ''
                                        }
                                    </div>

                                    <hr className="my-5" />

                                    <div className="inner_rows">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h4 className="mb-o">Sodium</h4>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div>
                                                <input
                                                    type="text"
                                                    value={sodium}
                                                    disabled={!energy}
                                                    onChange={e => (setSodium(e.target.value), setSodiumErr(''))}
                                                    className="form-control" />
                                                    {sodiumErr ? <span className="error-message">{sodiumErr}</span> : ''}
                                                </div>
                                               <div className="cm_add_more_div">
                                               {sodium ? <span onClick={() => setSodiumList([...sodiumList, {desc: '', value: ''}])} className="add-btn white-text btn-sm _tb-btn">+</span> : ''}
                                               </div>
                                            </div>
                                            
                                            
                                        </div>

                                        {
                                            sodiumList.length ? (
                                                <Table className=" box-shadow">
                                                    <tbody>
                                                        {
                                                            sodiumList?.map((item, i) => (
                                                                <tr key={i}>
                                                                    <td className='w-75'>
                                                                        <input
                                                                            type="text"
                                                                            value={item.desc}
                                                                            onChange={e => handleChange(e, sodiumList, setSodiumList, i, 'desc')}
                                                                            placeholder="Enter nutrition" 
                                                                            className="form-control" />
                                                                    </td>
                                                                    
                                                                    <td>
                                                                        <input
                                                                            type="text"
                                                                            value={item.value}
                                                                            onChange={e => handleChange(e, sodiumList, setSodiumList, i, 'value')}
                                                                            placeholder="0 g" 
                                                                            className="form-control" />
                                                                    </td>
        
                                                                    <td>
                                                                        <span onClick={() => handleDeleteSodiumItem(i)}>
                                                                            <img
                                                                                src={DeleteIcon}
                                                                                alt="delete" 
                                                                                className="dlt-icon"
                                                                                width={'30px'}
                                                                                height={'30px'}/>
                                                                        </span>
                                                                    </td>
                                                            </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </Table>
                                            ) : ''
                                        }
                                    </div>



                                </div>
                            </div>

                            <div className="row">
                            <div className="col-8"><hr /></div>
                           </div>


                            <h3 className='page_box_title'>Preparation</h3>

                                <div className='row pb-5'>
                                    <div className='col-lg-8'>
                                        <div className="form-group">
                                            <label>Baking Temperature</label>
                                            <input
                                                placeholder="170C – 180C"
                                                value={baking_temperature}
                                                onChange={e => setBaking_temperature(e.target.value)}
                                                className="form-control"
                                                type="text" />
                                        </div>

                                        <div className="form-group">
                                            <label>Baking Time (in minutes)</label>
                                            <input
                                                placeholder="27 – 30"
                                                value={baking_time}
                                                onChange={e => setBaking_time(e.target.value)}
                                                className="form-control"
                                                type="text" />
                                        </div>



                                        <Table className=" box-shadow">
                                            <thead>
                                            <tr>
                                                <th>S. No</th>
                                                <th>Description</th>
                                                <th>Action</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    preparation?.map((item, i) => (
                                                        <tr key={i}>
                                                            <td>{i + 1}.</td>
                                                            <td className='w-75'>
                                                                <input
                                                                    type="text"
                                                                    value={item}
                                                                    onChange={e => handlePreparationChange(e, i)}
                                                                    placeholder="Enter Recipe Ingredient" 
                                                                    className="form-control" />
                                                            </td>
                                                            

                                                            <td>
                                                                <span onClick={() => handleRemoveItem('preparation', i)}>
                                                                    <img
                                                                        src={DeleteIcon}
                                                                        alt="delete" 
                                                                        className="dlt-icon"
                                                                        width={'30px'}
                                                                        height={'30px'}/>
                                                                </span>
                                                            </td>
                                                    </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </Table>
                                        
                                        {preparationErr ? <span className="error-message">{preparationErr}</span> : ''}
                                        <div>
                                            <span onClick={() => handleAddItem('preparation')} className="btn btn-dark">+Add</span>
                                        </div>

                                        <div className=" d-flex justify-content-end mt-5">
                                            <Link to="/products" className="btn btn-dark me-4 px-5">Cancel</Link>
                                            <button disabled={btnLoader} type="submit" className="btn btn-primary px-5">{btnLoader ? <Spinner className="spiner" animation="border" /> : ''} Save</button>
                                        </div>
                                            
                                    </div>

                                    
                                </div>

                               
                        </div>


                       
                    </form>


            </div>
            </div>

            {notificationStatus && createPortal(<Notification {...notificationProps} />, document.querySelector("#notification-root"))}
      </div>
  );
};

export default ProductForm;
