import { Fragment, useEffect, useState } from "react";
import { getAllSoReportService, getJCService, getSoListServiceByJcNameService, uploadSoReportService } from "../../services/userService";
import { Modal, Spinner, Table, Toast } from "react-bootstrap";
import { CSVLink } from 'react-csv';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import Select from "react-select";

import { PageLoader } from "../svg";
import Pagination from "react-js-pagination";
import dateFormat from 'dateformat';
import demoTemplate from '../../assets/so-report-template.xlsx';


const titles = [
    'SO Name',
    'Conversion Y/ N',
    'Reasons',
    'Comments',
    'ASM Name',
    'Bakery Code',
    'Bakery Name',
    'Category Name',
    'City',
    'Customer Classifiction(5T/10T/40T/rest)',
    'NC/ LE/Lost Customer',
    'DB Code',
    'DB Name',
    'Last Month Tonnage',
    'Current Billed Tonnage',
    'Region',
    'SKU Name',
    'SKU Opportunity',
    'SO Email',
    // 'Created At',
    // 'Updated At',
];



const formatDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = ('0' + (d.getMonth() + 1)).slice(-2);
    const day = ('0' + d.getDate()).slice(-2);
    const hours = ('0' + d.getHours()).slice(-2);
    const minutes = ('0' + d.getMinutes()).slice(-2);
    const seconds = ('0' + d.getSeconds()).slice(-2);
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  };



const SoReport = () => {

    const [pageLoader, setPageLoader] = useState(false);
    const [list, setList] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [page, setPage] = useState(1);
    const [fileUploadLoader, setFileUploadLoader] = useState(false);
    const [successMsg, setSuccessMsg] = useState('');
    const [tempSoReport, setTempSoReport] = useState([]);
    const [downloadReportModal, setDownloadReportModal] = useState(false);
    const [uploadReportModal, setUploadReportModal] = useState(false);
    const [jcList, setJcList] = useState([]);
    const [jc, setJc] = useState('');
    const [jcName, setJcName] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [file, setFile] = useState('');
    const [fileErr, setFileErr] = useState('');
    const [downloadTab, setDownloadTab] = useState(1);
    const [missingBakeries, setMissingBakeries] = useState([]);
    const [missingEmails, setMissingEmails] = useState([]);
    const [resErr, setResErr] = useState('');
    const [csvData, setCsvData] = useState([]);
    const [csvData2, setCsvData2] = useState([]);
    const [currentJc, setCurrentJc] = useState('');

    const limit = 15;


    const getJCs = async () => {
        try {
            const res = await getJCService();
            if(res.data.statusCode){
                setJcList(res.data.responseData || [])
            }
        } catch (error) {
            console.error('Error fetching JC List:', error);
        }
    }


    useEffect(() => {
        getJCs()
    }, [])




    const getAllSoReport = async () => {
        const query = `?page=${page}&limit=${limit}`;
        setPageLoader(true);
        try {
            const res = await getAllSoReportService(query);
            if(res.data.statusCode){
                setList(res.data.responseData.result);
                if(res.data.responseData.result.length){
                    setCurrentJc(res.data.responseData.result[0].jc)
                }
               
                
                setTotalCount(res.data.responseData.total);
                setPageLoader(false);

            }
        } catch (error) {
            setPageLoader(false);
            console.error('Error fetching SO report:', error);
        }
    };



    const downloadReport = (data) => {
        const ws = XLSX.utils.aoa_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        const now = new Date();
        const day = String(now.getDate()).padStart(2, '0');
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const year = String(now.getFullYear()).slice(-2);
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');


        XLSX.writeFile(wb, `so_report-${day}-${month}-${year}-${hours}-${minutes}-${seconds}.xlsx`);
    };

    useEffect(() => {
        getAllSoReport()
    }, [page])


    const handleValidate = () => {
        let validate = true;

        if(!file){
            setFileErr('File is required');
            validate = false;
        }

        if(downloadTab == 1){
            if(jcList.length != 0){
                if(!jc){
                    setFileErr('Please select JC');
                    validate = false;
                }
            }
            
        }

        if(jcList.length == 0 || downloadTab == 2){
            
            if(!endDate){
                setFileErr('Please select end date ');
                validate = false;
            }

            if(!startDate){
                setFileErr('Please select start date ');
                validate = false;
            }

            if(!jcName){
                setFileErr('Please enter JC name');
                validate = false;
            }
        }

        

        return validate
    }



    const handleFileUpload = async (e) => {

        if(handleValidate()){
            const formData = new FormData();
            formData.append("file", file);
            if(jc.value){
                formData.append("jc", jc.value);
            }else{
                formData.append("jc", jcName);
            }
            if(startDate){
                formData.append("startDate", formatDate(startDate));  
            }
            if(endDate){
                formData.append("endDate", formatDate(endDate));  
            }
           
            try {
                const response = await uploadSoReportService(formData);
                if(response.data.statusCode){
                    setUploadReportModal(false);
                    setSuccessMsg(response.data.responseData.message)
                    setPage(1);
                    getAllSoReport();
                    getJCs();
                    setJcName('')
                    setJc('')
                    setStartDate('')
                    setEndDate('');
                    setFile('')
                }else{
                    
                    if(response.data.error.errorCode == 5){
                        if(response.data.error.responseMessage.missingBakeries){
                            setMissingBakeries(response.data.error.responseMessage.missingBakeries);
                            let _tempSoReport = response.data.error.responseMessage.missingBakeries.map(item => ([item.code, item.bakery]));
                            setCsvData2([["Bakery Code", "Bakery Name"], ..._tempSoReport]);
                            setUploadReportModal(false);
                        }else{
                            setResErr(response.data.error.responseMessage);
                        }

                        if(response.data.error.responseMessage.missingEmails){
                            setMissingEmails(response.data.error.responseMessage.missingEmails);
                            let _tempSoReport = response.data.error.responseMessage.missingEmails.map(item => ([item]));
                            setCsvData([["SO Email"], ..._tempSoReport]);
                            setUploadReportModal(false);
                        }else{
                            setResErr(response.data.error.responseMessage);
                        }



                        
                        
                    }
                }
    
            } catch (error) {
            } finally {
                setFileUploadLoader(false);
            }
        }

        
    }
    


    useEffect(() => {
        if(successMsg){
            setTimeout(() => {
                setSuccessMsg('')
            }, 3000)
        }
    }, [successMsg])


    const getSoListServiceByJcName = () => {
        let params = {
            jcName: jc.value
        }

        setDownloadReportModal(false);
        getSoListServiceByJcNameService(params).then(res => {
            if(res.data.statusCode){
                let _tempSoReport = res.data.responseData.map(item => [
                    item.userName,
                    item.conversion,
                    item.reason,
                    item.comment,
                    item.asm_name,
                    item.bakeryCode,
                    item.bakeryName,
                    item.categoryName,
                    item.city,
                    item.customerClassfication,
                    item.customerStatus,
                    item.dbCode,
                    item.dbName,
                    item.lastMonthTonnage,
                    item.currentBilledTonnage,
                    item.region,
                    item.skuName,
                    item.skuOpportunity,
                    item.so_email,
                ]);
                _tempSoReport.unshift(titles);
                setTempSoReport(_tempSoReport);
                downloadReport(_tempSoReport);
            }
        })
    }



    


    
    return (
        <div className="page_wrapper">
            {successMsg ? <div className="cm_toast">{successMsg}</div> : ''}


            <div className="container-box">

                <div className="search_part d-flex align-items-center justify-content-between w-100 mt-3 mb-2">
                    <h4 className="semibold-font mb-0 ms-2">SO Report <a href={demoTemplate} download={demoTemplate} className="btn btn-success btn-sm me-3">Download Template</a></h4>
                    <h5>Current JC: {currentJc}</h5>
                    <div className="btn_part">
                    <span className="btn btn-primary btn-sm me-3" onClick={() => setDownloadReportModal(true)}>Download SO Report</span>
                    <span className="btn btn-primary btn-sm me-3" onClick={() => setUploadReportModal(true)}>Upload Report</span>
                        {/* <span className="add-btn text-white box-shadow medium-font grey-font text-decoration-none cm_upload_report_btn">
                            Upload Report {fileUploadLoader ? <Spinner className="spiner" animation="border" /> : <input onChange={handleFileChange} type="file" accept=".xls,.xlsx" />}
                        </span> */}
                    </div>
                </div>

                <div className="table-responsive">
                    <Table style={{width: '5000px'}} className="table box-shadow">
                        <thead>
                            <tr>
                                <th>S. No</th>
                                <th>SO Name</th>
                                <th>Conversion</th>
                                <th>Reason</th>
                                <th>Comment</th>
                                <th>ASM Name</th>
                                <th>Bakery Code</th>
                                <th>Bakery Name</th>
                                <th>Category</th>
                                <th>City</th>
                                <th>Customer Classfication</th>
                                <th>Customer Status</th>
                                <th>DB Code</th>
                                <th>DB Name</th>
                                <th>Last Month Tonnage</th>
                                <th>Current Billed Tonnage</th>
                                <th>Region</th>
                                <th>SKU Name</th>
                                <th>SKU Opportunity</th>
                                <th>SO Email</th>
                                <th>Created At</th>
                                <th>Updated At</th>
                            </tr>
                        </thead>
                        <tbody>
                        
                            {list?.length ? list.map((item, index) => (
                                <tr>
                                    <td><span>{((index + 1) + ((page - 1) * limit))}</span></td>
                                    <td>{item.userName}</td>
                                    <td>{item.conversion || ''}</td>
                                    <td>{item.reason}</td>
                                    <td>{item.comment}</td>
                                    <td>{item.asm_name}</td>
                                    <td>{item.bakeryCode}</td>
                                    <td>{item.bakeryName}</td>
                                    <td>{item.categoryName}</td>
                                    <td>{item.city}</td>
                              
                                    <td>{item.customerClassfication}</td>
                                    <td>{item.customerStatus}</td>
                                    <td>{item.dbCode}</td>
                                    <td>{item.dbName}</td>
                                    <td>{item.lastMonthTonnage}</td>
                                    <td>{item.currentBilledTonnage}</td>
                                    <td>{item.region}</td>
                                    <td>{item.skuName}</td>
                                    <td>{item.skuOpportunity}</td>
                                    <td>{item.so_email}</td>
                                    <td>{dateFormat(item.createdAt, "dS mmmm yyyy")}</td>
                                    <td>{dateFormat(item.updatedAt, "dS mmmm yyyy")}</td>
                                </tr>
                            )) :
                                <tr className="text-center">
                                    <td></td>
                                    <td className="text-end no_data">
                                        <p>No Data Found</p>
                                    </td>
                                </tr>
                            }
                        </tbody>

                    </Table>
                </div>
                        {pageLoader ? <div className='loader_data'><PageLoader /></div> : ""}

                        {list.length ? <div className="cm_pagination mt-4"><Pagination
                            activePage={page}
                            itemsCountPerPage={limit}
                            totalItemsCount={totalCount}
                            pageRangeDisplayed={5}
                            onChange={p => setPage(p)} /></div> : ''}
                    
                    </div>



                    <Modal backdrop="static" show={uploadReportModal} onHide={() => setUploadReportModal(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Upload Options</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            {
                                jcList.length ? <Fragment>
                                    <span onClick={() => (setDownloadTab(1) , setJc(''))} className={`btn me-3 btn-${downloadTab == 1 ? 'primary' : 'dark'}`}>Select by JC</span>
                                    <span onClick={() => (setDownloadTab(2), setEndDate(''), setStartDate(''))} className={`btn btn-${downloadTab == 2 ? 'primary' : 'dark'}`}>Create New JC</span>
                                </Fragment> : ''
                            }


                            {
                                downloadTab == 1 ? <Fragment>
                                {jcList.length ? <div className="mt-4">
                                    <label>Select JC Calendar*</label>
                                    <Select
                                        value={jc}
                                        placeholder="Select JC Calendar"
                                        onChange={e => (setJc(e), setFileErr(''), setStartDate(new Date(e.startDate).toISOString().split('T')[0]), setEndDate(new Date(e.endDate).toISOString().split('T')[0]))}
                                        options={jcList.map(i => ({...i, label: i.jc, value: i.jc}))} />
                                </div> : ''}

                                {console.log(jc, 'adssdadsd')}

                                {
                                    jc.startDate && jc.endDate ? <div className="row mt-5">
                                    <div className="col-6">
                                        <label>Start Date*</label>
                                        <input
                                            placeholder="Start Date"
                                            value={startDate}
                                            onChange={e => (setStartDate(e.target.value), setFileErr(''), setResErr(''))}
                                            type="date"
                                            className="form-control" />
                                    </div>

                                    <div className="col-6">
                                        <label>End Date*</label>
                                        <input
                                            placeholder="End Date"
                                            value={endDate}
                                            onChange={e => (setEndDate(e.target.value), setFileErr(''), setResErr(''))}
                                            type="date"
                                            className="form-control" />
                                    </div>
                                </div> : ''
                                }

                                
                                </Fragment> : ''
                            }


                            {
                                jcList.length == 0 || downloadTab == 2 ? <Fragment>
                                    <div className="mt-4">
                                        <label>Add New JC Calendar Name*</label>
                                        <input
                                            value={jcName}
                                            placeholder="Add New JC Calendar Name"
                                            className="form-control"
                                            onChange={e => (setJcName(e.target.value), setFileErr(''), setResErr(''))}/>
                                    </div>

                                

                                    <div className="row mt-5">
                                        <div className="col-6">
                                            <label>Start Date*</label>
                                            <input
                                                placeholder="Start Date"
                                                value={startDate}
                                                onChange={e => (setStartDate(e.target.value), setFileErr(''), setResErr(''))}
                                                type="date"
                                                className="form-control" />
                                        </div>

                                        <div className="col-6">
                                            <label>End Date*</label>
                                            <input
                                                placeholder="End Date"
                                                value={endDate}
                                                onChange={e => (setEndDate(e.target.value), setFileErr(''), setResErr(''))}
                                                type="date"
                                                className="form-control" />
                                        </div>
                                    </div>
                                </Fragment> : ''
                            }

                            

                            
                            
                            

                            

                            <div className="mt-5">
                                <label>Select File*</label>
                                <input
                                    className="form-control"
                                    onChange={e => (setFile(e.target.files[0]), setResErr(''))}
                                    type="file"
                                    accept=".xls,.xlsx" />
                            </div>
                            {fileErr ? <h6 className="cm_err mb-0 text-center mt-4">{fileErr}</h6> : ''}

                            {resErr ? <h6 className="cm_err">{resErr}</h6> : ''}

                            <span onClick={handleFileUpload} className="btn btn-primary mt-5 mb-3 px-5">{downloadTab == 1 ? 'Update' : 'Submit'}</span>


                        </Modal.Body>
                    </Modal>

                    <Modal show={downloadReportModal} onHide={() => setDownloadReportModal(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Download Options</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="mt-4">
                                <label>Select JC Calendar</label>
                                <Select
                                    value={jc}
                                    placeholder="Select JC Calendar"
                                    onChange={e => setJc(e)}
                                    options={jcList.map(i => ({label: i.jc, value: i.jc}))} />

                                <span onClick={getSoListServiceByJcName} className="btn btn-primary mt-5 mb-3 px-5">Download</span>
                            </div>
                        </Modal.Body>
                    </Modal>



                    <Modal show={missingBakeries.length || missingEmails.length} onHide={() => (setMissingBakeries([]), setMissingEmails([]))}>
                        <Modal.Header closeButton>
                            <Modal.Title>Missing List</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="mt-4">
                                {
                                    missingBakeries.length? <div>
                                        <div className="d-flex justify-content-between mb-3">
                                            <h5>Missing Bakeries:</h5>
                                            <CSVLink className="btn btn-dark btn-sm" data={csvData2}>Download CSV</CSVLink>
                                        </div>
                                        <ul>
                                            {missingBakeries.map((item, i) => (
                                                <li key={i}>{item.bakery}</li>
                                            ))}
                                        </ul>
                                    </div> : ''
                                }

                                {
                                    missingEmails.length? <div>
                                        <div className="d-flex justify-content-between mb-3">
                                            <h5>Missing Emails:</h5>
                                            <CSVLink className="btn btn-dark btn-sm" data={csvData}>Download CSV</CSVLink>
                                        </div>
                                        <ul>
                                            {missingEmails.map((item) => (
                                                <li key={item}>{item}</li>
                                            ))}
                                        </ul>
                                    </div> : ''
                                } 



                                <span onClick={() => (setMissingBakeries([]), setMissingEmails([]))} className="btn btn-dark">Close</span> 

                            </div>
                        </Modal.Body>
                    </Modal>
            </div>




    )
}

export default SoReport;