import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import ModalLogo from "../../assets/images/distribution (2).svg";
import Table from 'react-bootstrap/Table';
import EditIcon from "../../assets/images/edit (5).svg";
import DeleteIcon from "../../assets/images/trash (1).svg";
import CrossIcon from "../../assets/images/cross_icon.svg";
import ArrowDown from "../../assets/images/arrow-down.svg";
import ArrowUp from "../../assets/images/arrow-up.svg";

import { addDistributorService, deleteDistributorService, getCityService } from "../../services/userService";
import { getDistributorService, updateDistributorService } from "../../services/userService";
import { PageLoader } from "../svg";
import SearchIcon from "../../assets/images/search (5).svg";
import ReactPaginate from "react-paginate";
import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { isValidName } from "../helper";



const Distributors = () => {
    const [show, setShow] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [updateModal, setUpdateModal] = useState(false);
    const [updateDistributor, setUpdateDistributor] = useState("");
    const [updateDistributorErr, setUpdateDistributorErr] = useState("");

    const [list, setList] = useState("");
    const [loader, setLoader] = useState(true);



    const [distributorName, setDistributorName] = useState('');
    const [distributorNameErr, setDistributorNameErr] = useState('');

    const [city, setCity] = useState('');
    const [cityErr, setCityErr] = useState('');
    const [id, setId] = useState('');
    const [search, setSearch] = useState('');
    const [cityList, setCityList] = useState('');

    const [sortKey, setSortKey] = useState('');
    const [type, setType] = useState('');




    const [page, setPage] = useState(1);

    const [pageCount, setPageCount] = useState(1);
    const [totalCount, setTotalCount] = useState(1);
    const [recordCount, setRecordCount] = useState(0);


    const limit = 10;
    localStorage.removeItem('page');







    const handleAdd = () => {
        setShow(true)
    }

    const handleClose = () => {
        setShow(false)
        setDeleteModal(false)
        setDistributorName('')
        setCity('')
        setCityErr('')
        setDistributorNameErr('')
        setUpdateDistributorErr('')
    }
    const handleDelete = (e) => {
        setDeleteModal(true)
        setId(e)
    }

    const handleUpdateFunction = (e) => {
        setUpdateModal(true)

        setUpdateDistributor(e);
    }
    const handleUpdateCloseFunction = (e) => {

        setUpdateModal(false)

    }


    const distributorList = (query) => {
        if (!query) {

            query = {
                "limit": limit,
                "page": page,
                "sort_key": sortKey,
                "type": type

            }
        }
        getDistributorService(query).then((res) => {
            let count = res.data.responseData.count;
            setRecordCount(count);


            let numberOfPages = Math.ceil(count / 10);
            setTotalCount(numberOfPages);

            setLoader(false)
            setList(res.data.responseData.result)

        })



    }

    useEffect(() => {
        distributorList()

    }, [type, sortKey])


    useEffect(() => {
        let _intervalTime;
      
        distributorList({
            limit: limit,
            page: 1,
            ...((search!=='' && search!==null) &&  {search: search}),
            ...((type!=='' && type!==null) &&  {type: type}),
            ...((sortKey!=='' && sortKey!==null) &&  {sort_key: sortKey}),

        })
        return () => {
            clearTimeout(_intervalTime);
        };
        // eslint-disable-next-line
    }, [search]);


    const validation = () => {



        let validate = true;
        if (distributorName == "") {
            setDistributorNameErr("Distributor is required");
            validate = false;

        }
        else if (distributorName.length < 2) {
            setDistributorNameErr("Distributor name should be between 2 to 30");
            validate = false;

        }

        if(!isValidName(distributorName)){
            setDistributorNameErr("Numeric values and Special characters not allowed");
            validate = false;
        }

        if (city == "") {
            setCityErr("City is required");
            validate = false;

        }

        return validate;
    }

    const updateValidation = () => {
        let validate = true;
        if (updateDistributor.name == "") {
            setUpdateDistributorErr("Distributor is required");
            validate = false;

        }else if (updateDistributor.name.length < 2) {
            setUpdateDistributorErr("Distributor name should be between 2 to 30");
            validate = false;

        }

        if(!isValidName(updateDistributor.name)){
            setUpdateDistributorErr("Numeric values and Special characters not allowed");
            validate = false;
        }
        

        return validate;
    }


    const handleClick = () => {
        if (validation()) {
            let temp = {
                distributorName: distributorName.trim(),
                city: city,
            }

            if (!temp.distributorName) {
                setDistributorNameErr("Distributor name cannot be empty");

            }

            temp.distributorName && addDistributorService(temp).then((res) => {
                handleClose()

                distributorList()

            })


        }
    }


    const handleUpdateClick = (e) => {
        e.preventDefault();
        if (updateValidation()) {

            if (updateDistributor.name && updateDistributor.city) {
                updateDistributorService({ name: updateDistributor.name.trim(), city: updateDistributor.city, _id: updateDistributor.id }).then(res => {
                    if (res.data.error.errorCode == 1) {
                        setUpdateDistributorErr("Distributor name cannot be empty")
                    }
                    else {
                        handleUpdateCloseFunction();

                        distributorList();

                    }


                })
            }
        }

    }


    const handlePageClick = (event) => {
        let pageNumber = event.selected + 1;

        setPage(event.selected + 1);
        

        distributorList({
            limit: limit,
            page: pageNumber,
            ...((search!=='' && search!==null) &&  {search: search}),
            ...((type!=='' && type!==null) &&  {type: type}),
            ...((sortKey!=='' && sortKey!==null) &&  {sort_key: sortKey}),

        })

        

    }

    const updateDistributorFunctionData = (event, name) => {
        setUpdateDistributor((prev) => ({ ...prev, [name]: event }))
    }


    const deleteDistributor = () => {
        deleteDistributorService({ _id: id }).then((res) => {
            handleClose();

            distributorList();
            if (res.data.error.errorCode == 5) {
                NotificationManager.error('Distributor can not delete because it is mapped with some user');


            }



        })

    }

    const getCityList = () => {
        getCityService().then((res) => {

            setCityList(res.data.responseData.result);

        })
    }
    useEffect(() => {
        getCityList()

    }, [])

    const arrowClick = (sortType, sortOrder) => {
        setSortKey(sortType)
        setType(sortOrder)


    }

    const searchChange = (e) =>{
        setSearch(e.target.value)
        setPage(0)
    }


    return (
        <>
            <div className="page_wrapper">
                    <div className="container-box">

                        <NotificationContainer />


                        <div className=" distributor-head">
                            <div className="row mt-4">
                                <div className="col-md-4">
                                    <h4 className="semibold-font">DISTRIBUTORS</h4>
                                </div>

                                <div className="col-md-5 ">
                                    <img src={SearchIcon} className="search-icon" />



                                    <input type="text" placeholder="Search by name" name="search" className=" search box-shadow light-italic-font" value={search} onChange={searchChange}>

                                    </input>

                                </div>




                                <div className="col-md-2  ">
                                    <button className="add-btn text-white box-shadow  medium-font grey-font" onClick={handleAdd}>Add Distributors
                                    </button>
                                </div>


                            </div>
                        </div>
                        <div className=" ">
                            <div className="table-content">
                                <Table className=" box-shadow common-black-color">
                                    <thead>
                                        <tr className="medium-font font-14px">
                                            <th>ID</th>
                                            <th>Name
                                                <span className="arrows pointer">
                                                    <img src={ArrowUp} alt="arrow-up" height={10} width={10} onClick={() => arrowClick("name", 1)} />

                                                    <img src={ArrowDown} alt="arrow-down " height={10} width={10} onClick={() => arrowClick("name", -1)} />
                                                </span>
                                            </th>
                                            <th className="sort pointer">City
                                                <span className="arrows ">
                                                    <img src={ArrowUp} alt="arrow-up" height={10} width={10} onClick={() => arrowClick("city", 1)} />

                                                    <img src={ArrowDown} alt="arrow-down " height={10} width={10} onClick={() => arrowClick("city", -1)} />
                                                </span></th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>

                                    <tbody>

                                        {list && list.map((item, index) => (
                                            <>
                                                <tr className="regular-font font-14px">
                                                    <td>{(limit * pageCount) + index + 1}</td>
                                                    <td className="td-2">{item.name}</td>
                                                    <td>{item.city}</td>
                                                    <td>
                                                        <img src={EditIcon} alt="edit" onClick={() => handleUpdateFunction(item)} />
                                                        <img src={DeleteIcon} alt="delete" className="dlt-icon" onClick={() => handleDelete(item.id)} />

                                                    </td>

                                                </tr>
                                            </>

                                        ))}
                                        {list?.length ? "" :
                                            <tr className="text-center">
                                                <td></td>

                                                <td className="text-end no_data">

                                                    <p>No Data Found</p>
                                                </td>

                                            </tr>
                                        }












                                    </tbody>

                                </Table>
                            </div>
                            {loader ? <div className='loader_data'><PageLoader /></div> : ""}

                            {recordCount > limit ? <ReactPaginate
                                previousLabel={"previous"}
                                nextLabel={"next"}
                                breakLabel={"..."}
                                pageCount={totalCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={3}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination "}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                activeClassName={"active"}
                                hrefBuilder={(page, pageCount, selected) =>
                                    setPageCount(selected)
                                }
                            /> : null}


                        </div>


                    </div>
            </div>

            {/*Add Distributor modal*/}


            <Modal show={show} onHide={handleClose} centered >
                <Modal.Header closeButton className="display"></Modal.Header>

                <Modal.Body>
                    <div className='modal-box'>
                        <div className="text-center">
                            <img src={ModalLogo} />
                            <h5 className="semibold-font mt-3">Add Distributor</h5>
                        </div>
                        <input type="text" placeholder="DISTRIBUTOR NAME" className="form-control mt-4 smaller-font" value={distributorName} maxLength={30} onChange={(e) => (setDistributorName(e.target.value), setDistributorNameErr(''))} />
                        <div className="error-message">{distributorNameErr ? distributorNameErr : ""}</div>


                        <select className="form-select mt-3 smaller-font options" placeholder="CITY" aria-label="Default select example" onChange={(e) => (setCity(e.currentTarget.value), setCityErr(''))} >
                            <option selected disabled>CITY </option>

                            {cityList && cityList.map((e, i) => (

                                <option key={i} value={e.city_name} >{e.city_name}

                                </option>




                            ))}


                        </select>
                        <div className="error-message">{cityErr ? cityErr : ""}</div>

                        <button className="add-modal-btn mt-3  btn-padding text-white" onClick={handleClick}>ADD</button>
                    </div>

                </Modal.Body>

            </Modal>

            {/*Delete Distributor modal*/}


            <Modal show={deleteModal} onHide={handleClose} centered>
                <Modal.Header className="display"></Modal.Header>

                <Modal.Body>
                    <div className='modal-box'>
                        <div className="text-center">
                            <img src={CrossIcon} alt="cross-icon" />
                            <h5 className="semibold-font mt-3">Are you Sure?</h5>
                            <h6 className="regular-font ">Are you sure you want to delete <br></br>this Distributor from the list?</h6>
                            <button className="confirm-btn mt-3 text-white" onClick={deleteDistributor}>YES,DELETE</button>
                            <div>
                                <button className="cancel-btn mt-2 medium-font " onClick={handleClose}>Cancel</button>
                            </div>


                        </div>

                    </div>

                </Modal.Body>

            </Modal>

            {/* Update Modal */}


            <Modal show={updateModal} onHide={handleUpdateCloseFunction} centered >
                <Modal.Header closeButton className="display"></Modal.Header>

                <Modal.Body>
                    <div className='modal-box'>
                        <div className="text-center">
                            <img src={ModalLogo} />
                            <h5 className="semibold-font mt-3">Update Distributor</h5>
                        </div>
                        <input type="text" placeholder="DISTRIBUTOR NAME" className="form-control mt-4 smaller-font" value={updateDistributor.name} onChange={(e) => (updateDistributorFunctionData(e.target.value, "name"), setUpdateDistributorErr(''))} />
                        <div className="error-message">{updateDistributorErr ? updateDistributorErr : ""}</div>




                        <select className="form-select mt-3 smaller-font options" placeholder="CITY" aria-label="Default select example" value={updateDistributor.city} onChange={(e) => updateDistributorFunctionData(e.target.value, "city")} >
                            <option selected disabled>CITY </option>

                            {cityList && cityList.map((e, i) => (

                                <option key={i} value={e.city_name} >{e.city_name}

                                </option>




                            ))}


                        </select>

                        <button className="add-modal-btn mt-3  update-btn-padding text-white" onClick={handleUpdateClick}>UPDATE</button>
                    </div>

                </Modal.Body>

            </Modal>


        </>

    )
}
export default Distributors;