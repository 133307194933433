import React, {useState} from "react";
import EditIcon from "../../assets/images/edit (5).svg";
import DeleteIcon from "../../assets/images/trash (1).svg";
import ViewIcon from "../../assets/images/view.svg";
import { Link } from "react-router-dom";

const Recipe = (props) => {
    const [item, setItem] =useState(props?.item);
  return (
    <div className="box">
      <div className="recipe-box box-shadow">
        <div className="text-align-center">
        <Link to={`/recipes/detail/${item._id}`}>
          <img
            src={item?.recipe_doc}
            alt={item?.name}
            className="border-radius-5"
            height={160}
            width={160}
          />
          </Link>
        </div>
        <div className="mt-3 left-margin-10">
          <h5 className="_recipe_heading">
            {item?.name}
          </h5>
          <h6 className="grey-text created_by_recipe">By {item?.created_byName}</h6>
          <div className="_recipe_icon_wrapper">
            <Link to={`/recipes/edit/${item._id}`}><img src={EditIcon} alt="edit" className="dlt-icon" /></Link>
            <img src={DeleteIcon} alt="delete" className="dlt-icon" onClick={() => props?.handleDelete(item._id)}/>
            <Link to={`/recipes/detail/${item._id}`}><img src={ViewIcon} alt="view" className="dlt-icon" /></Link>
            <Link className="ul_btn btn_sm mt-0" to={`/recipes/edit-language/${item._id}`}>
                <img src={EditIcon} className="dlt-icon" alt="edit" />
                <small>Language</small>
              </Link>
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default Recipe;
