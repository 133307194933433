import { useParams } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import { getRecipeService } from "../../services/userService";


const RecipeDetail = () => {
    let { id } = useParams();
    const [recipeData, setRecipeData] = useState({});

    const [name, setName] = useState('');
    const [created_by, setCreated_by] = useState('');
    const [preparation_method, setPreparation_method] = useState('');
    const [ingredients, setIngredients] = useState([]);
    const [baking_temperature, setBaking_temperature] = useState('');
    const [baking_time, setBaking_time] = useState('');
    const [season, setSeason] = useState([]);
    const [dish, setDish] = useState([]);
    const [product, setProduct] = useState([]);
    const [dietary, setDietary] = useState([]);
    const [recipe_doc, setRecipe_doc] = useState('');
    const [recipe_docView, setRecipe_docView] = useState('');
    const [recipe_url, setRecipe_url] = useState('');

    const getRecipeDetail = () => {
        let params = {_id: id}
        getRecipeService(params).then(res => {
            if (res?.data?.statusCode) {
                setRecipeData(res?.data?.responseData?.result[0] || {})

                let _data = res.data.responseData.result[0] || {};
                    setName(_data.name);
                    setRecipe_docView(_data.recipe_doc);
                    setBaking_temperature(_data.baking_temperature);
                    setBaking_time(_data.baking_time);

                    let updated_ingredients = _data.ingredients.map(item => ({
                        heading: item.heading.en,
                        list: item.list.map(jj => ({value: jj.value, desc: jj.desc.en}))
                    }))

                    setIngredients(updated_ingredients);
                    setSeason(_data.season);
                    setDish(_data.dish);
                    setDietary(_data.dietary);
                    setProduct(_data.product);
                    setPreparation_method(_data.preparation_method.en);
                    setCreated_by({label: _data.created_byName, value: _data.created_byId});
            } else {
      
            } 
        })  
    };


    useEffect(() => {
        if(id){
            getRecipeDetail()
        }
    }, [id])

    return (
        <div className="page_wrapper">
            <div className="container-box">
                <div className="mt-5">
                    <h4 className="semibold-font"> RECIPE DETAIL</h4>

                    <div className='page_box'>
                        <h3 className='page_box_title'>RECIPE INGREDIENTS</h3>

                            <div className="row">
                                <div className="col-md-3">
                                    <div className="image-box image-upload">
                                        <img src={recipe_docView} className="img-preview " alt="show" />
                                    </div>
                                </div>
                                <div className="col-md-9">
                                <h5>{name}</h5>
                                <h6 className="grey-text">By {created_by.label}</h6>

                                <hr />

                                <div className="recipe_ingredient">
                                    <h2 className="product-heading">Recipe Ingredients</h2>

                                    {
                                        ingredients?.map((item, index) => (
                                            <Fragment key={index}>
                                                <div className="row_view heading color-blue">
                                                    <p>{item.heading}</p>
                                                </div>

                                                {
                                                    item?.list?.map((itm, indx) => (
                                                        <Fragment key={indx}>
                                                            <div className="row_view heading border-b mb-0">
                                                                <p>{itm.desc}</p>
                                                                <p>{itm.value}</p>
                                                            </div>
                                                        </Fragment>
                                                    ))
                                                }

                                            </Fragment>
                                        ))
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    
                   


                </div>

            </div>
        </div>
    )
}
export default RecipeDetail;