


import { useEffect, useState } from "react";
import Select from "react-select";
import { MultiSelect } from "react-multi-select-component";
import {useParams, useNavigate, Link } from "react-router-dom";
import ReactQuill from "react-quill";
import { createPortal } from "react-dom";
import EditorToolbar, { modules, formats } from "../common/EditorToolbar";
import "react-quill/dist/quill.snow.css";

import camera from "../../assets/images/camera.svg";
import DeleteIcon from "../../assets/images/trash_.svg";
import EditIcon from "../../assets/images/edit (5).svg";
import { addRecipeService, getPillsburyChefsService, getRecipeService, translateContentService, updateRecipeService, uploadFileService } from "../../services/userService";
import Notification from "../common/Notification";
import { DROP_DOWN_DATA } from "../../constants/dropDown";
import { Spinner } from "react-bootstrap";



const RecipeForm = () => {
    let { id } = useParams();
    const navigate = useNavigate();
    const [notificationStatus, setNotificationStatus] = useState(false);
    const [notificationProps, setNotificationProps] = useState({
        message: "",
        type: "info",
    });
    const [chefList, setChefList] = useState([]);


    const [name, setName] = useState('');
    const [created_by, setCreated_by] = useState('');
    const [preparation_method, setPreparation_method] = useState('');
    const [ingredients, setIngredients] = useState([]);
    const [baking_temperature, setBaking_temperature] = useState('');
    const [baking_time, setBaking_time] = useState('');
    const [season, setSeason] = useState([]);
    const [dish, setDish] = useState([]);
    const [product, setProduct] = useState([]);
    const [dietary, setDietary] = useState([]);
    const [recipe_doc, setRecipe_doc] = useState('');
    const [recipe_docView, setRecipe_docView] = useState('');
    const [recipe_url, setRecipe_url] = useState('');

    const [nameErr, setNameErr] = useState('');
    const [created_byErr, setCreated_byErr] = useState('');
    const [preparation_methodErr, setPreparation_methodErr] = useState('');
    const [ingredientsErr, setIngredientsErr] = useState('');
    const [baking_temperatureErr, setBaking_temperatureErr] = useState('');
    const [baking_timeErr, setBaking_timeErr] = useState('');
    const [seasonErr, setSeasonErr] = useState('');
    const [dishErr, setDishErr] = useState('');
    const [productErr, setProductErr] = useState('');
    const [dietaryErr, setDietaryErr] = useState('');
    const [recipe_docErr, setRecipe_docErr] = useState('');
    const [recipe_urlErr, setRecipe_urlErr] = useState('');

    const [btnLoader, setBtnLoader] = useState(false);


    const getChefsList = () => {
        getPillsburyChefsService().then((res) => {
          if (res?.data?.statusCode === 1) {
            let chefs = res?.data?.responseData?.data?.map((item, i) => {
              return { value: item?._id, label: item?.name };
            });
            setChefList(chefs);
          }
        });
    };

    useEffect(() => {
        getChefsList();
    }, [])


    const handleAddIngredient = () => {
        let newIngredients = [...ingredients, {heading: '',list: []}]
        setIngredients(newIngredients);
    }

    const handleHeadingChange = (e, index) => {
        let newIngredients = [...ingredients];
    
        newIngredients[index].heading = e.target.value
        setIngredients(newIngredients);
        setIngredientsErr('');
    }

    const handleAddSubIngredient = index => {
        let newIngredients = [...ingredients];
        let newSubIngredients = [...newIngredients[index].list, {desc: '', value: ''}];
        newIngredients[index].list = newSubIngredients;
        setIngredients(newIngredients);
    }


    const handleDescChange = (e, index, subIndex, type) => {
        let newIngredients = [...ingredients];
        setIngredientsErr('');
        let newSubIngredients = [...newIngredients[index].list];
        if(type == 'desc'){
            newSubIngredients[subIndex].desc = e.target.value;
        }
        if(type == 'value'){
            newSubIngredients[subIndex].value = e.target.value;
        }
        setIngredients(newIngredients);
    }


    const handleDeleteIngredients = index => {
        let newIngredients = [...ingredients];
        newIngredients.splice(index, 1);
        setIngredients(newIngredients);
    }


    const handleDeleteSubIngredients = (index, subIndex) => {
        let newIngredients = [...ingredients];
        let newSubIngredients = [...newIngredients[index].list];
        newSubIngredients.splice(subIndex, 1);
        newIngredients[index].list = newSubIngredients;
        setIngredients(newIngredients);
    }


    const getRecipeDetailById = () => {
        let params = {_id: id}
        
        getRecipeService(params).then(res => {
            if(res.data.statusCode){
                let _data = res.data.responseData.result[0] || {};
                setName(_data.name);
                setRecipe_docView(_data.recipe_doc);
                setBaking_temperature(_data.baking_temperature);
                setBaking_time(_data.baking_time);

                let updated_ingredients = _data.ingredients.map(item => ({
                    heading: item.heading.en,
                    list: item.list.map(jj => ({value: jj.value, desc: jj.desc.en}))
                }))

                setIngredients(updated_ingredients);
                setSeason(_data.season);
                setDish(_data.dish);
                setDietary(_data.dietary);
                setProduct(_data.product);
                setPreparation_method(_data.preparation_method.en);
                setCreated_by({label: _data.created_byName, value: _data.created_byId});
            }
        });
    }

    useEffect(() => {
        if(id){
            getRecipeDetailById(); 
        }
    }, [id])






    const handleFileChange = e => {
        let reader = new FileReader();
        let file = e.target.files[0];
        setRecipe_doc(file);
        setRecipe_docErr('');
        
        reader.addEventListener('load', () => {
            setRecipe_docView(reader.result);
        }, false);
        if (file) { reader.readAsDataURL(file) }
    }


    const handleValidate = () => {
        let validate = true;

        if (!recipe_docView) {
            setRecipe_docErr('Image is required');
            setNotificationStatus(true);
            setNotificationProps({type: "error", message: 'Image is required'});
            validate = false;
        }
    
        if (!name || name.trim() === '') {
            setNameErr('Name is required');
            setNotificationStatus(true);
            setNotificationProps({type: "error", message: 'Name is required'});
            validate = false;
        }

        if (!created_by.value) {
            setCreated_byErr('Chef is required');
            setNotificationStatus(true);
            setNotificationProps({type: "error", message: 'Chef is required'});
            validate = false;
        }

        if (!baking_temperature) {
            setBaking_temperatureErr('Baking Temperature is required');
            setNotificationStatus(true);
            setNotificationProps({type: "error", message: 'Baking Temperature is required'});
            validate = false;
        }

        if (!baking_time) {
            setBaking_timeErr('Baking Time is required');
            setNotificationStatus(true);
            setNotificationProps({type: "error", message: 'Baking Time is required'});
            validate = false;
        }

        if (season.length == 0) {
            setSeasonErr('Season is required');
            setNotificationStatus(true);
            setNotificationProps({type: "error", message: 'Season is required'});
            validate = false;
        }

        if (product.length == 0) {
            setProductErr('Product is required');
            setNotificationStatus(true);
            setNotificationProps({type: "error", message: 'Product is required'});
            validate = false;
        }

        if (dietary.length == 0) {
            setDietaryErr('Dietary is required');
            setNotificationStatus(true);
            setNotificationProps({type: "error", message: 'Dietary is required'});
            validate = false;
        }

        if (dish.length == 0) {
            setDishErr('Dish is required');
            setNotificationStatus(true);
            setNotificationProps({type: "error", message: 'Dish is required'});
            validate = false;
        }

        if (!preparation_method) {
            setPreparation_methodErr('Preparation is required');
            setNotificationStatus(true);
            setNotificationProps({type: "error", message: 'Preparation is required'});
            validate = false;
        }


        let err_ingredients = [...ingredients];

        const handleError = () => {
            setIngredientsErr('Ingredients all fields are required');
            setNotificationStatus(true);
            setNotificationProps({ type: "error", message: 'Ingredients all fields are required' });
            validate = false;
        }

        if(err_ingredients.length == 0){
            setIngredientsErr('Ingredient is required');
            setNotificationStatus(true);
            setNotificationProps({ type: "error", message: 'Ingredient is required' });
            validate = false;
        }


        err_ingredients.forEach(err => {
            if (!err.heading) {
                handleError();
            }
            if (err.list && err.list.length) {
                err.list.forEach(err_inn => {
                    if (!err_inn.desc) {
                        handleError();
                    }
                    if (!err_inn.value) {
                        handleError();
                    }
                });
            }
        });

        
    

    
        return validate;
    }


    const handleSubmit = async e => {
        e.preventDefault();

        if(handleValidate()){
            setBtnLoader(true);

            let _name;
            let _preparation_method;
            let trans_ingredient = [];

            try {

                let [trans_preparation_method, trans_name] = await Promise.all([
                    translateContentService({ text: preparation_method }),
                    translateContentService({ text: name })
                ]);

                _preparation_method = trans_preparation_method.data.responseData.data;
                _name = trans_name.data.responseData.data;



                for (const _ingredient of ingredients) {
                    const res = await translateContentService({ text: _ingredient.heading });
                    let trans_list = [];
                    for (const _list of _ingredient.list) {
                        let new_list_item = {..._list};
                        const res = await translateContentService({ text: _list.desc });
                        trans_list.push({value: new_list_item.value, desc: res.data.responseData.data });
                    }
                    trans_ingredient.push({heading: res.data.responseData.data, list: trans_list });
                }

        } catch (error) {
            console.error("Error occurred:", error);
        }

        const formData = new FormData();

        if(id){
            formData.append('_id', id); 
        }

        formData.append('name', name);
        formData.append('translated_name', JSON.stringify(_name));
        formData.append('preparation_method', JSON.stringify(_preparation_method));
        formData.append('ingredients', JSON.stringify(trans_ingredient));
        formData.append('season', JSON.stringify(season));
        formData.append('dish', JSON.stringify(dish));
        formData.append('product', JSON.stringify(product));
        formData.append('dietary', JSON.stringify(dietary));
        formData.append('recipe_doc', recipe_doc);
        formData.append('recipe_url', recipe_url);
        formData.append('created_by', created_by.value);
        formData.append('baking_temperature', baking_temperature);
        formData.append('baking_time', baking_time);


        let _service = id ? updateRecipeService : addRecipeService;


        _service(formData).then(res => {
            if (res?.data?.statusCode) {
                setNotificationProps({
                  message: res?.data?.responseData?.message,
                  type: "success",
                });
                setBtnLoader(false);
                navigate("/recipes");
                setNotificationStatus(true);
                setBtnLoader(false);
              } else {
                setNotificationProps({
                  message: "Please fill all the details correctly.",
                  type: "error",
                });
                setNotificationStatus(true);
                setBtnLoader(false);
              }
          })
       
        }
    }



    const handleUpload = (e) => {
        let _formData = new FormData();
        _formData.set("file", e.target.files[0]);
        uploadFileService(_formData).then((res) => {
          if (res?.data?.statusCode) {
            setRecipe_url(res.data.responseData.file_url);
          }
        });
      };
 

  return (
      <div className="page_wrapper">
          <div className="container-box">
            <div className="mt-5">
                <h4 className="semibold-font">{id ? 'Edit' : 'Create'} Recipe</h4>
                    <form onSubmit={handleSubmit}>
                        <div className='page_box'>
                            <div className='row pb-5'>
                                <div className='col-lg-3'>
                                    <div className="cm_file_upload">
                                        <input onChange={handleFileChange} type="file" />
                                        {recipe_docView ? <img className="cm_uploaded_file" src={recipe_docView} /> : <img className="cm_upload_icon" src={camera} />}
                                        {recipe_docView ? <img className="cm_edit_icon" src={EditIcon} /> : ''}
                                    </div>

                                    {recipe_docErr ? <div className="error-message text-center">{recipe_docErr}</div> : ''}
                                    <h6 className="text-center mt-2">Image size: 160 x 170</h6>
                                </div>

                                <div className='col-lg-5'>
                                    <div className="form-group">
                                        <label>Recipe name<b className="cm_hash">*</b></label>
                                        <input
                                            className="form-control"
                                            value={name}
                                            onChange={e => (setName(e.target.value), setNameErr(''))}
                                            type="text" />
                                        
                                        {nameErr ? <span className="error-message">{nameErr}</span> : ''}
                                    </div>

                                    <div className="form-group">
                                        <label>Select chef<b className="cm_hash">*</b></label>
                                        <Select
                                            className="w-100 category-select grey-text mt-2"
                                            options={chefList}
                                            value={created_by}
                                            onChange={e => (setCreated_by(e), setCreated_byErr(''))}
                                            placeholder="Select..."/>

                                        {created_byErr ? <span className="error-message">{created_byErr}</span> : ''}
                                    </div>

                                    <div className="form-group">
                                        <label>Add Video</label>
                                        <input onChange={handleUpload} className="form-control" type="file" />
                                    </div>

                                </div>
                            </div>

                           <div className="row"><div className="col-8"><hr /></div></div>

                           <div className="row">
                                <div className="col-8">
                                    <div className="c_ingredients_list">
                                        <h3>Ingredients</h3>

                                        {
                                            ingredients.map((item, index) => (
                                                <div key={index} className="c_ingredient">
                                                    <div className="d-flex justify-content-between">
                                                        <div className="form-group w-75">
                                                            <input
                                                                value={item.heading}
                                                                onChange={e => handleHeadingChange(e, index)}
                                                                placeholder="Enter Heading"
                                                                type="text"
                                                                className="form-control" />
                                                        </div>

                                                        <div className="w-25 d-flex justify-content-end">
                                                            <div>
                                                                <span onClick={() => handleAddSubIngredient(index)} className="btn btn-dark btn-sm">+ Add</span>
                                                                <span className="ms-3" onClick={() => handleDeleteIngredients(index)}>
                                                                    <img src={DeleteIcon} alt="delete" className="dlt-icon" width={'30px'} height={'30px'}/>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {
                                                        item.list.map((subItem, subIndex) => (
                                                            <div className="d-flex mb-3" key={subIndex}>
                                                                <div className="w-50 ms-5">
                                                                    <input
                                                                        type="text"
                                                                        value={subItem.desc}
                                                                        onChange={e => handleDescChange(e, index, subIndex, 'desc')}
                                                                        placeholder="Enter Ingredient"
                                                                        className="form-control" />
                                                                </div>
                                                                <div className="d-flex ms-3">
                                                                    <input
                                                                        type="text"
                                                                        value={subItem.value}
                                                                        onChange={e => handleDescChange(e, index, subIndex, 'value')}
                                                                        placeholder="Weight with unit"
                                                                        className="form-control" />
                                                                    <span className="ms-3" onClick={() => handleDeleteSubIngredients(index, subIndex)}>
                                                                        <img src={DeleteIcon} alt="delete" className="dlt-icon" width={'30px'} height={'30px'}/>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                  
                                                </div>
                                            ))
                                        }

                                        <div className="flex justify-content-end mt-3">
                                            <span className="btn btn-dark" onClick={handleAddIngredient}>+ Add</span>
                                        </div>
                                    </div>

                                    {ingredientsErr ? <span className="error-message">{ingredientsErr}</span> : ''}
                                </div>
                           </div>



                           <div className="row">
                            <div className="col-8"><hr /></div>
                           </div>



                           <div className='row pb-5 pt-5'>
                                <div className='col-lg-8'>
                                    <div className="row">

                                    <div className='col-lg-6'>
                                        <div className="form-group">
                                            <label>Baking Temperature</label>
                                            <input
                                                placeholder="170C – 180C"
                                                value={baking_temperature}
                                                onChange={e => (setBaking_temperature(e.target.value), setBaking_temperatureErr())}
                                                className="form-control"
                                                type="text" />
                                            
                                            {baking_temperatureErr ? <span className="error-message">{baking_temperatureErr}</span> : ''}
                                        </div>
                                    </div>
                                    <div className='col-lg-6'>

                                        <div className="form-group">
                                            <label>Baking Time (in minutes)</label>
                                            <input
                                                placeholder="27 – 30"
                                                value={baking_time}
                                                onChange={e => (setBaking_time(e.target.value), setBaking_timeErr(''))}
                                                className="form-control"
                                                type="text" />

                                            {baking_timeErr ? <span className="error-message">{baking_timeErr}</span> : ''}
                                        </div> 
                                    </div>

                                    
                                        <div className="col-6">
                                            <div className="form-group">
                                            <label>Select Seasons</label>
                                                <MultiSelect
                                                    options={DROP_DOWN_DATA?.seasonOptions}
                                                    value={season}
                                                    onChange={e => (setSeason(e), setSeasonErr(''))}
                                                    labelledBy="Select"
                                                    className="cm_multi_select_box" />

                                                {seasonErr ? <span className="error-message">{seasonErr}</span> : ''}
                                            </div>
                                        </div>

                                        <div className="col-6">
                                            <div className="form-group">
                                            <label>Select Dishes</label>
                                                <MultiSelect
                                                    options={DROP_DOWN_DATA?.dishesOptions}
                                                    value={dish}
                                                    onChange={e => (setDish(e), setDishErr(''))}
                                                    labelledBy="Select"
                                                    className="cm_multi_select_box" />

                                                {dishErr ? <span className="error-message">{dishErr}</span> : ''}
                                            </div>
                                        </div>

                                        <div className="col-6">
                                            <div className="form-group">
                                            <label>Select Product</label>
                                                <MultiSelect
                                                    options={DROP_DOWN_DATA?.productOptions}
                                                    value={product}
                                                    onChange={e => (setProduct(e), setProductErr(''))}
                                                    labelledBy="Select"
                                                    className="cm_multi_select_box" />

                                                {productErr ? <span className="error-message">{productErr}</span> : ''}
                                            </div>
                                        </div>


                                        <div className="col-6">
                                            <div className="form-group">
                                            <label>Select Dietery</label>
                                                <MultiSelect
                                                    options={DROP_DOWN_DATA?.ingredientOptions}
                                                    value={dietary}
                                                    onChange={e => (setDietary(e), setDietaryErr(''))}
                                                    labelledBy="Select"
                                                    className="cm_multi_select_box" />

                                                {dietaryErr ? <span className="error-message">{dietaryErr}</span> : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                         
                            <div className='row pb-5'>
                                <div className='col-lg-8'>
                                    <label>Preparation</label>
                                    <div className="text-editor">
                                        <EditorToolbar />
                                        <ReactQuill
                                            theme="snow"
                                            value={preparation_method}
                                            onChange={e => (setPreparation_method(e), setPreparation_methodErr(''))}
                                            placeholder={"Write something..."}
                                            modules={modules}
                                            formats={formats}
                                        />

                                    {preparation_methodErr ? <span className="error-message">{preparation_methodErr}</span> : ''}
                                    </div>


                                    <div className=" d-flex justify-content-end mt-5">
                                        <Link to="/recipes" className="btn btn-dark me-4 px-5">Cancel</Link>
                                        <button disabled={btnLoader} type="submit" className="btn btn-primary px-5">{btnLoader ? <Spinner className="spiner" animation="border" /> : ''} Save</button>
                                    </div>
                                </div>
                            </div>


                            


                        </div>


                       
                    </form>


            </div>
            </div>

            {notificationStatus && createPortal(<Notification {...notificationProps} />, document.querySelector("#notification-root"))}
      </div>
  );
};

export default RecipeForm;
