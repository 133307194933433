import { createSlice } from '@reduxjs/toolkit';
import { SLICE_NAME } from '../constants/slicesConstant';

const initialState = {
  recipeData: [],
  formData:{}
}

export const recipeSlice = createSlice({
  name: SLICE_NAME?.RECIPE_DATA,
  initialState,
  reducers: {
    newFormData : (state,action) => {
      state.formData = {...action?.payload};
    },
    setRecipes: (state,action) => {
      state.formData = [action?.payload]
    },
    
  },
})


export const { newFormData, setRecipes } = recipeSlice.actions

export default recipeSlice.reducer