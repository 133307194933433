import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { addPillsburyChefsService } from "../../../services/userService";
import camera from "../../../assets/images/camera.svg";
import Profile from "../../../assets/images/profile.png";
import { isEmailValid, isValidName, isValidPhoneNumber } from "../../helper";
import Dropdown from "./../../../Hooks/Dropdown";
import {
  getCityService,
  getASMListService,
} from "../../../services/userService";
import { MultiSelect } from "react-multi-select-component";


const AddChefs = () => {
  const [chefName, setChefName] = useState("");
  const [chefNameErr, setChefNameErr] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [emailAddressErr, setEmailAddressErr] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberErr, setPhoneNumberErr] = useState("");
  const [file, setFile] = useState("");
  const [showImage, setShowImage] = useState("");
  const [city, setCity] = useState("");
  const [cityErr, setCityErr] = useState("");

  const [cityList, setCityList] = useState([]);

  const [asmName, setAsmName] = useState("");
  const [asmList, setAsmList] = useState("");
  const [serviceOptions, setserviceOptions] = useState([]);
  const [ServiceSelected, setServiceSelected] = useState([]);

  const navigate = useNavigate();

  const handleKeyDown = (e) => {
    if (e.key === " " && chefName.length === 0) {
      e.preventDefault();
    }
  };

  const validation = () => {
    let validate = true;

    if (chefName == "") {
      setChefNameErr("Chef Name is required");
      validate = false;
    }else if(!isValidName(chefName)){
      setChefNameErr("Numeric values and Special characters not allowed");
      validate = false;
    }
    if (chefName.length < 2) {
      setChefNameErr("Chef Name should be between 2 to 30 ");
      validate = false;
    }
    if (emailAddress == "") {
      setEmailAddressErr("Email Address is required");
      validate = false;
    } else if (!isEmailValid(emailAddress)) {
      setEmailAddressErr("Email Address is invalid");
      validate = false;
    }
    if (phoneNumber == "") {
      setPhoneNumberErr("Phone Number is required");
      validate = false;
    }else if(!isValidPhoneNumber(phoneNumber)){
      setPhoneNumberErr("Phone Number not valid");
      validate = false;
    }

    if (city == "") {
      setCityErr("City is required");
      validate = false;
    }

    return validate;
  };

  const handleClick = () => {
    if (validation()) {
      let asmName = [];
     
      ServiceSelected &&
        ServiceSelected.map((x) => {
          asmName.push(x["label"]);
        });
        asmName = asmName.join(",");

      let formData = new FormData();
      formData.set("name", chefName);
      formData.set("profile_image", file);
      formData.set("email", emailAddress);
      formData.set("phone_number", phoneNumber);
      formData.set("user_role", "pillsbury_chef");
      formData.set("home_city", city);
      formData.set("asm_name", asmName);

      addPillsburyChefsService(formData).then((res) => {
        if (res.data.error.errorCode == 5) {
          setEmailAddressErr("Email Already Exists");
        } else if (res.data.error.errorCode == 3) {
          setPhoneNumberErr("Phone number Exists");
        } else {
          navigate("/pillsbury-chef");
        }
      });
    }
  };

  const handleCancel = () => {
    navigate("/pillsbury-chef");
  };

  const handleImageChange = (e) => {
    const file = new File([e.target.files[0]], e.target.files[0].name, {
      type: e.target.files[0].type,
    });
    setFile(file);
    setShowImage(window.URL.createObjectURL(e.target.files[0]));
  };
  const handleNumber = (e) => {
    const reg = new RegExp("^[0-9]+$");

    if (
      (reg.test(e.target.value) || phoneNumber.length == 1) &&
      e.target.value.length <= 14
    ) {
      setPhoneNumber(e.target.value);
    }
    setPhoneNumberErr("");
  };
  const getCityList = () => {
    getCityService().then((res) => {
      setCityList(res.data.responseData.result);
    });
  };

  const getAsmList = () => {
    getASMListService().then((res) => {
      setAsmList(res.data.responseData.result);
    });
  };

  useEffect(() => {
    getCityList();
    getAsmList();
  }, []);

  useEffect(() => {
    const serviceArr = [];
    asmList &&
      asmList.map((list, i) => {
          serviceArr.push({ label: list.asm_name, value: list._id });
        
      });
    setserviceOptions(
      serviceArr.sort((a, b) =>
        a.label < b.label ? -1 : a.value < b.value ? 1 : 0
      )
    );
  }, [asmList]);

  const selectedChange = (e) => {
    setServiceSelected(e);
  };

  return (
    <>
      <div className="page_wrapper">
          <div className="container-box">
            <div className=" distributor-head">
              <div className="row mt-5">
                <h4 className="semibold-font">ADD CHEF</h4>
                <div className="tab-box box-shadow border-radius">
                  <div className="add-product-box">
                    <div className="image-box image-upload ">
                      <label htmlFor="file-input">
                        {showImage ? (
                          <div className="">
                            <img
                              src={showImage}
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = { Profile };
                              }}
                              className="img-preview "
                            />
                          </div>
                        ) : (
                          <div className="camera-img left-margin-20">
                            <img src={camera} className="" />
                          </div>
                        )}
                      </label>
                      <input
                        id="file-input"
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                      />
                      {file ? (
                        ""
                      ) : (
                        <p className="smaller-font font-light mt-2 text-center">
                          Upload Image{" "}
                        </p>
                      )}
                    </div>
                    <h6 className="dimension">Image size: 160 x 170</h6>

                    <input
                      type="text"
                      placeholder="CHEF NAME"
                      className="form-control chef-box mt-4"
                      value={chefName}
                      maxLength={30}
                      onKeyDown={handleKeyDown}
                      onChange={(e) => (
                        setChefName(e.target.value), setChefNameErr("")
                      )}
                    ></input>
                    <div className="error-message mt-1">
                      {chefNameErr ? chefNameErr : ""}
                    </div>

                    <input
                      type="text"
                      placeholder="EMAIL ADDRESS"
                      className="form-control chef-box"
                      value={emailAddress}
                      onChange={(e) => (
                        setEmailAddress(e.target.value), setEmailAddressErr("")
                      )}
                    ></input>
                    <div className="error-message mt-1">
                      {emailAddressErr ? emailAddressErr : ""}
                    </div>

                    <input
                      type="text"
                      placeholder="PHONE NUMBER"
                      className="form-control chef-box"
                      value={phoneNumber}
                      onChange={handleNumber}
                    ></input>
                    <div className="error-message mt-1">
                      {phoneNumberErr ? phoneNumberErr : ""}
                    </div>
                    <Dropdown
                      list={cityList}
                      title="CITY"
                      value={city}
                      showValue="city_name"
                      bindValue="city_name"
                      setSelectedValue={setCity}
                      setError={setCityErr}
                      style="form-control chef-box"
                    ></Dropdown>
                    <div className="error-message">
                      {cityErr ? cityErr : ""}
                    </div>


                    <MultiSelect
                      options={serviceOptions}
                      value={ServiceSelected}
                      onChange={selectedChange}
                      labelledBy="Select"
                      className="chef-box "
                    />

                    <button
                      className="common-btn white-text save-bigger-btn mt-3"
                      onClick={handleClick}
                    >
                      SAVE
                    </button>
                    <div>
                      <button
                        className="cancel-btn save-bigger-cancel-btn mt-3 "
                        onClick={handleCancel}
                      >
                        CANCEL
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </>
  );
};
export default AddChefs;
