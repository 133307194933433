import React, {useEffect, useState,useRef} from 'react';
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "./EditorToolbar";
import "react-quill/dist/quill.snow.css";

const TextEditor = (props) => {
    const [editorValue, setEditorValue] = useState('');
    const editorRef = useRef();
    
    const handleChange = value => {
        setEditorValue(value);
        props?.handleChange(value);
      
    };

    useEffect(()=>{
        setEditorValue(props?.defaultValue);
    },[props?.defaultValue])



    return (
      <div className="text-editor">
        <EditorToolbar />
        <ReactQuill
          ref={editorRef}
          theme="snow"
          value={editorValue}
          onChange={(data)=>{
            handleChange(data)}}
          placeholder={"Write something..."}
          modules={modules}
          formats={formats}
        />
      </div>
    );
}

export default TextEditor