import {Table, Spinner} from "react-bootstrap";
import { useState, useEffect } from "react";
import Select from "react-select";

import {
  addFaqService,
  deleteFaqService,
  getFaqService,
  translateContentService,
  updateFaqService,
} from "../../services/userService";
import EditIcon from "../../assets/images/edit (5).svg";
import DeleteIcon from "../../assets/images/trash (1).svg";
import { Modal } from "react-bootstrap";
import ModalLogo from "../../assets/images/information.svg";
import CrossIcon from "../../assets/images/cross_icon.svg";
import DeleteModal from "./../../Hooks/DeleteModal";
import ReactPaginate from "react-paginate";
import SearchIcon from "../../assets/images/search (5).svg";
import ArrowDown from "../../assets/images/arrow-down.svg";
import ArrowUp from "../../assets/images/arrow-up.svg";
import { PageLoader } from "../svg";
import { langOptions } from "../helper";

const Faq = () => {

  const [list, setList] = useState("");
  const [question, setQuestion] = useState("");
  const [questionErr, setQuestionErr] = useState("");
  const [answer, setAnswer] = useState("");
  const [answerErr, setAnswerErr] = useState("");

  const [show, setShow] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [id, setId] = useState("");
  const [btnLoader, setBtnLoader] = useState(false);
  const [activeLang, setActiveLang] = useState(langOptions[0]);

  const [page, setPage] = useState(1);

  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [recordCount, setRecordCount] = useState(0);

  const limit = 10;

  const [search, setSearch] = useState("");
  const [sortKey, setSortKey] = useState("");
  const [type, setType] = useState("");
  const [loader, setLoader] = useState(true);

  localStorage.removeItem("page");

  const handlePageClick = (event) => {
    let pageNumber = event.selected + 1;

    setPage(event.selected + 1);
    if (search !== "" && search !== null) {
      getFaqList({
        limit: limit,
        page: pageNumber,
        search: search,
      });
    } else {
      getFaqList({
        limit: limit,
        page: pageNumber,
      });
    }
  };

  const getFaqList = (query) => {
    if (!query) {
      query = {
        limit: limit,
        page: page,
        sort_key: sortKey,
        type: type,
      };
    }
    setList([]);
    getFaqService(query).then((res) => {
      setLoader(false);
      let count = res.data.responseData.count;
      setRecordCount(count);
      let numberOfPages = Math.ceil(count / 10);
      setTotalCount(numberOfPages);
      setList(res.data.responseData.result);
    });
  };

  useEffect(() => {
    getFaqList();
  }, [page, sortKey, type]);

  useEffect(() => {
    let _intervalTime;
    if (search !== "" && search !== null) {
      _intervalTime = setTimeout(() => {
        getFaqList({
          limit: limit,
          page: 1,
          search: search,
        });
      }, 1500);
    } else {
      getFaqList({
        limit: limit,
        page: 1,
      });
    }
    return () => {
      clearTimeout(_intervalTime);
    };
  }, [search]);

  const handleAdd = () => {
    setShow(true);
  };

  const handleEdit = (item) => {
    setShow(true);
    setId(item._id);
    setQuestion(item.question[activeLang.value]);
    setAnswer(item.answer[activeLang.value]);
  };



  const handleDelete = (e) => {
    setDeleteModal(true);
    setId(e);
  };

  const handleDeleteClick = () => {
    deleteFaqService({ _id: id }).then((res) => {
      setDeleteModal(false);
      getFaqList();
    });
  };

  const validation = () => {
    let validate = true;
    if (question == "") {
      setQuestionErr("Question is required");
      validate = false;
    } else if (question.length < 2) {
      setQuestionErr("Question should be between 2 to 30");
      validate = false;
    }

    if (answer == "") {
      setAnswerErr("Answer is required");
      validate = false;
    } else if (answer.length < 2) {
      setAnswerErr("Answer should be between 2 to 30");
      validate = false;
    }

    return validate;
  };

  const handleSubmit = async () => {
    if (validation()) {
      setBtnLoader(true);
      let params = {};

      

    if (id) {
      let activeItemFaq = list.find(i => i._id == id);
      params._id = id; 
      params.question = JSON.stringify({
        ...activeItemFaq.question,
        [activeLang.value]: question
      })
      params.answer = JSON.stringify({
        ...activeItemFaq.answer,
        [activeLang.value]: answer
      }) 
    }else{
      try {
        let [_question, _answer] = await Promise.all([
            translateContentService({ text: question }),
            translateContentService({ text: answer }),
        ]);

        params.question = JSON.stringify(_question.data.responseData.data);
        params.answer = JSON.stringify(_answer.data.responseData.data);

      }catch (error) {
        console.error("Error occurred:", error);
    }
    }
      let _service = id ? updateFaqService : addFaqService;

      _service(params).then((res) => {
        setBtnLoader(false);
        handleClose();
        getFaqList();
      });
    }
  };



  

  const handleClose = () => {
    setShow(false);
    setDeleteModal(false);
    setQuestion("");
    setQuestionErr("");
    setAnswer("");
    setAnswerErr("");
    setId("");
  };

  const arrowClick = (sortType, sortOrder) => {
    setSortKey(sortType);
    setType(sortOrder);
  };
  return (
    <>
      <div className="page_wrapper">
          <div className="container-box">
            <div className="distributor-head">
              <div className="d-flex justify-content-between align-items-center mt-3">
                <h4 className="semibold-font mb-0">FAQs</h4>

                <div className="cm_search">
                  <img src={SearchIcon} className="search-icon" />
                  <input
                    type="text"
                    placeholder="Search by name"
                    name="search"
                    className=" search box-shadow light-italic-font"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)} />
                </div>

                <div className="d-flex justify-content-between align-items-center">
                  <div className="lang_select">
                    <Select
                        options={langOptions}
                        value={activeLang}
                        onChange={e => setActiveLang(e)} />
                  </div>
                  <button className="add-btn text-white box-shadow add-btn-position medium-font grey-font ms-5" onClick={handleAdd}>Add Faq</button>
                </div>

                
              </div>
            </div>

            <div className="table-content">
              <Table className=" box-shadow common-black-color">
                <thead>
                  <tr className="medium-font font-14px">
                    <th>ID</th>
                    <th> Questions
                      <span className="arrows pointer">
                        <img
                          src={ArrowUp}
                          alt="arrow-up"
                          height={10}
                          width={10}
                          onClick={() => arrowClick("question", 1)}
                        />

                        <img
                          src={ArrowDown}
                          alt="arrow-down "
                          height={10}
                          width={10}
                          onClick={() => arrowClick("question", -1)}
                        />
                      </span>
                    </th>
                    <th>
                      Answers
                      <span className="arrows pointer">
                        <img
                          src={ArrowUp}
                          alt="arrow-up"
                          height={10}
                          width={10}
                          onClick={() => arrowClick("answer", 1)}
                        />

                        <img
                          src={ArrowDown}
                          alt="arrow-down "
                          height={10}
                          width={10}
                          onClick={() => arrowClick("answer", -1)}
                        />
                      </span>
                    </th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {list ? list.map((item, index) => (
                      <tr>
                        <td>{recordCount <= 10 ? index + 1 : limit * pageCount + index + 1}</td>
                        <td>{item.question[activeLang.value]}</td>
                        <td>{item.answer[activeLang.value]}</td>
                        <td>
                          <img src={EditIcon} alt="edit" className="pointer" onClick={() => handleEdit(item)} />
                          <img src={DeleteIcon} alt="delete" onClick={() => handleDelete(item._id)} className="dlt-icon ms-3" />
                          {/* <span className="ul_btn d-inline-block ms-3" onClick={() => handleLangEdit(item)}>
                            <img src={EditIcon} className="dlt-icon" alt="edit" />
                            <small>Language</small>
                          </span> */}
                        </td>
                      </tr>
                    )) :
                    (
                      <tr className="text-center">
                        <td></td>
                        <td className="text-end no_data">
                          <p>No Data Found</p>
                        </td>
                      </tr>
                    )}
                  
                </tbody>
              </Table>
              {loader ? (
                <div className="loader_data">
                  <PageLoader />
                </div>
              ) : (
                ""
              )}
            </div>
            {recordCount > limit ? (
              <ReactPaginate
                previousLabel={"previous"}
                nextLabel={"next"}
                breakLabel={"..."}
                pageCount={totalCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={"pagination "}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
                hrefBuilder={(page, pageCount, selected) =>
                  setPageCount(selected)
                }
              />
            ) : null}
          </div>
      </div>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton className="display"></Modal.Header>

        <Modal.Body>
          <div className="modal-box">
            <div className="text-center">
              <img src={ModalLogo} />
              <h5 className="semibold-font mt-3">
                {id ? "Update" : "Add"} FAQ
              </h5>
            </div>
            <input
              type="text"
              placeholder="QUESTION"
              className="form-control mt-4 smaller-font"
              value={question}
              onChange={(e) => (
                setQuestion(e.target.value), setQuestionErr("")
              )}
            />
            <div className="error-message">
              {questionErr ? questionErr : ""}
            </div>
            <input
              type="text"
              placeholder="ANSWER"
              className="form-control mt-4 smaller-font"
              value={answer}
              onChange={(e) => (setAnswer(e.target.value), setAnswerErr(""))}
            />
            <div className="error-message">{answerErr ? answerErr : ""}</div>
            <button
              disabled={btnLoader}
              onClick={handleSubmit}
              className={`${
                id
                  ? "add-modal-btn mt-3  update-btn-padding text-white"
                  : "add-modal-btn mt-3  add-btn-padding text-white"
              }`}
            >{btnLoader ? <Spinner className="spiner" animation="border" /> : ''} {id ? "UPDATE" : "ADD"}{" "}
            </button>
          </div>
        </Modal.Body>
      </Modal>



      


      <DeleteModal
        deleteModal={deleteModal}
        title="Faq"
        Icon={CrossIcon}
        handleClose={handleClose}
        handleDeleteClick={handleDeleteClick}
      ></DeleteModal>
    </>
  );
};

export default Faq;
