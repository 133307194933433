
import UsersIcon from "../../assets/images/user (17).svg";
import androidIcon from "../../assets/images/android_icon.svg";
import appleIcon from "../../assets/images/apple_icon.svg";
import pageLoaderImage from "../../assets/images/bouncing-circles.svg";
import churnrateIcon from "../../assets/images/churnrate.svg";
import infoIcon from "../../assets/images/info-icon.svg";
import { getChurnRateService, getMasterDataService, getRetentionService, getGrowthRateService } from "../../services/userService";
import { useState, useEffect } from "react";
import DateRangePicker from "../common/DateRangePicker";


const currentDate = new Date();
const currentYear = currentDate.getFullYear();
const currentMonth = currentDate.getMonth();
const currentDay = currentDate.getDate();


const today = new Date();
const yesterday = new Date(today);
yesterday.setDate(today.getDate() - 1);
const formattedYesterday = yesterday.toISOString().split('T')[0];



function convertSeconds(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours}:${minutes}:${remainingSeconds}`
}

const getAdjustedDates = () => {
    const currentDate = new Date();
    const dateMinusOneDay = new Date(currentDate);
    dateMinusOneDay.setDate(currentDate.getDate() - 1);
    const dateMinusOneMonth = new Date(currentDate);
    dateMinusOneMonth.setMonth(currentDate.getMonth() - 1);
    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };
  
    return {
      dateMinusOneDay: formatDate(dateMinusOneDay),
      dateMinusOneMonth: formatDate(dateMinusOneMonth)
    };
  };


const DashboardCounts = () => {
    const [masterData, setMasterData] = useState({});
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [retentionRate, setRetentionRate] = useState('');
    const [growthRate, setGrowthRate] = useState('');
    const [startDate2, setStartDate2] = useState('');
    const [endDate2, setEndDate2] = useState('');
    const [startDate3, setStartDate3] = useState('');
    const [endDate3, setEndDate3] = useState('');
    const [pageLoader, setPageLoader] = useState(true);
    const [minLoader, setMinLoader] = useState(false);

    const getMasterData = () => {
        let query = `?startDate=${getAdjustedDates().dateMinusOneMonth}&endDate=${getAdjustedDates().dateMinusOneDay}`;
        getMasterDataService(query).then((res) => {
            if(res?.data?.statusCode){
                setMasterData(res.data.responseData.result);
            }
            setPageLoader(false)
        });
    }

    const getRetention = (a, b, c, d) => {
        let query = `?firstPeriodStartDate=${a}&firstPeriodEndDate=${b}&lastPeriodStartDate=${c}&lastPeriodEndDate=${d}`;
        getRetentionService(query).then((res) => {
          if(res?.data?.statusCode){
            setRetentionRate(res.data.responseData.data)
          }
        });
    }


    const getGrowthRate = (a, b, c, d) => {
        let query = `?firstPeriodStartDate=${a}&firstPeriodEndDate=${b}&lastPeriodStartDate=${c}&lastPeriodEndDate=${d}`;
        getGrowthRateService(query).then((res) => {
          if(res?.data?.statusCode){
            setGrowthRate(res.data.responseData.data)
          }
        });
    }

    const handleDateSubmit = () => {
        if(startDate && endDate){
            let query = `?startDate=${startDate}&endDate=${endDate}`;
            setMinLoader(true);
            getMasterDataService(query).then((res) => {
                if(res?.data?.statusCode){
                    setMasterData(res.data.responseData.result)
                }
                setMinLoader(false);
            });
        }
    }


    useEffect(() => {
        getMasterData();
    },[])

    function getEndOfMonth(year, month) {
        let date = new Date(year, month + 1, 0);
        return date.getDate();  // Get only the day of the month
    }

    useEffect(() => {
        let a = `${currentYear}-${currentMonth}-01`
        let b = `${currentYear}-${currentMonth}-${getEndOfMonth(currentYear, currentMonth)}`
        let c = `${currentYear}-${currentMonth + 1}-01`
        let d = `${currentYear}-${currentMonth + 1}-${currentDay}`
        getRetention(a, b, c, d);
    },[])


    useEffect(() => {
        let a = `${currentYear}-${currentMonth}-01`
        let b = `${currentYear}-${currentMonth}-${getEndOfMonth(currentYear, currentMonth)}`
        let c = `${currentYear}-${currentMonth + 1}-01`
        let d = `${currentYear}-${currentMonth + 1}-${currentDay}`
        getGrowthRate(a, b, c, d);
    },[])


    // const handleDateSubmit2 = () => {
    //     if(startDate2 && endDate2){
    //         let s0 = new Date(`${startDate2}-01`);
    //         let s1 = s0.getMonth();
    //         let s2 = s0.getFullYear();
    //         let e0 = new Date(`${endDate2}-01`);
    //         let e1 = e0.getMonth();
    //         let e2 = e0.getFullYear();
    //         let a = `${startDate2}-01`;
    //         let b = `${startDate2}-${getEndOfMonth(s2, s1)}`;
    //         let c = `${endDate2}-01`;
    //         let d = `${endDate2}-${getEndOfMonth(e2, e1)}`;
    //         getRetention(a, b, c, d);
    //     }
    // }

    const handleDateSubmit2 = () => {
        if (startDate2 && endDate2) {
            let s0 = new Date(`${startDate2}-01`);
            let s1 = s0.getMonth();
            let s2 = s0.getFullYear();
            let e0 = new Date(`${endDate2}-01`);
            let e1 = e0.getMonth();
            let e2 = e0.getFullYear();
            
            let a = `${startDate2}-01`;
            let b = `${startDate2}-${getEndOfMonth(s2, s1)}`;
            let c = `${endDate2}-01`;

            let d;
            if (e1 === currentMonth && e2 === currentYear) {
                let currentDay = currentDate.getDate() - 1;
                d = `${endDate2}-${currentDay < 10 ? '0' + currentDay : currentDay}`;
            } else {
                d = `${endDate2}-${getEndOfMonth(e2, e1)}`;
            }
            getRetention(a, b, c, d);
        }
    }

    if(pageLoader){
        return(
            <div className="page_loader"><img src={pageLoaderImage} /></div>
        )
    }else{
        return(
            <div className="dash_section">
                <div className="mt-4 mb-4">
                    <label>Filter by date range</label>
                    <div className="d-flex align-items-center mt-2">
                        <input placeholder="Start Date" value={startDate} onChange={e => setStartDate(e.target.value)} type="date" className="form-control w-auto me-2" />
                        <input placeholder="Start Date" value={endDate} onChange={e => setEndDate(e.target.value)} type="date" className="form-control w-auto me-2" max={formattedYesterday} />
                        {minLoader ? <span>Loading...</span> : <span className="btn btn-primary" onClick={handleDateSubmit}>Submit</span>}
                        
                        
                    </div>
                </div>
                <div className="row">
    
                    <div className="col-7">
                        <div className="row">
                        <div className="col-md-6">
                            <div className="dashboard_count2">
                                <h5 className="">{masterData?.user?.totalUsers}</h5>
                                <h6>Total Users</h6>
                                <div className="info_icon" title="Total number of sign-ins right from the inception of the app.">
                                    <img className="cm_info" src={infoIcon} />
                                </div>
                            </div>
                        </div>


                        <div className="col-md-6">
                            <div className="dashboard_count2">
                                {/* <img  src={UsersIcon} /> */}
                                <h5 className="">{masterData?.user?.dailyActiveUser}</h5>
                                <h6>Daily Active Users</h6>

                                <div
                                    className="info_icon"
                                    title={`
It refers to the number of unique users
who interact with an app on a daily basis
                                    `}>
                                    <img className="cm_info" src={infoIcon} />
                                </div>
                            </div>
                        </div>



                        <div className="col-md-6">
                            <div className="dashboard_count2">
                                <h5 className="">{masterData?.user?.newSignUps}</h5>
                                <h6>New Users Sign-ups</h6>
                                <div className="info_icon" title="New users who have signed up in the selected period.">
                                    <img className="cm_info" src={infoIcon} />
                                </div>
                            </div>
                        </div>



                        <div className="col-md-6">
                            <div className="dashboard_count2">
                                <h5 className="">{masterData?.inactiveUserFor30Days?.Android + masterData?.inactiveUserFor30Days?.ios}</h5>
                                <h6>Inactive Users</h6>
                                <div className="info_icon" title="Users who have not used the app for more than 2 months.">
                                    <img className="cm_info" src={infoIcon} />
                                </div>
                            </div>
                        </div>


                        <div className="col-md-6">
                            <div className="dashboard_count2">
                                <h5 className="">{masterData?.avgSessionFrequency}</h5>
                                <h6>Avg Session Frequency per user</h6>
                                <div className="info_icon" title={`
The Average Sessions Frequency per User metric measures the average number of times users open and engage with your app over a specific period.

Interpretation: Higher values indicate frequent user interaction, which is typically a sign of a highly engaging app.

Formula:
 For Example, if your app had 5,000 sessions during the month and 1,000 unique users, the Average Sessions per User would be:

This means, on average, each user opened and interacted with your app 5 times during that month.
                                    `}>
                                    <img className="cm_info" src={infoIcon} />
                                </div>
                            </div>
                        </div>


                        <div className="col-md-6">
                            <div className="dashboard_count2">
                                <h5 className="">{masterData?.avgSessionLength} (Minutes)</h5>
                                <h6>Avg Session length</h6>
                                <div className="info_icon" title={`
The Average Session Length metric measures the average duration of time users spend in a single session on your app.

Interpretation:
Longer session lengths typically indicate that users find your app valuable and engaging.

Formula:

Example:
if the total duration of all sessions during the month is 10,000 minutes and there were 5,000 sessions, the Average Session Length would be:

This means, on average, each session lasted 2 minutes.
                                    `}>
                                    <img className="cm_info" src={infoIcon} />
                                </div>
                            </div>
                        </div>





                        <div className="col-md-12">
                            <div className="dashboard_count2">
                                <h5 className="">{ masterData?.mostVisited?.featureWithMaxAvgSessionLength?.length ? masterData?.mostVisited?.featureWithMaxAvgSessionLength.join(', ') : 'N/A'}</h5>
                                <h6>Feature Most Spent time on</h6>
                                <div className="info_icon" title={`
Feature on which the user has spent most time on.  
Source: Highest Avg Session Length (Feature)
                                    `}>
                                    <img className="cm_info" src={infoIcon} />
                                </div>
                            </div>
                        </div>




                        <div className="col-md-12">
                            <div className="dashboard_count2">

                                <h5 className="">{ masterData?.mostVisited?.featureMostVisited?.length ? masterData?.mostVisited?.featureMostVisited.join(', ') : 'N/A'}</h5>
                                <h6>Feature Most Visited</h6>
                                <div className="info_icon" title={`
Feature which has been most visited by users. 

Source: Most frequently opened feature
                                    `}>
                                    <img className="cm_info" src={infoIcon} />
                                </div>
                            </div>
                        </div>




                       





                   
                       
    
                       
    
                        <div className="col-md-6">
                            <div className="dashboard_count2">
                           
                                <h5 className="">{masterData?.notification?.notificationAccessed}</h5>
                                <h6>Notification Accessed</h6>
                                <div
                                    className="info_icon"
                                    title={`
It refers to the number of times users have
interacted with an app's notifications
                                `}>
                                    <img className="cm_info" src={infoIcon} />
                                </div>
                            </div>
                        </div>
    
                        <div className="col-md-6">
                            <div className="dashboard_count2">
                            
                                <h5 className="">{masterData?.notification?.notificationScreenDurationSeconds ? parseInt(masterData?.notification?.notificationScreenDurationSeconds)?.toFixed(2) : '0'}</h5>
                                <h6>Notification Screen Duration <small>in Seconds</small></h6>
                                <div
                                    className="info_icon"
                                    title={`
It refers to the total amount of time, measured in seconds,
that notifications from an app are displayed on a user's screen.
                                `}>
                                    <img className="cm_info" src={infoIcon} />
                                </div>
                            </div>
                        </div>



                        <div className="col-md-6">
                            <div className="dashboard_count2">
                        
                                <h5 className="">{masterData?.churnRate ? parseInt(masterData?.churnRate)?.toFixed(2) : '0'}%</h5>
                                <h6>Churn Rate</h6>
                                <div
                                    className="info_icon"
                                    title={`
Churn Rate Formula:

Churn Rate = (Number of users at the start of the period - Number of users at the end of the period) / Number of users at the start of the period.

This formula calculates the rate at which customers stop using your product or service over a given period.
    
                                    `}>
                                    <img className="cm_info" src={infoIcon} />
                                </div>
                            </div>
                        </div>
    





                      




                        </div>
                    </div>
                    <div className="col-5">
                        <div className="screen_time">
                            <h5>Screen Time</h5>
                            <ul>
                                <li><span>Cake Design Screen:</span> <b>{convertSeconds(masterData?.screenTime?.cake_design_screen_exit)}</b></li>
                                <li><span>Cost Calculator Product Screen:</span> <b>{convertSeconds(masterData?.screenTime?.cost_calculator_product_screen_exit)}</b></li>
                                <li><span>Home Screen:</span> <b>{convertSeconds(masterData?.screenTime?.home_screen_exit)}</b></li>
                                <li><span>Product List Screen:</span> <b>{convertSeconds(masterData?.screenTime?.product_list_screen_exit)}</b></li>
                                <li><span>Recipe Detail Screen:</span> <b>{convertSeconds(masterData?.screenTime?.recipe_detail_screen_exit)}</b></li>
                            </ul>
                        </div>



                        <div className="app_downloads_card">


                        <div className="dashboard_count2">
                                <h5 className="">{masterData?.appDownloads?.androidCount + masterData?.appDownloads?.iosCount}</h5>
                                <h6>Total Downloads</h6>
                                <div className="info_icon" title="Combined downloads of the Bakers of India App on Android and iOS Devices.">
                                    <img className="cm_info" src={infoIcon} />
                                </div>
                            </div>

                            
                            <div className="dashboard_count2">
                                {/* <img  src={androidIcon} /> */}
                                <h5 className="">{masterData?.appDownloads?.androidCount}</h5>
                                <h6>Playstore App Download</h6>
                                <div
                                    className="info_icon"
                                    title={`
It refers to the number of times an application
has been downloaded from the Google Play Store.
                                `}>
                                    <img className="cm_info" src={infoIcon} />
                                </div>
                            </div>
                        </div>





                        <div className="dashboard_count2">
                                {/* <img  src={appleIcon} /> */}
                                <h5 className="">{masterData?.appDownloads?.iosCount}</h5>
                                <h6>Appstore App Download</h6>
                                <div
                                    className="info_icon"
                                    title={`
It refers to the number of times an application
has been downloaded from the App Store.
                                    `}>
                                    <img className="cm_info" src={infoIcon} />
                                </div>
                            </div>




                    </div>
    
                    
    
                </div>







                    <div className="row">

                 

                       


                        


                        


                        {/* <div className="col-md-4">
                            <div className="dashboard_count2">
                                <h5 className="">{masterData?.user?.dailyActiveUser}</h5>
                                <h6>Daily Active users (Overall)</h6>
                                <div className="info_icon" title="Average no. of Users who use the app daily. DAU= Unique returning users + New Unique Users ">
                                    <img className="cm_info" src={infoIcon} />
                                </div>
                            </div>
                        </div> */}


                      


                        


                        



                       


                        


             

                       
                    </div>
                





    
                <div className="">
                    <div className="cm_retaition_box">
                        <div className="row">
                            <div className="col-3">
                                <small>
                                Customer Retention Rate
                                <br />
                                <br />
    
                                ((CE - CN) / CS) X 100
                                <br />
                                <br />
                                CE: number of customers at the end of the period <br />
                                CN: number of new customers during the period<br />
                                CS: number of customers when the measured period began
                                </small>
                            </div>
                            <div className="col-1"></div>
                            <div className="col-8">
                                <div className="range_group">
                                    <div className="d-flex align-items-center mt-2">
                                        <div>
                                            <small className="me-2">Start Month</small>
                                            <input
                                                type="month"
                                                value={startDate2}
                                                onChange={e => setStartDate2(e.target.value)}
                                                className="form-control w-auto me-3" />
                                        </div>
                                        <div>
                                            <small className="me-2">End Month</small>
                                            <input
                                                value={endDate2}
                                                onChange={e => setEndDate2(e.target.value)}
                                                type="month"
                                                className="form-control w-auto me-5" />
                                        </div>
                                        <button onClick={handleDateSubmit2} className="btn btn-primary mt-4">Submit</button>
                                    </div>
    
    
                                  
    
                                    
    
                                    
                                </div>
    
    
                                <div className="mt-5"><h1>Retention Rate: {getGrowthRate}%</h1></div>
                            </div>
                        </div>
                    </div>
                </div>





                <div className="">
                    <div className="cm_retaition_box">
                        <div className="row">
                            <div className="col-3">
                                <small>
                                User Growth Rate tells you the percentage increase in the number of users over a specified time frame. 
                                <br />
                                Interpretation:<br />
                                A higher user growth rate indicates the increasing user base of the app.<br />

                                Formula: <br />



                                For example: <br />
                                if you had 1,000 users at the and gained 200 new users by the end of the month, your User Growth Rate would be: 20%<br />

                                This means your user base grew by 20% during that month.
                                </small>
                            </div>
                            <div className="col-1"></div>
                            <div className="col-8">
                                <div className="range_group">
                                    <div className="d-flex align-items-center mt-2">
                                        <div>
                                            <small className="me-2">Start Month</small>
                                            <input
                                                type="month"
                                                value={startDate3}
                                                onChange={e => setStartDate3(e.target.value)}
                                                className="form-control w-auto me-3" />
                                        </div>
                                        <div>
                                            <small className="me-2">End Month</small>
                                            <input
                                                value={endDate3}
                                                onChange={e => setEndDate3(e.target.value)}
                                                type="month"
                                                className="form-control w-auto me-5" />
                                        </div>
                                        <button onClick={handleDateSubmit2} className="btn btn-primary mt-4">Submit</button>
                                    </div>
    
    
                                  
    
                                    
    
                                    
                                </div>
    
    
                                <div className="mt-5"><h1>User Growth Rate: {retentionRate}%</h1></div>
                            </div>
                        </div>
                    </div>
                </div>
    
            </div>
        )
    }



}

export default DashboardCounts;