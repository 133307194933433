import { useState, useEffect } from "react";

import DashboardCounts from "./DashboardCounts";
import UserAnalytics from "./UserAnalytics";
import AppointmentAnalytics from "./AppointmentAnalytics";
import RecipeAnalytics from "./RecipeAnalytics";
import ProductAnalytics from "./ProductAnalytics";

const dashTabList = [
  {name: 'Dashboard Counts', val: 1},
  {name: 'User Analytics', val: 2},
  {name: 'Appointment Analytics', val: 3},
  {name: 'Recipe Analytics', val: 4},
  {name: 'Product Analytics', val: 5},
]



const Dashboard = () => {
  const [dashTab, setDashTab] = useState(1);
  const [currentHour, setCurrentHour] = useState("");
  const [userName, setUserName] = useState("");

  useEffect(() => {
    setUserName(localStorage.getItem("userName"));
    var today = new Date();
    var curHr = today.getHours();
    if (curHr < 12) {
      setCurrentHour("Good Morning!");
    } else if (curHr < 18) {
      setCurrentHour("Good Afternoon!");
    } else {
      setCurrentHour("Good Evening!");
    }
  }, []);

  return (
    <div className="page_wrapper">
      <div className="container-box">
        <div className="welcome">
          <h6 className="grey-text">{new Date().toLocaleDateString("en-us", {weekday: "long", year: "numeric", month: "short", day: "numeric"})}</h6>
          <h5>Hello {userName}, {currentHour}</h5>
        </div>

        <div className="dash_tabs">
          {dashTabList.map(item => <span onClick={() => setDashTab(item.val)} key={item.val} className={item.val == dashTab ? 'active' : ''}>{item.name}</span>)}
        </div>

        {dashTab == 1 ? <DashboardCounts  /> : ''}
        {dashTab == 2 ? <UserAnalytics /> : ''}
        {dashTab == 3 ? <AppointmentAnalytics /> : ''}
        {dashTab == 4 ? <RecipeAnalytics /> : ''}
        {dashTab == 5 ? <ProductAnalytics /> : ''}

      </div>
    </div>
  );
};
export default Dashboard;

