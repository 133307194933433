import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { createPortal } from "react-dom";
import DeleteModal from "../common/DeleteModal";
import { getRecipeService } from "../../services/userService";
import Recipe from "./Recipe";
import { deleteRecipeService } from "../../services/userService";
import ReactPaginate from "react-paginate";
import SearchIcon from "../../assets/images/search (5).svg";
import { PageLoader } from "../svg";
import Notification from "../common/Notification";

const Recipes = () => {
  const [notificationStatus, setNotificationStatus] = useState(false);
    const [notificationProps, setNotificationProps] = useState({
        message: "",
        type: "info",
    });
  const [data, setData] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [id, setId] = useState("");
  const [totalCount, setTotalCount] = useState(1);
  const [dataLimit, setDataLimit] = useState(10);
  const [pageNum, setPageNum] = useState(parseInt(localStorage.getItem('page')) || 1);

  
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  const navigate = useNavigate();
  const getRecipesList = async (params) => {
    setLoading(true);
    try {
      setData([]);
      let res = await getRecipeService(params);
      if (res?.data?.statusCode) {
        setLoading(false);
        let totalCount = 1;
        let length = res?.data?.responseData?.count;
        totalCount = Math.ceil(length / dataLimit);
        setTotalCount(totalCount);
        let _data = res?.data?.responseData?.result;
        setData([..._data]);
      } else {
        setLoading(false);
      }
    } catch (error) {
    }
  };

  useEffect(() => {

    let _intervalTime;
    let customPage = localStorage.getItem("page");
    if (search !== "" && search !== null) {
      _intervalTime = setTimeout(() => {
        
        getRecipesList({ limit: dataLimit, page: 1, name: search });
      }, 2500);
    } else {

      getRecipesList({ limit: dataLimit, page: 1 });
    }
    return () => {
      clearTimeout(_intervalTime);
    };
    // eslint-disable-next-line
  }, [search]);


  const handleDelete = (e) => {
    setShowDelete(true);
    setId(e);
  };
  const handleClose = () => {
    setShowDelete(false);
  };
  const deleteRecipe = () => {
    deleteRecipeService({ _id: id }).then((res) => {
      setNotificationProps({
        message: "Recipe successfully removed.",
        type: "success",
      });
      setNotificationStatus(true);
      handleClose();
      getRecipesList({ limit: dataLimit, page: pageNum });
    });
  };

  const updateProductHandler = (data) => {
    navigate("/updateRecipe", { state: data });
  };
  const viewRecipeHandler = (data) => {
    // localStorage.setItem("page",pageNum)
    navigate("/viewRecipe", { state: data });
  };

  const handlePageClick = (event) => {
    let customPage = event?.selected + 1;
    let pageNumber = event?.selected + 1;
    localStorage.setItem("page",customPage)
    localStorage.setItem("limit",dataLimit)


    setPageNum(customPage);
    if (search !== "" && search !== null) {
      getRecipesList({ limit: dataLimit, page: customPage, name: search });
    }else{
      getRecipesList({ limit: dataLimit, page: customPage });
    }
  };



  
  return (
    <>
      <div className="page_wrapper">
              <div className="container-box">
                  <div className="distributor-head">
                    <div className="top-head mt-3 mb-2">
                        <div className="search_part d-flex align-items-center justify-content-between w-100">
                          <h4 className="semibold-font mb-0 ms-2">RECIPES</h4>
                          <div className="cm_saerch">
                            <img src={SearchIcon} className="search-icon" alt={"search-icon"}/>
                            <input
                              type="text"
                              placeholder="Search by name"
                              name="search"
                              className="search box-shadow"
                              value={search}
                              onChange={(e) => setSearch(e.target.value)} />
                          </div>

                          <div className="btn_part">
                            <Link to={`/recipes/create`} className="add-btn text-white box-shadow  medium-font grey-font text-decoration-none">Add Recipe</Link>
                          </div>
                        </div>
                        
                    </div>
                  </div>
                  {data?.length > 0?
                  <div className="grid-container">
                   {data?.map((item, index) => (
                          <Recipe
                            kye={index}
                            item={item}
                            viewRecipeHandler={viewRecipeHandler}
                            updateProductHandler={updateProductHandler}
                            handleDelete={handleDelete}
                          />
                        ))}
                  </div>:!loading?<div className="row"><div className="col-12 not-found-wrapper"><p className="text-center">Data not found.</p></div></div>:null }
                  {data?.length === 0 && loading ? (
                    <div className="text-center">
                      <div className="loader_data"> <PageLoader /></div>
                    </div>
                  ) : null}
              

              <div className="mt-4">
                {totalCount>dataLimit?<ReactPaginate
                  onPageActive={parseInt(pageNum) - 1}
                  initialPage={parseInt(pageNum) - 1}
                  previousLabel={"previous"}
                  nextLabel={"next"}
                  breakLabel={"..."}
                  pageCount={totalCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination justify-content-center"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />:null}
              </div>

              </div>
      </div>

       {showDelete &&
          createPortal(
            <DeleteModal 
            delete={deleteRecipe}
            close={handleClose}
            status={showDelete}
            message={'Are you sure you want to delete this Recipe from the list?'}
            />,
            document.querySelector("#notification-root")
          )}

{notificationStatus && createPortal(<Notification {...notificationProps} />, document.querySelector("#notification-root"))}

    </>
  );
};
export default Recipes;
