import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

import './assets/style/style.css';
import Index from "./components/login";
import Dashboard from "./components/dashboard";
import Distributors from "./components/distributors";
import Bakers from "./components/bakers";
import Products from "./components/products";
import Recipes from "./components/recipes";
import Feedbacks from './components/feedbacks';
import ASM from './components/asm';
import SO from './components/so';
import PillsburryChefs from './components/pillsBuryChefs';
import AddChefs from './components/pillsBuryChefs/addChefs';
import ViewRecipe from './components/recipes/RecipeDetail';
import ProductCategory from './components/ProductCategory';
import AddCategory from './components/ProductCategory/addcategory';
import Notification from './components/notification';
import Users from './components/users';
import PrivateRoute from "./routes/PrivateRoutes";
import PublicRoute from './routes/PublicRoutes';
import NotFound from './components/error/NotFound';
import Faq from './components/faq';
import City from './components/city';
import ProductDetail from './components/products/ProductDetail';
import ProductForm from './components/products/ProductForm';
import EditProduct from './components/products/EditProduct';
import RecipeForm from './components/recipes/RecipeForm';
import EditRecipeLanguge from './components/recipes/EditRecipeLanguge';
import DesignCategory from './components/DesignCategory';
import DesignLibrary from './components/design-library/DesignLibrary';
import CreateDesignCategory from './components/DesignCategory/CreateDesignCategory';
import DesignCategoryLangEdit from './components/DesignCategory/DesignCategoryLangEdit';
import DesignLibraryForm from './components/design-library/DesignLibraryForm';
import DesignLibraryFormLang from './components/design-library/DesignLibraryFormLang';
import ImageGen from './components/image-gen/ImageGen';
import { Fragment } from "react";
import SideBar from "./components/sidebar";
import Header from "./components/header";
import MarkingToolkitDesigns from "./components/markingToolkitDesigns/MarkingToolkitDesigns";
import MarkingToolkitDesignCategory from "./components/markingToolkitDesignCategory";
import CreateMarkingToolkitDesignCategory from "./components/markingToolkitDesignCategory/CreateMarkingToolkitDesignCategory";
import CreateMarkingToolkitDesignCategoryLangEdit from "./components/markingToolkitDesignCategory/CreateMarkingToolkitDesignCategoryLangEdit";
import MarkingToolkitDesignForm from "./components/markingToolkitDesigns/MarkingToolkitDesignForm";
import MarkingToolkitDesignFormLang from "./components/markingToolkitDesigns/MarkingToolkitDesignFormLang";
import ConfirmEmailForApp from "./components/for-app/ConfirmEmailForApp";
import SoReport from "./components/so/SoReport";
import DesignApproval from "./components/design-approval/DesignApproval";
import DesignApprovalForm from "./components/design-approval/DesignApprovalForm";
import DesignApprovalFormLang from "./components/design-approval/DesignApprovalFormLang";

function App() {

  const token = localStorage.getItem("token");

  return (
    <BrowserRouter>
      {token ? <Fragment><Header /><SideBar /></Fragment> : ''}
      <Routes>
        <Route exact path="/" element={<PublicRoute Component ={Index} />} ></Route>
        <Route exact path="/delete-my-account-request" element={<PublicRoute Component ={ConfirmEmailForApp} />} ></Route>
        <Route path="/image-gen/:userId/:toolkitDesignId/:token/:lang" element={<PublicRoute Component ={ImageGen} />} ></Route>
        <Route path="*" element={<NotFound/>} ></Route>
        <Route path="dashboard" element={<PrivateRoute Component = {Dashboard}/>}  > </Route>
        <Route path="distributor" element={<PrivateRoute Component = {Distributors} />} > </Route>
        <Route path="design-category" element={<PrivateRoute Component = {DesignCategory} />} > </Route>
        <Route path="design-category/create" element={<PrivateRoute Component = {CreateDesignCategory} />} > </Route>
        <Route path="design-category/edit/:id" element={<PrivateRoute Component = {CreateDesignCategory} />} > </Route>
        <Route path="design-category/lang-edit/:id" element={<PrivateRoute Component = {DesignCategoryLangEdit} />} > </Route>
        <Route path="design-library" element={<PrivateRoute Component = {DesignLibrary} />} > </Route>
        <Route path="design-library/create" element={<PrivateRoute Component = {DesignLibraryForm} />} > </Route>
        <Route path="design-library/edit/:id" element={<PrivateRoute Component = {DesignLibraryForm} />} > </Route>
        <Route path="design-library/lang-edit/:id" element={<PrivateRoute Component = {DesignLibraryFormLang} />} > </Route>

        <Route path="design-approval" element={<PrivateRoute Component = {DesignApproval} />} > </Route>
        <Route path="design-approval/create" element={<PrivateRoute Component = {DesignApprovalForm} />} > </Route>
        <Route path="design-approval/edit/:id" element={<PrivateRoute Component = {DesignApprovalForm} />} > </Route>
        <Route path="design-approval/lang-edit/:id" element={<PrivateRoute Component = {DesignApprovalFormLang} />} > </Route>


        <Route path="baker" element={<PrivateRoute Component ={Bakers} />} > </Route>
        <Route path="products" element={<PrivateRoute Component ={Products} />}>  </Route>
        <Route path="products/create" element={<PrivateRoute Component ={ProductForm} />}>  </Route>
        <Route path="products/detail/:id" element={<PrivateRoute Component ={ProductDetail} />}>  </Route>
        <Route path="products/edit/:id" element={<PrivateRoute Component ={ProductForm} />}>  </Route>
        <Route path="products/edit-language/:id" element={<PrivateRoute Component ={EditProduct} />}>  </Route>
        <Route path="product-categories" element={<PrivateRoute Component ={ProductCategory} />} > </Route>
        <Route path="product-categories/create" element={<PrivateRoute Component ={AddCategory} />} > </Route>
        <Route path="product-categories/edit/:id" element={<PrivateRoute Component ={AddCategory} />} > </Route>
        <Route path="recipes" element={<PrivateRoute Component ={Recipes} />} > </Route>
        <Route path="recipes/create" element={<PrivateRoute Component ={RecipeForm} />} > </Route>
        <Route path="recipes/edit/:id" element={<PrivateRoute Component ={RecipeForm} />} > </Route>
        <Route path="recipes/detail/:id" element={<PrivateRoute Component ={ViewRecipe} />} > </Route>
        <Route path="recipes/edit-language/:id" element={<PrivateRoute Component ={EditRecipeLanguge} />} > </Route>
        <Route path="feedback" element={<PrivateRoute Component ={Feedbacks} />} > </Route>
        <Route path="asm" element={<PrivateRoute Component ={ASM} />} > </Route>
        <Route path="city" element={<PrivateRoute Component ={City} />} > </Route>
        <Route path="so" element={<PrivateRoute Component ={SO} />} > </Route>
        <Route path="so-report" element={<PrivateRoute Component ={SoReport} />} > </Route>
        <Route path="pillsbury-chef" element={<PrivateRoute Component ={PillsburryChefs} />} > </Route>
        <Route path="addchef" element={<PrivateRoute Component ={AddChefs} />} > </Route>
        <Route path="notification" element={<PrivateRoute Component ={Notification} />} > </Route>
        <Route path="user" element={<PrivateRoute Component ={Users} />} > </Route>
        <Route path="faq" element={<PrivateRoute Component ={Faq} />} > </Route>
        <Route path="marking-toolkit-designs" element={<PrivateRoute Component ={MarkingToolkitDesigns} />} > </Route>
        <Route path="marking-toolkit-designs/create" element={<PrivateRoute Component = {MarkingToolkitDesignForm} />} > </Route>
        <Route path="marking-toolkit-designs/edit/:id" element={<PrivateRoute Component = {MarkingToolkitDesignForm} />} > </Route>
        <Route path="marking-toolkit-designs/lang-edit/:id" element={<PrivateRoute Component = {MarkingToolkitDesignFormLang} />} > </Route>
        <Route path="marking-toolkit-design-category" element={<PrivateRoute Component ={MarkingToolkitDesignCategory} />} > </Route>
        <Route path="marking-toolkit-design-category/create" element={<PrivateRoute Component = {CreateMarkingToolkitDesignCategory} />} > </Route>
        <Route path="marking-toolkit-design-category/edit/:id" element={<PrivateRoute Component = {CreateMarkingToolkitDesignCategory} />} > </Route>
        <Route path="marking-toolkit-design-category/lang-edit/:id" element={<PrivateRoute Component = {CreateMarkingToolkitDesignCategoryLangEdit} />} > </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;



