import {useState} from "react";
import PillsBuryLogo from "../../assets/images/pillsbury_logo.png";
import ArrowDown from "../../assets/images/arrow-down.svg";
import ArrowDown2 from "../../assets/images/arrow-down2.svg";
import {useNavigate} from "react-router-dom";

const Header = () =>{
    const[showArrow,setShowArrow] = useState(false);
    let userName = localStorage.getItem("userName");
    const navigate = useNavigate('');
    const handleArrow = () =>{
        setShowArrow(!showArrow)

    }
    const handleLogout = () =>{
        localStorage.clear()
        navigate("/")


    }
    return(
        <>
            <div className="main-display">

            <div className="pb-logo">
                    <img src={PillsBuryLogo} alt="logo"/>
                </div>

                <div className="header-container">
                    <div className="name-box flex">
                        <h6 className="smaller-padding">{userName}</h6>
                        {showArrow?
                        <img src = {ArrowDown2} alt="arrow-down" height={10} width={10} className="m-2" onClick={handleArrow}/>:<img src = {ArrowDown} alt="arrow-down" height={10} width={10} className="m-2" onClick={handleArrow}/>}

                    </div>
                    {showArrow?<div className="logout-box "><button className="simple-btn" onClick={handleLogout}>Logout</button></div>:""}



                </div>

            </div>



        </>
    )
}
export default Header;