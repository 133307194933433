


import { useEffect, useState } from "react";
import Select from "react-select";
import {useParams, useNavigate, Link } from "react-router-dom";
import ReactQuill from "react-quill";
import { createPortal } from "react-dom";
import EditorToolbar, { modules, formats } from "../common/EditorToolbar";
import "react-quill/dist/quill.snow.css";

import { getRecipeService, updateRecipeService } from "../../services/userService";
import Notification from "../common/Notification";
import { Spinner } from "react-bootstrap";
import { langOptions } from "../helper";



const EditRecipeLanguge = () => {
    let { id } = useParams();
    const navigate = useNavigate();
    const [activeLang, setActiveLang] = useState(langOptions[0]);
    const [recipeData, setRecipeData] = useState({});
    const [notificationStatus, setNotificationStatus] = useState(false);
    const [notificationProps, setNotificationProps] = useState({
        message: "",
        type: "info",
    });


    const [name, setName] = useState('');
    const [preparation_method, setPreparation_method] = useState('');
    const [ingredients, setIngredients] = useState([]);
   
    const [nameErr, setNameErr] = useState('');
    const [preparation_methodErr, setPreparation_methodErr] = useState('');

    const [btnLoader, setBtnLoader] = useState(false);


    const handleHeadingChange = (e, index) => {
        let newIngredients = [...ingredients];
        newIngredients[index].heading = e.target.value
        setIngredients(newIngredients);
    }



    const handleDescChange = (e, index, subIndex, type) => {
        let newIngredients = [...ingredients];
        let newSubIngredients = [...newIngredients[index].list];
        if(type == 'desc'){
            newSubIngredients[subIndex].desc = e.target.value;
        }
        if(type == 'value'){
            newSubIngredients[subIndex].value = e.target.value;
        }
        setIngredients(newIngredients);
    }





    const getRecipeDetailById = () => {
        let params = {_id: id}
        getRecipeService(params).then(res => {
            if(res.data.statusCode){
                setRecipeData(res.data.responseData.result[0] || {});
            }
        });
    }

    useEffect(() => {
        if(id){
            getRecipeDetailById(); 
        }
    }, [id])


    useEffect(() => {
        if(recipeData?.name){
            setName(recipeData.translated_name[activeLang.value]);
                let updated_ingredients = recipeData?.ingredients?.map(item => ({
                    heading: item.heading[activeLang.value],
                    list: item.list.map(jj => ({value: jj.value, desc: jj.desc[activeLang.value]}))
                }))

                setIngredients(updated_ingredients);
                setPreparation_method(recipeData.preparation_method[activeLang.value]);
        }

    }, [activeLang, recipeData])







    const handleValidate = () => {
        let validate = true;

       
        if (!name || name.trim() === '') {
            setNameErr('Name is required');
            setNotificationStatus(true);
            setNotificationProps({type: "error", message: 'Name is required'});
            validate = false;
        }

        if (!preparation_method) {
            setPreparation_methodErr('Preparation is required');
            setNotificationStatus(true);
            setNotificationProps({type: "error", message: 'Preparation is required'});
            validate = false;
        }
    

    
        return validate;
    }


    const handleSubmit = async e => {
        e.preventDefault();

        if(handleValidate()){
            setBtnLoader(true);

            let _name = {
                ...recipeData.translated_name,
                [activeLang.value]: name
            };

            let _preparation_method = {
                ...recipeData.preparation_method,
                [activeLang.value]: preparation_method
            };


            let res_ingredients = ingredients.map((jj, i) => ({
                heading: {
                    ...recipeData.ingredients[i].heading,
                    [activeLang.value]: jj.heading,
                },
                list: jj.list.map((kk, j) => ({
                    value: recipeData.ingredients[i].list[j].value,
                    desc: {
                        ...recipeData.ingredients[i].list[j].desc,
                        [activeLang.value]: kk.desc,
                    }
                }))
            }))


            const formData = new FormData();
            formData.append('_id', id);
            formData.append('translated_name', JSON.stringify(_name));
            formData.append('preparation_method', JSON.stringify(_preparation_method));
            formData.append('ingredients', JSON.stringify(res_ingredients));



            updateRecipeService(formData).then(res => {
                if (res?.data?.statusCode) {
                    setNotificationProps({
                      message: res?.data?.responseData?.message,
                      type: "success",
                    });
                    setBtnLoader(false);
                    navigate("/recipes");
                    setNotificationStatus(true);
                    setBtnLoader(false);
                  } else {
                    setNotificationProps({
                      message: "Please fill all the details correctly.",
                      type: "error",
                    });
                    setNotificationStatus(true);
                    setBtnLoader(false);
                  }
              })

       
        }
    }
 

  return (
      <div className="page_wrapper">
          <div className="container-box">
            <div className="mt-5">
                
                    <form onSubmit={handleSubmit}>
                        <div className='page_box'>
                        <div className="d-flex justify-content-between align-items-center py-5">
                            <h5 className="pt-3">Update Recipe Language</h5>
                            <div className="lang_select">
                                <Select
                                    options={langOptions}
                                    value={activeLang}
                                    onChange={e => setActiveLang(e)} />
                            </div>
                        </div>
                            <div className='row pb-5'>
                                <div className='col-lg-5'>
                                    <div className="form-group">
                                        <label>Recipe name<b className="cm_hash">*</b></label>
                                        <input
                                            className="form-control"
                                            value={name}
                                            onChange={e => (setName(e.target.value), setNameErr(''))}
                                            type="text" />
                                        
                                        {nameErr ? <span className="error-message">{nameErr}</span> : ''}
                                    </div>

                                </div>
                            </div>

                           <div className="row"><div className="col-8"><hr /></div></div>

                           <div className="row">
                                <div className="col-8">
                                    <div className="c_ingredients_list">
                                        <h3>Ingredients</h3>

                                        {
                                            ingredients.map((item, index) => (
                                                <div key={index} className="c_ingredient">
                                                    <div className="d-flex justify-content-between">
                                                        <div className="form-group w-75">
                                                            <input
                                                                value={item.heading}
                                                                onChange={e => handleHeadingChange(e, index)}
                                                                placeholder="Enter Heading"
                                                                type="text"
                                                                className="form-control" />
                                                        </div>

                                                       
                                                    </div>

                                                    {
                                                        item.list.map((subItem, subIndex) => (
                                                            <div className="d-flex mb-3" key={subIndex}>
                                                                <div className="w-50 ms-5">
                                                                    <input
                                                                        type="text"
                                                                        value={subItem.desc}
                                                                        onChange={e => handleDescChange(e, index, subIndex, 'desc')}
                                                                        placeholder="Enter Ingredient"
                                                                        className="form-control" />
                                                                </div>
                                                                <div className="d-flex ms-3">
                                                                    <input
                                                                        type="text"
                                                                        value={subItem.value}
                                                                        onChange={e => handleDescChange(e, index, subIndex, 'value')}
                                                                        placeholder="Weight with unit"
                                                                        className="form-control" />
                                                                
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                  
                                                </div>
                                            ))
                                        }

                                      
                                    </div>
                                </div>
                           </div>



                           <div className="row">
                            <div className="col-8"><hr /></div>
                           </div>

                            <div className='row pb-5'>
                                <div className='col-lg-8'>
                                    <label>Preparation</label>
                                    <div className="text-editor">
                                        <EditorToolbar />
                                        <ReactQuill
                                            theme="snow"
                                            value={preparation_method}
                                            onChange={e => (setPreparation_method(e), setPreparation_methodErr(''))}
                                            placeholder={"Write something..."}
                                            modules={modules}
                                            formats={formats}
                                        />

                                    {preparation_methodErr ? <span className="error-message">{preparation_methodErr}</span> : ''}
                                    </div>


                                    <div className=" d-flex justify-content-end mt-5">
                                        <Link to="/recipes" className="btn btn-dark me-4 px-5">Cancel</Link>
                                        <button disabled={btnLoader} type="submit" className="btn btn-primary px-5">{btnLoader ? <Spinner className="spiner" animation="border" /> : ''} Save</button>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </form>

            </div>
            </div>

            {notificationStatus && createPortal(<Notification {...notificationProps} />, document.querySelector("#notification-root"))}
      </div>
  );
};

export default EditRecipeLanguge;
