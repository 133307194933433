export const DROP_DOWN_DATA = {
    seasonOptions :[
        { label: "Autumn", value: "1" },
        { label: "Monsoon", value: "2" },
        { label: "Spring", value: "3" },
        { label: "Summer", value: "4" },
        { label: "Winter", value: "5" },
    ],

    dishesOptions :[
        { label: "Bread", value: "1" },
        { label: "Brownie", value: "2" },
        { label: "Celebration Cake", value: "3" },
        { label: "Cookie", value: "4" },
        { label: "Donut", value: "5" },
        { label: "Tea Time Cake", value: "6" },
        { label: "Waffle", value: "7" },

    ],

    productOptions :[
        { label: "BreadBread Concentrates", value: "1" },
        { label: "Cake Premix", value: "2" },
        { label: "Dessert Mix", value: "3" },
        { label: "Donut Mix", value: "4" },
        { label: "Sponge Mix", value: "5" },
        { label: "Tea Time Mix", value: "6" },
        { label: "Waffle Mix", value: "7" },
        { label: "Whipped Topping", value: "8" },


    ],

    ingredientOptions :[
        { label: "Eggless", value: "1" },
        { label: "Fruit", value: "2" },
        { label: "Nuts", value: "3" },

    ],
}

 