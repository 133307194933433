import { useEffect, useState } from "react";
import Select from "react-select";
import pageLoaderImage from "../../assets/images/bouncing-circles.svg";
import { Bar } from "react-chartjs-2";

import Chart from 'chart.js/auto';
import { getDashboardCountService, getAppointmentDataService } from "../../services/userService";
import { monthsList } from "../helper";


const cy = new Date().getFullYear();
const yearsArray = [];
for (let i = 0; i <= 10; i++) {
  yearsArray.push(cy - i);
}


const options = {
    responsive: true,
    plugins: {
      title: {
        display: false,
        position: "bottom",
        text: "Year - 2022",
      },
      legend: {
        display: false,
      },
    },
  };

const currentMonth = (new Date().getMonth() + 1);

const AppointmentAnalytics = () => {
    const [list, setList] = useState([]);
    const [appointmentData, setAppointmentData] = useState({});
    const [status, setStatus] = useState({label: 'Online', value: 'ONLINE'});
    const [selectedMonth, setSelectedMonth] = useState(currentMonth);
    const [slotData2, setSlotData2] = useState("");
    const [selectedYear, setSelectedYear] = useState({value: yearsArray[0], label: yearsArray[0]});
    const [pageLoader, setPageLoader] = useState(true);
    const [totalCountData, setTotalCountData] = useState(0);


    const getAppointmentList = () => {
        let query = {status: status.value};
    
        getDashboardCountService(query).then((res) => {
            if(res.data.statusCode){
                setList(res.data.responseData.result.slot_list);
            }
            setPageLoader(false)
        });
    };


    useEffect(() => {
        getAppointmentList()
    }, [status])



    const getAppointmentData = () => {
        let params = {
            year: selectedYear.value
        }

        getAppointmentDataService(params).then(res => {
            if(res.data.statusCode){
                  const valuesArray = Object.values(res.data.responseData.result);
                  setAppointmentData(valuesArray);
                  setTimeout(() => {
                    getSingleMonthData(valuesArray);
                  }, 100)
            }
            setPageLoader(false)
        })
    }


    useEffect(() => {
        if(selectedYear){
            getAppointmentData()
        }

    }, [selectedYear])




    
      const getSingleMonthData = (array) => {
        if(array?.length){
          let _singleData = array[selectedMonth - 1];
          const _totalCountData = ((_singleData.completed_offline || 0) + (_singleData.completed_online || 0));
          setTotalCountData(_totalCountData)
          let label = Object.keys(_singleData);
            label.pop();
            let value = Object.values(_singleData);
            value.pop();
            const labels = [
              "Requested By Bakery",
              "Accepted By Chef",
              "Rescheduled",
              "Rejected",
              "Completed Online",
              "Completed Offline",
              "Completed",
              "Whatsapp Call Initiated ByBaker",
            ];
            let data = {
              labels: labels,
    
              datasets: [
                {
                  data: value,
                  backgroundColor: [
                    "#FFBB00",
                    "#7F00A6",
                    "#00A606",
                    "#EF5D5D",
                    "#61AFDC",
                    "#9FC545",
                    "#FFB6C1",
                    "#A52A2A",
                  ],
                  borderRadius: 4,
                },
              ],
            };
            setSlotData2(data);
        }
      }
    
      useEffect(() => {
        getSingleMonthData(appointmentData);
      }, [selectedMonth])



      if(pageLoader){
        return(
            <div className="page_loader"><img src={pageLoaderImage} /></div>
        )
    }else{
    return(
        <div className="dash_section mt-5">
            <div className="">
                <div className="d-flex align-items-center">
                    <h5 className="mb-0 me-2">Appointments</h5>
                    <Select
                        value={status}
                        onChange={e => setStatus(e)}
                        options={[
                            {label: 'Online', value: 'ONLINE'},
                            {label: 'Offline', value: 'OFFLINE'},
                        ]} />
                </div>

                <table className="table table-responsive">
                    <thead>
                        <tr className="medium-font font-14px">
                            <th>ID</th>
                            <th>Chef Name</th>

                            <th>Requested </th>
                            <th>Accepted </th>
                            <th>Completed</th>
                            <th>Rescheduled</th>
                            <th>Rejected</th>
                            <th>Whatsapp Call Initiated </th>
                        </tr>
                    </thead>
                    <tbody>
                    {   
                        list.map((item, index) => (
                            <tr key={item._id}>
                                <td>{index + 1}</td>
                                <td>{item.name}</td>
                                <td>{item.requested_by_bakery}</td>
                                <td>{item.accepted_by_Chef}</td>
                                <td>{item.completed}</td>
                                <td>{item.rescheduled}</td>
                                <td>{item.rejected}</td>
                                <td>{item.whatsapp_call_initiated_by_baker}</td>
                            </tr>
                        ))
                    }

                    {list.length ? '' : <tr><td colSpan={8} className="no_table">No Data</td></tr>}
                    </tbody>
                </table>
            </div>



            <div className=" chart-box col-md-12">
                <div className="chart-container">
                <div className="d-flex align-items-center justify-content-between">
                    <h5 className=" ">Analytics of Appointment</h5>
                    <Select
                        value={selectedYear}
                        placeholder="Select by year"
                        onChange={e => setSelectedYear(e)}
                        options={yearsArray.map(i => ({label: i, value: i}))} />
                </div>
                <hr></hr>
                <div className="months_list">
                    {monthsList.map(item => (
                        <span
                            key={item.id}
                            className={`${(item.id == selectedMonth) ? 'active' : ''}`}
                            onClick={() => setSelectedMonth(item.id)}>
                                {item.name}

                                {/* <b>{totalCountData}</b> */}
                        </span>
                    ))}
                </div>

                <p className="mb-0 mt-3">Completed: {totalCountData}</p>
                
                <div className="bar ">
                    {slotData2 && (
                    <Bar data={slotData2} options={options} />
                    )}
                </div>
                </div>
            </div>

        </div>
    )
}
}

export default AppointmentAnalytics;