import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Select from "react-select";

import {
    updateToolkitDesignCategoryService,
    getToolkitDesignCategoryDetailService,
} from "../../services/userService";
import { Spinner } from "react-bootstrap";
import { langOptions } from "../helper";


const CreateMarkingToolkitDesignCategoryLangEdit = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [category_name, setCategory_name] = useState('');
    const [translated_name, setTranslated_name] = useState({});
    const [activeLang, setActiveLang] = useState(langOptions[0]);
    const [category_nameErr, setCategory_nameErr] = useState('');
    const [btnLoader, setBtnLoader] = useState(false);
    const [subCats, setSubCats] = useState([]);


    const handleValidate = () => {
        let validate = true;
    
        if (!category_name) {
            setCategory_nameErr('Name is required');
            validate = false;
        }

        if(subCats.length){
            let _temp = [...subCats].map(item => ({...item, subcategory_nameErr: !item.subcategory_name2 ? 'Name is required' : ''}));
            let errRes = _temp.filter(item => item.subcategory_nameErr);
            if(errRes.length){
                setSubCats(_temp);
                validate = false;
            }
        }
    
        return validate;
    }

    const handleSubmit = async () => {
        if (handleValidate()) {
            try {
                setBtnLoader(true);

                let trans_sub_cats = [...subCats].map(item => ({
                    category_id: item.category_id,
                    createdAt: item.createdAt,
                    status: item.status,
                    subcategory_name: item.subcategory_name,
                    translated_name: JSON.stringify({...item.translated_name, [activeLang.value]: item.subcategory_name2}),
                    updatedAt: item.updatedAt,
                    _id: item._id,
                }));

                const params = {
                    _id: id,
                    translated_name: JSON.stringify({...translated_name, [activeLang.value]: category_name}),
                    subCat: JSON.stringify(trans_sub_cats)
                };


                const _res = await updateToolkitDesignCategoryService(params);
                setBtnLoader(false);
                if (_res.data.statusCode) {
                    navigate("/design-category");
                }
            } catch (error) {
                console.error("Error:", error);
            }
        }
    };


    const categoryDetails = () => {
        let query = `/?category_id=${id}`
        getToolkitDesignCategoryDetailService(query).then((res) => {
            let _res = res.data.responseData.data;
            setCategory_name(_res.category_name);
            setTranslated_name(_res.translated_name);
            if(_res?.sub_category?.length){
                let _temp = _res?.sub_category.map(j => ({...j, subcategory_name2: j.translated_name[activeLang.value]}))
                setSubCats(_temp);
            }   
        })
    }

    useEffect(() => {
        if(id){
            categoryDetails()
        }
    }, [id])

    const handleSubCatChange = (e, index) => {
        let temp = [...subCats];
        temp[index].subcategory_name2 = e.target.value;
        temp[index].subcategory_nameErr = '';
        setSubCats(temp)
    }


    useEffect(() => {
        setCategory_name(translated_name[activeLang.value]);
        if(subCats?.length){
            let _temp = [...subCats].map(j => ({...j, subcategory_name2: j.translated_name[activeLang.value]}))
            setSubCats(_temp);
        } 
    }, [activeLang])

    return (
        <div className="page_wrapper">
            <div className="container-box">

                <div className="d-flex justify-content-between align-items-center">
                    <h4 className="semibold-font mt-5">UPDATE DESIGN CATEGORY LANGUAGE</h4>
                    <div className="lang_select">
                        <Select
                            options={langOptions}
                            value={activeLang}
                            onChange={e => setActiveLang(e)} />
                    </div>
                </div>

                <div className="tab-box">
                    <div className="add-product-box">
                        <div className="row">
                            <div className="col-md-9">

                                <div className="form-group">
                                    <label className="mb-2">Category Name</label>
                                    <input
                                        type="text"
                                        placeholder="CATEGORY NAME"
                                        className="form-control product-box mb-0" 
                                        value={category_name}
                                        onChange={(e) => (setCategory_name(e.target.value), setCategory_nameErr(''))} />
                                        
                                        {category_nameErr ? <div className="error-message">{category_nameErr}</div> : ''}
                                </div>

                                <h5>Sub Category</h5>

                                <ul className="d_s_c_ul">
                                    {subCats.map((item, index) => (
                                        <li key={index}>
                                            <div className="d-flex align-items-center">
                                                <input
                                                    onChange={(e) => handleSubCatChange(e, index)}
                                                    className="form-control product-box mb-0 me-3"
                                                    type="text"
                                                    value={item.subcategory_name2} />
                                            </div>
                                            {item.subcategory_nameErr ? <div className="error-message">{item.subcategory_nameErr}</div> : ''}
                                        </li>
                                    ))}
                                </ul>

                                <div className="mt-5">
                                    <Link className="btn btn-dark me-4 px-5" to="/design-category">CANCEL</Link>
                                    <span className="btn btn-primary me-4 px-5" onClick={btnLoader ? null : handleSubmit}>{btnLoader ? <Spinner className="spiner" animation="border" /> : ''} Save</span>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
export default CreateMarkingToolkitDesignCategoryLangEdit;