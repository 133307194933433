import { deleteCakeDesignService, getCakeDesignsService } from "../../services/userService";
import { useEffect, useState } from "react";
import Pagination from "react-js-pagination";

import SearchIcon from "../../assets/images/search (5).svg";
import CrossIcon from "../../assets/images/cross_icon.svg";
import noPlaceholder from "../../assets/images/no_image_placeholder.jpg";
import { Link } from "react-router-dom";
import { Modal, Table } from "react-bootstrap";


import EditIcon from "../../assets/images/edit (5).svg";
import DeleteIcon from "../../assets/images/trash (1).svg";
import ViewIcon from "../../assets/images/view.svg";


const DesignLibrary = () => {

    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const [totalCount, setTotalCount] = useState('');
    const [list, setList] = useState([]);
    const [showDelete, setShowDelete] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [activeItem, setActiveItem] = useState('');

    const limit = 12;



    const getCakeDesigns = () => {
        let params = {
            name: search,
            limit,
            page,
            search,
            isAdmin: 1
        };

        getCakeDesignsService(params).then(res => {
            if(res.data.statusCode){
                setList(res.data.responseData.result);
                setTotalCount(res.data.responseData.count);
            }
       
        })
    }


    useEffect(() => {
        getCakeDesigns()
    }, [search, page])


    const handleDeleteModal = item => {
        setShowDelete(true);
        setActiveItem(item);
    }

    const handleShowModal = item => {
        setShowModal(true);
        setActiveItem(item);
    }

    const handleDeleteItem = () => {
        deleteCakeDesignService({ _id: activeItem._id }).then((res) => {
            setShowDelete(false)
            getCakeDesigns()
            setPage(1)
        })

    }



    return (
        <div className="page_wrapper">
            <div className="container-box">
                <div className="search_part d-flex align-items-center justify-content-between w-100 mt-3 mb-2">
                    <h4 className="semibold-font mb-0 ms-2">Design Library</h4>
                    <div className="cm_saerch">
                        <img src={SearchIcon} className="search-icon" />
                        <input
                            type="text"
                            placeholder="Search by name"
                            name="search"
                            className="search box-shadow light-italic-font"
                            value={search}
                            onChange={(e) => ((setSearch(e.target.value), setPage(1)))} />
                    </div>
                    <div className="btn_part">
                        <Link to="/design-library/create" className="btn btn-primary btn-sm px-4">Add Design</Link>
                    </div>
                </div>

                <div className="row mt-5">
                    {
                        list.map(item => (
                            <div className="col-lg-4" key={item._id}>
                                <div className="cake_design_card">
                                    <div className="cake_design_banners">
                                        <img onError={(e) => {e.target.onerror = null; e.target.src = noPlaceholder }} src={item.design_url} />
                                    </div>
                                    <div className="cake_design_contents">
                                       <div>
                                            <h5>{item.translated_name}</h5>
                                            {/* <small className="cale_design_cat">{item?.categoryName ? item.categoryName[0] : ''}</small> */}
                                            <p>{item.translated_description}</p>
                                       </div>

                                        <div className="cake_design_actions">
                                            <span onClick={() => handleShowModal(item)} className="dlt-icon"><img src={ViewIcon} alt="view" className="dlt-icon" /></span>
                                            <Link to={`/design-library/edit/${item._id}`}><img src={EditIcon} alt="edit" /></Link>
                                            <img src={DeleteIcon} alt="delete" className="dlt-icon" onClick={() => handleDeleteModal(item)} />
                                            <Link to={`/design-library/lang-edit/${item._id}`} className="btn btn-dark btn-sm ms-3">Language</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                    
                </div>

                {list.length ? <div className="cm_pagination"><Pagination
                    activePage={page}
                    itemsCountPerPage={limit}
                    totalItemsCount={totalCount}
                    pageRangeDisplayed={5}
                    onChange={p => setPage(p)} /></div> : ''}

                


                <Modal show={showDelete} centered>
                    <Modal.Header className="display"></Modal.Header>

                    <Modal.Body>
                        <div className='modal-box'>
                            <div className="text-center">
                                <img src={CrossIcon} alt="cross-icon" />
                                <h5 className="semibold-font mt-3">Are you Sure?</h5>
                                <h6 className="regular-font ">Are you sure you want to delete <br></br>this from the list?</h6>
                                <button className="confirm-btn mt-3 text-white" onClick={handleDeleteItem} >YES,DELETE</button>
                                <div>
                                    <button className="cancel-btn mt-2 medium-font " onClick={() => setShowDelete(false)}>Cancel</button>
                                </div>


                            </div>

                        </div>

                    </Modal.Body>
                </Modal>



                <Modal show={showModal} centered>
                    <Modal.Header className="display"></Modal.Header>
                    <Modal.Body>
                        <div className='modal-box p-0'>
                            <div className="text-center"><img className="img-fluid" onError={(e) => {e.target.onerror = null; e.target.src = noPlaceholder }} src={activeItem?.design_url} /></div>
                            <h5 className="mt-4">{activeItem.translated_name}</h5>
                            {activeItem?.category?.length ? activeItem?.category.map(i => <small key={i} className="cale_design_cat">{i}</small>) : ''}
                            {activeItem?.sub_categories?.length ? activeItem?.sub_categories.map(i => <small key={i} className="cale_design_cat">{i}</small>) : ''}
                            <h5>Product Used: </h5>
                            <p>{activeItem.product_used}</p>
                            <br />
                            <h5>Description: </h5>
                            <p className="text-break">{activeItem.translated_description}</p>
                            <div>
                                <button className="btn btn-dark d-block w-100" onClick={() => setShowModal(false)}>Close</button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                
            </div>
        </div>

    )
}
export default DesignLibrary;