import { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  deleteChefService,
  getPillsburyChefsService,
  updatePillsburyChefsService,
  getCityService,
  getASMListService,
} from "../../services/userService";
import EditIcon from "../../assets/images/edit (5).svg";
import DeleteIcon from "../../assets/images/trash (1).svg";
import { PageLoader } from "../svg";
import ReactPaginate from "react-paginate";
import SearchIcon from "../../assets/images/search (5).svg";
import camera from "../../assets/images/camera.svg";
import Profile from "../../assets/images/profile.png";
import ModalLogo from "../../assets/images/chef (1).png";
import { MultiSelect } from "react-multi-select-component";

import { Modal } from "react-bootstrap";
import CrossIcon from "../../assets/images/cross_icon.svg";
import DeleteModal from "./../../Hooks/DeleteModal";
import { isEmailValid } from "../helper";
import ArrowDown from "../../assets/images/arrow-down.svg";
import ArrowUp from "../../assets/images/arrow-up.svg";
import Dropdown from "./../../Hooks/Dropdown";

const PillsburryChefs = () => {
  const navigate = useNavigate();

  const [list, setList] = useState("");
  const [loader, setLoader] = useState(true);
  const [totalCount, setTotalCount] = useState(1);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [id, setId] = useState("");
  const [search, setSearch] = useState("");
  const limit = 10;

  const [chefName, setChefName] = useState("");
  const [chefNameErr, setChefNameErr] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [tempEmailAddress, setTempEmailAddress] = useState("");

  const [emailAddressErr, setEmailAddressErr] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberErr, setPhoneNumberErr] = useState("");
  const [file, setFile] = useState("");
  const [showImage, setShowImage] = useState("");
  const [show, setShow] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [sortKey, setSortKey] = useState("");
  const [type, setType] = useState("");
  const [recordCount, setRecordCount] = useState(0);
  const [city, setCity] = useState("");
  const [cityErr, setCityErr] = useState("");

  const [cityList, setCityList] = useState([]);

  const [asmName, setAsmName] = useState("");
  const [serviceOptions, setserviceOptions] = useState([]);
  const [ServiceSelected, setServiceSelected] = useState([]);
  const [asmList, setAsmList] = useState("");

  localStorage.removeItem("page");

  const handleAdd = () => {
    navigate("/addchef");
  };

  const handleClose = () => {
    setShow(false);
    setDeleteModal(false);
    setEmailAddressErr("");
    setChefNameErr("");
    setPhoneNumberErr("");
    setAsmName("");
  };

  const getPillsburyChefsList = (query) => {
    if (!query) {
      query = {
        limit: limit,
        page: page,
        sort_key: sortKey,
        type: type,
      };
    }
    getPillsburyChefsService(query).then((res) => {
      let count = res.data.responseData.count;
      setRecordCount(count);

      let numberOfPages = Math.ceil(count / 10);
      setTotalCount(numberOfPages);
      setList(res.data.responseData.data);
      setLoader(false);
    });
  };

  useEffect(() => {
    getPillsburyChefsList();
  }, [page, sortKey, type]);
  useEffect(() => {
    let _intervalTime;
    if (search !== "" && search !== null) {
      _intervalTime = setTimeout(() => {
        getPillsburyChefsList({
          limit: limit,
          page: 1,
          search: search,
        });
      }, 1500);
    } else {
      getPillsburyChefsList({
        limit: limit,
        page: 1,
      });
    }
    return () => {
      clearTimeout(_intervalTime);
    };
    // eslint-disable-next-line
  }, [search]);

  const handlePageClick = (event) => {
    let pageNumber = event.selected + 1;

    setPage(event.selected + 1);
    getPillsburyChefsList({
      limit: limit,
      page: pageNumber,
      // search: search,
      ...(search !== "" && search !== null && { search: search }),
      ...(type !== "" && type !== null && { type: type }),
      ...(sortKey !== "" && sortKey !== null && { sort_key: sortKey }),
    });
  };

  const handleImageChange = (e) => {
    const file = new File([e.target.files[0]], e.target.files[0].name, {
      type: e.target.files[0].type,
    });
    setFile(file);
    setShowImage(window.URL.createObjectURL(e.target.files[0]));
  };

  const handleEdit = (e) => {
    if (e.asm_name) {
      let split = e.asm_name.split(",");


      const serviceArr = [];
      let tempData = split.map((list, i) => {
        serviceArr.push({ label: list, value: list });
      });
      setServiceSelected(serviceArr);

    }

    setId(e._id);
    setShow(true);
    setChefName(e.name);
    setEmailAddress(e.email);
    setPhoneNumber(e.phone_number);
    setShowImage(e.profile_image);
    setCity(e.home_city);

   
  };

  const handleDelete = (e) => {
    setId(e);
    setDeleteModal(true);
  };

  const handleDeleteClick = () => {
    deleteChefService({ _id: id }).then((res) => {
      setDeleteModal(false);
      getPillsburyChefsList();
    });
  };

  const handleEmail = (e) => {
    if (emailAddress !== e.target.value) {
      setTempEmailAddress(e.target.value);
    }
    setEmailAddress(e.target.value);
    setEmailAddressErr("");
  };

  const handleKeyDown = (e) => {
    if (e.key === " " && chefName.length === 0) {
      e.preventDefault();
    }
  };

  const validation = () => {
    let validate = true;

    if (chefName == "") {
      setChefNameErr("Chef Name is required");
      validate = false;
    }
    if (chefName.length < 2) {
      setChefNameErr("Chef Name should be between 2 to 30 ");
      validate = false;
    }
    if (emailAddress == "") {
      setEmailAddressErr("Email Address is required");
      validate = false;
    } else if (!isEmailValid(emailAddress)) {
      setEmailAddressErr("Email Address is invalid");
      validate = false;
    }
    if (phoneNumber == "") {
      setPhoneNumberErr("Phone Number is required");
      validate = false;
    }
    if (city == "") {
      setCityErr("City is required");
      validate = false;
    }

    return validate;
  };

  const handleClick = () => {
    if (validation()) {
      let asmName = [];
      ServiceSelected &&
        ServiceSelected.map((x) => {
          asmName.push(x["label"]);
        });
      asmName.join(",");
      let formData = new FormData();
      formData.set("name", chefName);
      formData.set("profile_image", file);
      if (emailAddress === tempEmailAddress) {
        formData.set("email", emailAddress);
      }
      formData.set("phone_number", phoneNumber);
      formData.set("user_role", "pillsbury_chef");
      formData.set("user_id", id);
      formData.set("home_city", city);
      formData.set("asm_name", asmName);

      updatePillsburyChefsService(formData).then((res) => {
        handleClose();
        getPillsburyChefsList();
      });
    }
  };

  const arrowClick = (sortType, sortOrder) => {
    setSortKey(sortType);
    setType(sortOrder);
  };

  const handlePhone = (e) => {
    const reg = new RegExp("^[0-9]+$");

    if (
      (reg.test(e.target.value) || phoneNumber.length == 1) &&
      e.target.value.length <= 14
    ) {
      setPhoneNumber(e.target.value);
    }
    setPhoneNumberErr("");
  };

  const getCityList = () => {
    getCityService().then((res) => {
      setCityList(res.data.responseData.result);
    });
  };
  const getAsmList = () => {
    getASMListService().then((res) => {
      setAsmList(res.data.responseData.result);
    });
  };

  useEffect(() => {
    getCityList();
    getAsmList();
  }, []);

  useEffect(() => {
    const serviceArr = [];
    asmList &&
      asmList.map((list, i) => {
        serviceArr.push({ label: list.asm_name, value: list._id });
      });
    setserviceOptions(
      serviceArr.sort((a, b) =>
        a.label < b.label ? -1 : a.value < b.value ? 1 : 0
      )
    );
  }, [asmList]);

  const selectedChange = (e) => {
    setServiceSelected(e);
  };

  return (
    <>
      <div className="page_wrapper">
          <div className="container-box">

            <div>
              <div className=" distributor-head">
                <div className="row mt-4">
                  <div className="col-md-4">
                    <h4 className="semibold-font">PILLSBURY CHEFS</h4>
                  </div>

                  <div className="col-md-5 ">
                    <img src={SearchIcon} className="search-icon" />

                    <input
                      type="text"
                      placeholder="Search by name"
                      name="search"
                      className=" search box-shadow light-italic-font"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    ></input>
                  </div>

                  <div className="col-md-2  ">
                    <button
                      className="add-btn text-white box-shadow  medium-font grey-font"
                      onClick={handleAdd}
                    >
                      Add Chefs
                    </button>
                  </div>
                </div>
              </div>
              <div className="table-content">
                <Table className=" box-shadow">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Image</th>

                      <th>
                        Chef Name
                        <span className="arrows pointer">
                          <img
                            src={ArrowUp}
                            alt="arrow-up"
                            height={10}
                            width={10}
                            onClick={() => arrowClick("name", 1)}
                          />

                          <img
                            src={ArrowDown}
                            alt="arrow-down "
                            height={10}
                            width={10}
                            onClick={() => arrowClick("name", -1)}
                          />
                        </span>
                      </th>
                      <th>
                        Email Address
                        <span className="arrows pointer">
                          <img
                            src={ArrowUp}
                            alt="arrow-up"
                            height={10}
                            width={10}
                            onClick={() => arrowClick("email_address", 1)}
                          />

                          <img
                            src={ArrowDown}
                            alt="arrow-down "
                            height={10}
                            width={10}
                            onClick={() => arrowClick("email_address", -1)}
                          />
                        </span>
                      </th>
                      <th>
                        Phone Number
                        <span className="arrows pointer">
                          <img
                            src={ArrowUp}
                            alt="arrow-up"
                            height={10}
                            width={10}
                            onClick={() => arrowClick("phone_number", 1)}
                          />

                          <img
                            src={ArrowDown}
                            alt="arrow-down "
                            height={10}
                            width={10}
                            onClick={() => arrowClick("phone_number", -1)}
                          />
                        </span>
                      </th>
                      <th>City</th>
                      <th>ASM Name</th>

                      <th>Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {list &&
                      list.map((item, index) => (
                        <tr>
                          <td>{limit * pageCount + index + 1}</td>
                          <td>
                            <img
                              src={item.profile_image}
                              width={50}
                              height={40}
                              alt="image"
                              className="circular"
                            />
                          </td>
                          <td>{item.name}</td>
                          <td>{item.email}</td>
                          <td>{item.phone_number}</td>
                          <td>{item.home_city}</td>
                          <td>{item.asm_name}</td>

                          <td>
                            <img
                              src={EditIcon}
                              alt="edit"
                              onClick={() => handleEdit(item)}
                            />
                            <img
                              src={DeleteIcon}
                              alt="delete"
                              className="dlt-icon"
                              onClick={() => handleDelete(item._id)}
                            />
                          </td>
                        </tr>
                      ))}
                    {list?.length ? (
                      ""
                    ) : (
                      <tr className="text-center">
                        <td></td>
                        <td></td>

                        <td className="text-end no_data">
                          <p>No Data Found</p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                {loader ? (
                  <div className="loader_data">
                    <PageLoader />
                  </div>
                ) : (
                  ""
                )}
                {recordCount > limit ? (
                  <ReactPaginate
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={totalCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination "}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                    hrefBuilder={(page, pageCount, selected) =>
                      setPageCount(selected)
                    }
                  />
                ) : null}
              </div>
            </div>
          </div>
      </div>

      {/* Edit Modal */}

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton className="display"></Modal.Header>

        <Modal.Body>
          <div className="modal-box">
            <div className="text-center">
              <img src={ModalLogo} height={30} width={30} />
              <h5 className="semibold-font mt-3">Update Chef</h5>
            </div>
            <div className="add-product-box">
              <div className="image-box image-upload margin-auto">
                <label htmlFor="file-input">
                  {showImage ? (
                    <div className="">
                      <img
                        src={showImage}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = { Profile };
                        }}
                        className="img-preview "
                      />
                    </div>
                  ) : (
                    <div className="camera-img left-margin-20">
                      <img src={camera} className="" />
                    </div>
                  )}
                </label>
                <input
                  id="file-input"
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                />
              </div>
              <h6 className="text-center mt-2">Image size: 160 x 170</h6>

              <input
                type="text"
                placeholder="CHEF NAME"
                className="form-control mt-4 smaller-font"
                value={chefName}
                onKeyDown={handleKeyDown}
                onChange={(e) => (
                  setChefName(e.target.value), setChefNameErr("")
                )}
              ></input>
              <div className="error-message">
                {chefNameErr ? chefNameErr : ""}
              </div>

              <input
                type="text"
                placeholder="EMAIL ADDRESS"
                className="form-control mt-4 smaller-font"
                value={emailAddress}
                onChange={handleEmail}
              ></input>
              <div className="error-message">
                {emailAddressErr ? emailAddressErr : ""}
              </div>

              <input
                type="text"
                placeholder="PHONE NUMBER"
                className="form-control mt-4 smaller-font"
                value={phoneNumber}
                onChange={handlePhone}
              ></input>
              <div className="error-message">
                {phoneNumberErr ? phoneNumberErr : ""}
              </div>
              <Dropdown
                list={cityList}
                title="CITY"
                value={city}
                showValue="city_name"
                bindValue="city_name"
                setSelectedValue={setCity}
                setError={setCityErr}
              ></Dropdown>
              <div className="error-message">{cityErr ? cityErr : ""}</div>

              <MultiSelect
                options={serviceOptions}
                value={ServiceSelected}
                onChange={selectedChange}
                labelledBy="Select"
                className=" mt-4 smaller-font"
              />

              <button
                className="common-btn mt-3  btn-padding2 text-white"
                onClick={handleClick}
              >
                UPDATE
              </button>

              <div></div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Delete Modal */}

      <DeleteModal
        deleteModal={deleteModal}
        title="Chef"
        Icon={CrossIcon}
        handleClose={handleClose}
        handleDeleteClick={handleDeleteClick}
      ></DeleteModal>
    </>
  );
};
export default PillsburryChefs;
