import { Modal } from "react-bootstrap";
const DeleteModal=({deleteModal,title,Icon,handleClose,handleDeleteClick})=>{

    return (
        
        <Modal show={deleteModal}  centered>
        <Modal.Header className="display"></Modal.Header>
        <Modal.Body>
            <div className='modal-box'>
                <div className="text-center">
                    <img src={Icon} alt="cross-icon" />
                    <h5 className="semibold-font mt-3">Are you Sure?</h5>
                    <h6 className="regular-font ">Are you sure you want to delete <br></br>this {title} from the list?</h6>
                    <button className="confirm-btn mt-3 text-white" onClick={handleDeleteClick}>YES,DELETE</button>
                    <div>
                        <button className="cancel-btn mt-2 medium-font " onClick={handleClose}>Cancel</button>
                    </div>
                </div>
            </div>
        </Modal.Body>

    </Modal>
    )
}

export default DeleteModal