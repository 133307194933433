import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import NotificationIcon from "../../assets/images/notification.svg";
import {
  getNotificationService,
  sendNotificationService,
  getUserListService,
  getUsedBakeryService,
  deleteNotificatiobService,
  getRecipeService,
} from "../../services/userService";
import Table from "react-bootstrap/Table";
import DeleteIcon from "../../assets/images/trash (1).svg";
import { PageLoader, EmptyData } from "../svg";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import Dropdown from "./../../Hooks/Dropdown";
import { MultiSelect } from "react-multi-select-component";
import CrossIcon from "../../assets/images/cross_icon.svg";
import DeleteModal from "./../../Hooks/DeleteModal";
import camera from "../../assets/images/camera.svg";
import { type } from "@testing-library/user-event/dist/type";

const Notification = () => {
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [messageErr, setMessageErr] = useState("");
  const [list, setList] = useState("");
  const [loader, setLoader] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);

  const [page, setPage] = useState(1);

  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [users, setUsers] = useState("");
  const [userList, setUserList] = useState();
  const [serviceOptions, setserviceOptions] = useState([]);
  const [ServiceSelected, setServiceSelected] = useState([]);
  const [ServiceSelectedErr, setServiceSelectedErr] = useState("");
  const [recordCount, setRecordCount] = useState(0);

  const [showImage, setShowImage] = useState("");
  const [file, setFile] = useState("");

  const [recipeData, setRecipeData] = useState("");
  const [SelectedValue, setSelectedValue] = useState([]);

  const [recipe, setRecipe] = useState("");
  const [checkedOne, setCheckedOne] = useState(false);
  const[apiCall,setApiCall] = useState(true);

  localStorage.removeItem("page");

  const [id, setId] = useState("");

  const limit = 10;

  const handleAdd = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setMessage("");
    setMessageErr("");
    setServiceSelected([]);
    setDeleteModal(false);
    setServiceSelectedErr("");
    setShowImage("");
    setRecipe("");
    setCheckedOne("");
    setSelectedValue([]);
  };

  const handleKeyDown = (e) => {
    if (e.key === " " && message.length === 0) {
      e.preventDefault();
    }
  };

  const validation = () => {
    let validate = true;
    if (message == "" && checkedOne == false) {
      setMessageErr("Message is required");
      validate = false;
    }
    
    if (ServiceSelected == "") {
      setServiceSelectedErr("User is required");
      validate = false;
    }

    return validate;
  };

  const handleClick = (e) => {
    if(apiCall==true){
      
      setApiCall(false)
      e.preventDefault();
    if (validation()) {
      let ids = [];
      let names = [];
      let recipeId = [];
      if (ServiceSelected.length == serviceOptions.length) {
        names = "All Users";
      } else {
        ServiceSelected &&
          ServiceSelected.map((x) => {
            names.push(x["label"]);
          });
        names = names.join(",");
      }
      ServiceSelected &&
        ServiceSelected.map((x) => {
          ids.push(x["value"]);
        });

        var myData = Object.keys(SelectedValue).map(key => {
          return SelectedValue[key];
      })


      let formData = new FormData();
      formData.set("message", message);
      formData.set("sendTo", names);
      formData.set("ids", ids);
      formData.set("recipe_id", myData[0]);
      formData.set("recipe_banner", file);
      formData.set("send_all",names=="All Users"?1:0);

     
      let checkedOneValue = checkedOne == true ? 1 : 0;

      formData.set("type", checkedOneValue);
      sendNotificationService(formData).then((res) => {
        setApiCall(true);
        
        handleClose();
        getNotifications();
      });
    }

    }
    
  };

  const handleDeleteClick = () => {
    deleteNotificatiobService({ _id: id }).then((res) => {
      setDeleteModal(false);
      getNotifications();
    });
  };

  const getNotifications = () => {
    let query = {
      limit: limit,
      page: page,
    };

    getNotificationService(query).then((res) => {
      let count = res.data.responseData.count;
      setRecordCount(count);

      let numberOfPages = Math.ceil(count / 10);
      setTotalCount(numberOfPages);
      setLoader(false);
      setList(res.data.responseData.result);
    });
  };
  useEffect(() => {
    getNotifications();
  }, [page]);
  const getUsers = () => {
    getUsedBakeryService().then((res) => {
      setUserList(res.data.responseData.data);
    });
  };

  useEffect(() => {
    getUsers();
  }, []);

  const handlePageClick = (event) => {
    setPage(event.selected + 1);
  };
  useEffect(() => {
    const serviceArr = [];
    userList &&
      userList.map((list, i) => {
        if (list.user_role != "admin") {
          serviceArr.push({ label: list.bakeryName, value: list.bakery_id });
        }
      });
    setserviceOptions(
      serviceArr.sort((a, b) =>
        a.label < b.label ? -1 : a.value < b.value ? 1 : 0
      )
    );
  }, [userList]);

  const deleteNotification = (_id) => {
    setDeleteModal(true);
    setId(_id);
  };

  const selectedChange = (e) => {
    setServiceSelected(e);
    setServiceSelectedErr("");
  };

  const handleImageChange = (e) => {
    const file = new File([e.target.files[0]], e.target.files[0].name, {
      type: e.target.files[0].type,
    });
    setFile(file);
    setShowImage(window.URL.createObjectURL(e.target.files[0]));
  };

  const recipeList = () => {
    getRecipeService().then((res) => {
      let response = res.data.responseData.result;

      const sortRecipe = [...response].sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
      );
      let tempData = sortRecipe.map((datum) => {
        return {
          'value':datum._id,
          'label':datum.name
        }
      });


     
      setRecipeData(tempData);
    });
  };

  useEffect(() => {
    recipeList();
  }, []);

  const handleChangeOne = () => {
    let checkedValue = checkedOne == true ? false : true;
    setCheckedOne(checkedValue);
  };
  
  const recipeChange = (e) =>{
    setSelectedValue(e)

  }

  return (
    <>
      <div className="page_wrapper">
          <div className="container-box">

            <div className=" distributor-head">
              <div className="row mt-5">
                <div className="col-md-4">
                  <h4 className="semibold-font">NOTIFICATIONS</h4>
                </div>

                <div className="col-md-5 "></div>

                <div className="col-md-3 ">
                  <button
                    className="add-btn text-white box-shadow add-btn-position medium-font grey-font"
                    onClick={handleAdd}
                  >
                    Send Notification
                  </button>
                </div>
              </div>
            </div>

            <div className="table-content">
              <Table className=" box-shadow common-black-color">
                <thead>
                  <tr className="medium-font font-14px">
                    <th>ID</th>
                    <th>Send to</th>
                    <th>Type</th>
                    <th>Message</th>


                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {list &&
                    list.length > 0 &&
                    list.map((item, index) => (
                      <tr className="regular-font font-14px">
                        <td>{limit * pageCount + index + 1}</td>
                        <td className="">{item.sendTo}</td>
                        <td className="">
                          {item.type == 1 ? "Recipe" : "Admin"}
                        </td>
                        <td className="message_width ">{item.message}</td>


                        <td>
                          <img
                            src={DeleteIcon}
                            alt="delete"
                            className="dlt-icon"
                            onClick={() => deleteNotification(item._id)}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
              {loader ? (
                <div className="loader_data">
                  <PageLoader />
                </div>
              ) : (
                ""
              )}
              {list && list?.length ? (
                ""
              ) : (
                <div className="no_data">
                  <EmptyData /> <p>No Data</p>
                </div>
              )}
            </div>
            {recordCount > limit ? (
              <ReactPaginate
                previousLabel={"previous"}
                nextLabel={"next"}
                breakLabel={"..."}
                pageCount={totalCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={"pagination "}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
                hrefBuilder={(page, pageCount, selected) =>
                  setPageCount(selected)
                }
              />
            ) : null}
          </div>
      </div>

      {/* Send notification modal  */}

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton className="display"></Modal.Header>

        <Modal.Body>
          <div className="modal-box">
            <div className="text-center">
              <img src={NotificationIcon} height={20} width={20} />
              <h5 className="semibold-font mt-3">Send Notification</h5>
            </div>
            <div className="image-box image-upload margin-auto">
              <label htmlFor="file-input">
                {showImage ? (
                  <div className="">
                    <img src={showImage} className="img-preview " />
                  </div>
                ) : (
                  <div className="camera-img left-margin-20">
                    <img src={camera} className="" />
                  </div>
                )}
              </label>
              <input
                id="file-input"
                type="file"
                accept="image/*"
                onChange={handleImageChange}
              />
              {showImage ? (
                ""
              ) : (
                <p className="smaller-font font-light mt-2 text-center">
                  Upload Recipe Banner Image{" "}
                </p>
              )}
            </div>
            <div className="mt-2">
              <label>
                <input
                  type="checkbox"
                  value={checkedOne}
                  onChange={handleChangeOne}
                />
              </label>
              <span className="m-2">Send Recipe Notification</span>
            </div>

          
            <MultiSelect
              options={serviceOptions}
              value={ServiceSelected}
              onChange={selectedChange}
              labelledBy="Select"
              className="mt-3"
            />
            <div className="error-message">
              {ServiceSelectedErr ? ServiceSelectedErr : ""}
            </div>
            <div>
             
           

              <Select
                className=" mt-3 smaller-font options"
                placeholder="RECIPE"
                aria-label="Default select example"
                // onChange={(e) => setRecipe(e.currentTarget.value)}
                onChange = {recipeChange}
                isSearchable = {true}
                options= {recipeData}
                value= {SelectedValue}
               
              >
               
              </Select>
              
            </div>

            <textarea
              type="text"
              id="w3review"
              name="w3review"
              rows="4"
              cols="50"
              placeholder="MESSAGE"
              className="form-control mt-4 smaller-font"
              value={message}
              onKeyDown={handleKeyDown}
              onChange={(e) => (setMessage(e.target.value), setMessageErr(""))}
            ></textarea>
            <div className="error-message">{messageErr ? messageErr : ""}</div>


            <button
              className="add-modal-btn mt-3  btn-padding text-white"
              onClick={handleClick}
            >
              SEND
            </button>
          </div>
        </Modal.Body>
      </Modal>

      {/* Delete Modal */}

      <DeleteModal
        deleteModal={deleteModal}
        title="Notification"
        Icon={CrossIcon}
        handleClose={handleClose}
        handleDeleteClick={handleDeleteClick}
      ></DeleteModal>
    </>
  );
};

export default Notification;
