import { useEffect, useState } from "react";
import Select from "react-select";
import { getProductDashBoardDataService } from "../../services/userService";



const ProductAnalytics = () => {
    const [list, setList] = useState([]);


    const getList = () => {
          getProductDashBoardDataService().then((res) => {
            if(res.data.statusCode){
                setList(res.data.responseData.result);
            }
        });
    };


    useEffect(() => {
        getList()
    }, [])



    return(
        <div className="dash_section mt-5">
            <div className="">
                <div className="d-flex">
                    <h5 className="mb-0 me-2">Product Analytics</h5>
                </div>

                <table className="table table-responsive">
                <thead>
                    <tr className="medium-font font-14px">
                      <th>ID</th>
                      <th>Product Name</th>
                      <th>Product Text View Count</th>
                      <th>Product Text View Average Time (<small>seconds</small>)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {list &&
                      list.map((item, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{item.name}</td>
                          <td>{item.productTextViewCount}</td>
                          <td>{item.productTextViewTime}</td>
                        </tr>
                      ))}
                      {list.length ? '' : <tr><td colSpan={8} className="no_table">No Data</td></tr>}
                  </tbody>

               
                </table>
            </div>

        </div>
    )
}

export default ProductAnalytics;