import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import FilerobotImageEditor, {
    TABS,
    TOOLS,
  } from 'react-filerobot-image-editor';
import { getToolkitDesignDetailByIdServiceWithToken, toolkitDesignFavoriteWithToken, uploadFileServiceWithToken } from "../../services/userService";


const cropOptions = {
    presetsItems: [
      {
        titleKey: 'classicTv',
        descriptionKey: '4:3',
        ratio: 4 / 3,
      },
      {
        titleKey: 'cinemascope',
        descriptionKey: '21:9',
        ratio: 21 / 9,
      },
    ],
    presetsFolders: [
      {
        titleKey: 'socialMedia',
        groups: [
          {
            titleKey: 'facebook',
            items: [
              {
                titleKey: 'profile',
                width: 180,
                height: 180,
                descriptionKey: 'fbProfileSize',
              },
              {
                titleKey: 'coverPhoto',
                width: 820,
                height: 312,
                descriptionKey: 'fbCoverPhotoSize',
              },
            ],
          },
        ],
      },
    ],
  }


const ImageGen = () => {
  const location = useLocation();
  const pathname = location.pathname;

  const pathParts = pathname.split('/');
  const userId = pathParts[2];
  const toolkitDesignId = pathParts[3];
  const token = pathParts[4];
  const lang = pathParts[5];

  const [toolkitDesignData, setToolkitDesignData] = useState();
  const [finalBanner, setBinalBanner] = useState('');
  const [isFav, setIsFav] = useState(false);


  useEffect(() => {
    if(isFav){
      setTimeout(() => {
        setIsFav(false)
      }, 4000)
    }
  }, [isFav])

    function base64toFile(base64String, fileName, mimeType) {
        let arr = base64String.split(',');
        let mime = mimeType || arr[0].match(/:(.*?);/)[1];
        let bstr = atob(arr[1]);
        let n = bstr.length;
        let u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], fileName, { type: mime }); 
    }


    const handleOnSave = async (editedImageObject, designState) => {
        const file = base64toFile(editedImageObject.imageBase64, editedImageObject.fullName, editedImageObject.mimeType);
        const formData = new FormData();
        formData.append("file", file);
        const bannerUploadResponse = await uploadFileServiceWithToken(formData, token);
        let _banner = bannerUploadResponse.data.responseData.file_url;
        setBinalBanner(_banner);
        //window.open(_banner, '_blank');
      };


      const textOptions = {
        fonts: [
            { label: 'Arial', value: 'Arial' },
            { label: 'Courier New', value: 'Courier New' },
            { label: 'Times New Roman', value: 'Times New Roman' },
            { label: 'Roboto', value: 'Roboto' },
            { label: 'Open Sans', value: 'Open Sans' },
        ],
        fontFamilies: [
            'Arial', 'Courier New', 'Times New Roman', 'Roboto', 'Open Sans'
        ],
    };


    const getToolkitDesignDetailById = () => {
      const query = `/?toolkit_id=${toolkitDesignId}`;
      getToolkitDesignDetailByIdServiceWithToken(query, token).then(res => {
       
        if(res?.data?.statusCode){
          setToolkitDesignData(res.data.responseData.data);
        }
      })
    }


    useEffect(() => {
      if(toolkitDesignId){
        getToolkitDesignDetailById()
      }
  
    }, [toolkitDesignId])


    const handleClose = () => {

    }


    const handleFave = () => {
      let params = {
        toolkitDesignId,
        isFavorite: true
      };

      toolkitDesignFavoriteWithToken(params, token).then(res => {
        if(res?.data?.statusCode){
          setIsFav(true)
        }
      })
    }



    const handleShare = () => {
      const data = {
        finalBanner,
        name: toolkitDesignData.name[lang],
        translated_name: toolkitDesignData.name[lang],
        design_url: toolkitDesignData.design_url,
        design_type: toolkitDesignData.design_type,
      };
      if (window.flutter_inappwebview) {
        window.flutter_inappwebview.callHandler('dataHandler', JSON.stringify(data));
      }
    }


    const handleDownload = () => {
      const data = {finalBanner};
      if (window.flutter_inappwebview) {
        window.flutter_inappwebview.callHandler('handleFileDownload', JSON.stringify(data));
      }
    }

    // const handleDownload = async () => {
      
    //   try {
    //     const response = await fetch(finalBanner);
    //     const blob = await response.blob();
    //     const url = URL.createObjectURL(blob);
    
    //     const link = document.createElement('a');
    //     link.href = url;
    //     link.download = 'edited_image.png';
    //     document.body.appendChild(link);
    //     link.click();
    
    //     document.body.removeChild(link);
    //     URL.revokeObjectURL(url);
    //   } catch (error) {
    //     console.error('Error downloading the image:', error);
    //   }
    // };


    useEffect(() => {
      // Wait for the component to mount and then change the tab text
      const intervalId = setInterval(() => {
        const imageTab = document.querySelector('.FIE_image-tool-button .SfxLabel-text');
        if (imageTab) {
          imageTab.textContent = 'Upload Logo';
          clearInterval(intervalId);
        }
      }, 100);
      // Clear the interval if the component unmounts
      return () => clearInterval(intervalId);
    }, []);

      
    

    return (
        <div className="image_gen_wrapper">
            <div className="second_step">
                { toolkitDesignData?.original_design_url ? (
                  <FilerobotImageEditor
                    source={toolkitDesignData?.original_design_url}
                    defaultSavedImageQuality={1}
                    onSave={handleOnSave}
                    onClose={handleClose}
                    annotationsCommon={{fill: '#ff0000'}}
                    Text={textOptions}
                    Rotate={{ angle: 90, componentType: 'slider' }}
                    Crop={cropOptions}
                    tabsIds={[TABS.ADJUST, TABS.ANNOTATE]}
                    defaultTabId={TABS.ANNOTATE}
                    saveOptions={{
                      quality: 1.0
                    }}
                    defaultToolId={TOOLS.TEXT}  />
                ) : ''}

            </div>


            {
              finalBanner ? <div className="final_banner">
                <div className="container mt-0">
                  <img className="final_banner_img" src={finalBanner} />
                  <h3>{toolkitDesignData.name}</h3>
                  
                  <div className="text-center p-3">
                    <small>Your file is ready to download</small>
                    <br />
                    <span onClick={handleDownload} className="btn btn-primary w-100 mt-4">Save to Device</span>
                    <br />
                    <span onClick={handleFave} className="btn btn-outline-primary w-100 mt-4">Save as favourites</span>
                    <br />
                    <span onClick={handleShare} className="btn btn-outline-primary w-100 mt-4">Share</span>
                  </div>
                </div>

                {isFav ? <div className="cm_notif">Mark as favourite</div> : ''}
              </div> : ''
            }
        </div>
    )
}

export default ImageGen;