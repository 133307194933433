import { useEffect, useState } from "react";
import Select from "react-select";
import Table from "react-bootstrap/Table";
import EditIcon from "../../assets/images/edit (5).svg";
import DeleteIcon from "../../assets/images/trash (1).svg";
import { Link } from "react-router-dom";
import { getDesignCategoryListService, updateStatusDesignCategoryService, uploadFileService, createDesignCategoryService, translateContentService, createDesignSubCategoryService, deleteDesignCategoryService } from "../../services/userService";
import { Modal, Spinner } from "react-bootstrap";
import { PageLoader } from "../svg";
import SearchIcon from "../../assets/images/search (5).svg";
import Pagination from "react-js-pagination";
import ArrowDown from "../../assets/images/arrow-down.svg";
import ArrowUp from "../../assets/images/arrow-up.svg";
import CrossIcon from "../../assets/images/cross_icon.svg";
import noPlaceholder from "../../assets/images/no_image_placeholder.jpg";





const DesignCategory = () => {
    const [list, setList] = useState([]);
    const [search, setSearch] = useState('');

    const [actionModal, setActionModal] = useState(false);
    const [subCatModal, setSubCatModal] = useState(false);
    const [activeCat, setActiveCat] = useState(false);
    const [catType, setCatType] = useState('');
    const [category_name, setCategory_name] = useState('');
    const [banner, setBanner] = useState('');
    const [viewBanner, setViewBanner] = useState('');
    const [category_type, setCategory_type] = useState(1);
    const [category_nameErr, setCategory_nameErr] = useState('');
    const [bannerErr, setBannerErr] = useState('');
    const [subcategory_name, setSubcategory_name] = useState('');
    const [subcategory_nameErr, setSubcategory_nameErr] = useState('');
    const [btnLoader, setBtnLoader] = useState(false);
    const [loader, setLoader] = useState(true);
    const [page, setPage] = useState(1);
    const [totalCount, setTotalCount] = useState(1);
    const [id, setId] = useState("");
    const [showDelete, setShowDelete] = useState(false);
    localStorage.removeItem('page');
    const limit = 10;



    const getCategoryList = () => {
        let query = `?category_name=${search}&limit=${limit}&page=${page}&category_type=${catType.value || ''}`
        getDesignCategoryListService(query).then((res) => {
            if(res.data.statusCode){
                setTotalCount(res.data.responseData.count);
                setList(res.data.responseData.result)
            }
            setLoader(false)
        })
    }

    useEffect(() => {
        getCategoryList()
    }, [page, search, catType])



    const deleteCategory = (e) => {
        setId(e)
        setShowDelete(true)
    }


    const handleDelete = () => {
        deleteDesignCategoryService({ _id: id }).then((res) => {
            handleClose()
            getCategoryList()
            setPage(1)
        })
    }

    const handleClose = () => {
        setActionModal(false);
        setSubCatModal(false);
        setActiveCat(false);
        setShowDelete(false);
        setCategory_name('');
        setBanner('');
        setViewBanner('');
        setCategory_type(1);
        setCategory_nameErr('');
        setBannerErr('');
        setSubcategory_name('');
        setSubcategory_nameErr('');

    }


 


  


    const handleFileChange = e => {
        let reader = new FileReader();
        let file = e.target.files[0];
        setBanner(file);
        setBannerErr('');
        
        reader.addEventListener('load', () => {
            setViewBanner(reader.result);
        }, false);
        if (file) { reader.readAsDataURL(file) }
    }


    const handleValidate = () => {
        let validate = true;
    
        if (!category_name) {
            setCategory_nameErr('Name is required');
            validate = false;
        }
    
        // if (!viewBanner) {
        //     setBannerErr('Banner is required');
        //     validate = false;
        // }
    
        return validate;
    }

    const handleValidate2 = () => {
        let validate = true;
    
        if (!subcategory_name) {
            setSubcategory_nameErr('Name is required');
            validate = false;
        }
    
        return validate;
    }




    const handleSubmit = async () => {
        if (handleValidate()) {
            try {
                setBtnLoader(true);

                let _banner = '';

                if(viewBanner){
                    const formData = new FormData();
                    formData.set("file", banner);
                    const bannerUploadResponse = await uploadFileService(formData);
                    _banner = bannerUploadResponse.data.responseData.file_url;
                }
                const categoryNames_res = await translateContentService({ text: category_name });
                const _translated_name = categoryNames_res.data.responseData.data;
                const params = {
                    category_name,
                    banner: _banner,
                    category_type,
                    translated_name: JSON.stringify(_translated_name)
                };
                const createCategoryResponse = await createDesignCategoryService(params);
                if (createCategoryResponse.data.statusCode) {
                    setBtnLoader(false);
                    handleClose();
                    getCategoryList();
                }
            } catch (error) {
                console.error("Error:", error);
            }
        }
    };


    const handleSubmit2 = async () => {
        if (handleValidate2()) {
            try {
                setBtnLoader(true);
                const translated_name_res = await translateContentService({ text: subcategory_name });
                const _translated_name = translated_name_res.data.responseData.data;
                const params = {
                    subcategory_name,
                    category_id: activeCat._id,
                    translated_name: JSON.stringify(_translated_name)
                };
                const createSubCategoryResponse = await createDesignSubCategoryService(params);
                if (createSubCategoryResponse.data.statusCode) {
                    setBtnLoader(false);
                    handleClose();
                    getCategoryList();
                }
            } catch (error) {
                console.error("Error:", error);
            }
        }
    };


    const handleStatusChange = (item, index) => {
        const params = {_id: item._id, status: (item.status == 1) ? 0 : 1, category_type: item.category_type};
        
        let _list = [...list];
        _list[index].status = item.status == 1 ? 0 : 1;
        _list[index].loader = true;
        setList(_list);
        updateStatusDesignCategoryService(params).then(res => {
            getCategoryList();
        })

        
        
    }


    return (
        <>

            <div className="page_wrapper">
                    <div className="container-box">

                        <div className="search_part d-flex align-items-center justify-content-between w-100 mt-3 mb-2">
                            <h4 className="semibold-font mb-0 ms-2">Design Category</h4>
                            <div className="cm_saerch">
                                <img src={SearchIcon} className="search-icon" />
                                <input
                                    type="text"
                                    style={{minWidth: '300px'}}
                                    placeholder="Search by category name"
                                    name="search"
                                    className="search box-shadow light-italic-font"
                                    value={search}
                                    onChange={e => (setSearch(e.target.value), setPage(1))} />
                            </div>

                            <div className="lang_select">
                                <Select
                                    options={[{label: 'All', value: ''}, {label: 'Product Category', value: 1}, {label: 'Seasonal Collection', value: 2}, {label: 'Flavoured Category', value: 3}]}
                                    value={catType}
                                    placeholder="Filter by Type"
                                    onChange={e => (setCatType(e), setPage(1))} />
                            </div>
                            <div className="btn_part">
                                <Link to="/design-category/create" className="btn btn-primary btn-sm px-4">Add Category</Link>
                            </div>
                        </div>


                        <Table className="table box-shadow">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Banner</th>
                                    <th>Category Name</th>
                                    <th>Type</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {list && list.map((item, index) => (
                                    <tr key={index}>
                                        <td><span>{((index + 1) + ((page - 1) * limit))}</span></td>
                                        <td>
                                            <img height={40} width={50} onError={(e) => {e.target.onerror = null; e.target.src = noPlaceholder }} src={item.banner} />
                                        </td>
                                        <td>{item.category_name}</td>
                                        <td>
                                            {item.category_type == 1 ? 'Product Category' : ''}
                                            {item.category_type == 2 ? 'Seasonal Collection' : ''}
                                            {item.category_type == 3 ? 'Flavoured Category' : ''}
                                        </td>

                                        <td>
                                            <div className="d-flex align-center position-relative">
                                                <Link to={`/design-category/edit/${item._id}`}><img src={EditIcon} alt="edit" /></Link>
                                                <img src={DeleteIcon} alt="delete" className="dlt-icon" onClick={() => deleteCategory(item._id)} />
                                                <Link to={`/design-category/lang-edit/${item._id}`} className="btn btn-dark btn-sm ms-3">Edit Language</Link>
                                                <span className="position-relative d-inline-block">
                                                <div onClick={() => item.loader ? null : handleStatusChange(item, index)} style={{right: 0}} className="pos cm_swich_abs position-relative left-0 top-0 d-inline-block ms-3">
                                                    <input
                                                        checked={item.status == 1}
                                                        className="switch-checkbox position-absolute w-100 h-100 z-2"
                                                        type="checkbox"/>
                                                    <label
                                                        style={{ background: item.status == 1 ? '#06D6A0' : 'gray' }}
                                                        className="switch-label"
                                                        htmlFor={`switch`}>
                                                        <span className={`switch-button`} />
                                                    </label>
                                                </div>
                                                <b className="status_loader">{item.loader ? 'Loading' : ''}</b>
                                                </span>
                                            </div>
                                        </td>

                                    </tr>
                                ))}
                                  {list?.length ? "" :
                                            <tr className="text-center">
                                                <td colSpan="5" className="text-end no_data">
                                                    <p className="text-center">No Data Found</p>
                                                </td>
                                            </tr>
                                        }
                            </tbody>

                        </Table>
                        {loader ? <div className='loader_data'><PageLoader /></div> : ""}

                        {list.length ? <div className="cm_pagination"><Pagination
                            activePage={page}
                            itemsCountPerPage={limit}
                            totalItemsCount={totalCount}
                            pageRangeDisplayed={5}
                            onChange={p => setPage(p)} /></div> : ''}
                        

                    </div>
            </div>

            <Modal show={actionModal} onHide={handleClose} centered>
                <Modal.Header closeButton className="display"></Modal.Header>
                <Modal.Body>
                    <div className='modal-box'>

                    <div className="text-center">
                    <h5 className="semibold-font mb-4">
                        {id ? "Update" : "Add"} Design Category
                    </h5>
                    </div>

                        <div className="form-group">
                            <p className="d-flex justify-content-between align-items-center">
                                <span>
                                    <input
                                        type="radio"
                                        name="category_type"
                                        checked={category_type == 1}
                                        onChange={(e) => setCategory_type(category_type == 1 ? 2 : 1)} />
                                        <b className="ms-2">Design Category</b>
                                </span>
                                <span>
                                    <input
                                        type="radio"
                                        name="category_type"
                                        checked={category_type == 2}
                                        onChange={(e) => setCategory_type(category_type == 1 ? 2 : 1)} />
                                        <b className="ms-2">Seasonal Collection</b>
                                </span>
                            </p>
                        </div>

                        <div className="form-group">
                            <label className="mb-2">Category Name</label>
                            <input
                                type="text"
                                value={category_name}
                                onChange={(e) => (setCategory_name(e.target.value), setCategory_nameErr(''))}
                                className="form-control" />

                                {category_nameErr ? <div className="error-message">{category_nameErr}</div> : ''}

                            
                        </div>

                        <div className="form-group">
                            <label className="mb-2">Banner</label>
                            <input
                                type="file"
                                onChange={handleFileChange}
                                className="form-control" />

                            {bannerErr ? <div className="error-message">{bannerErr}</div> : ''}
                        </div>

                        {viewBanner ? <img src={viewBanner} className="view_banner" /> : ''}


                        <div className="d-flex justify-content-between align-items-center">
                            <span className="btn btn-dark" onClick={handleClose}>Cancel</span>
                            <span className="btn btn-primary" onClick={btnLoader ? null : handleSubmit}>{btnLoader ? <Spinner className="spiner" animation="border" /> : ''} Save</span>
                        </div>

                    </div>
                </Modal.Body>
            </Modal>



            <Modal show={subCatModal} onHide={handleClose} centered>
                <Modal.Header closeButton className="display"></Modal.Header>
                <Modal.Body>
                    <div className='modal-box'>

                    <div className="text-center">
                    <h5 className="semibold-font mb-4">
                        Add Sub Category
                    </h5>
                    </div>

                        

                        <div className="form-group">
                            <label className="mb-2">Sub Category Name</label>
                            <input
                                type="text"
                                value={subcategory_name}
                                onChange={(e) => (setSubcategory_name(e.target.value), setSubcategory_nameErr(''))}
                                className="form-control" />

                                {subcategory_nameErr ? <div className="error-message">{subcategory_nameErr}</div> : ''}
                            
                        </div>

                        <div className="d-flex justify-content-between align-items-center">
                            <span className="btn btn-dark" onClick={handleClose}>Cancel</span>
                            <span className="btn btn-primary" onClick={btnLoader ? null : handleSubmit2}>{btnLoader ? <Spinner className="spiner" animation="border" /> : ''} Save</span>
                        </div>

                    </div>
                </Modal.Body>
            </Modal>




            <Modal show={showDelete} centered>
                <Modal.Header className="display"></Modal.Header>

                <Modal.Body>
                    <div className='modal-box'>
                        <div className="text-center">
                            <img src={CrossIcon} alt="cross-icon" />
                            <h5 className="semibold-font mt-3">Are you Sure?</h5>
                            <h6 className="regular-font ">Are you sure you want to delete <br></br>this Category from the list?</h6>
                            <button className="confirm-btn mt-3 text-white" onClick={handleDelete} >YES,DELETE</button>
                            <div>
                                <button className="cancel-btn mt-2 medium-font " onClick={handleClose}>Cancel</button>
                            </div>


                        </div>

                    </div>

                </Modal.Body>
            </Modal>

        </>

    )
}


export default DesignCategory;