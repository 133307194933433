import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { createLogger } from "redux-logger";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

import recipeSlice from "./recipe";
import productSlice from "./product";

const persistConfig = {
  key:'root',
  storage,
};

const reducers = combineReducers({ recipe: recipeSlice,
product:productSlice,
});

const persistedReducer = persistReducer(persistConfig, reducers);
const loggerMiddleware = createLogger();

export default configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(loggerMiddleware),
});
