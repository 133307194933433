import { Link, useNavigate, useParams } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import Select from "react-select";

import {
    uploadFileService,
    updateToolkitDesignCategoryService,
    updateStatusToolkitDesignCategoryService,
    getToolkitDesignCategoryDetailService,
    translateContentService,
    createToolkitDesignCategoryService,
} from "../../services/userService";
import { Spinner } from "react-bootstrap";

const colorOptions = [
    { label: 'Black', value: '#000000' },
    { label: 'White', value: '#ffffff' },
    { label: 'Red', value: '#ff0000' },
    { label: 'Green', value: '#00ff00' },
    { label: 'Blue', value: '#0000ff' },
    { label: 'Yellow', value: '#ffff00' },
    { label: 'Purple', value: '#800080' },
    { label: 'Orange', value: '#ffa500' },
];

const CreateMarkingToolkitDesignCategory = (key) => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [category_name, setCategory_name] = useState('');
    const [category_nameErr, setCategory_nameErr] = useState('');
    const [banner, setBanner] = useState('');
    const [viewBanner, setViewBanner] = useState('');
    const [category_type, setCategory_type] = useState(1);
    const [text_color, setText_color] = useState('');
    const [show_text, setShow_text] = useState({label: 'Yes', value: true});
    const [bannerErr, setBannerErr] = useState('');
    const [btnLoader, setBtnLoader] = useState(false);
    const [subCats, setSubCats] = useState([]);
    const [status, setStatus] = useState('');



    const handleFileChange = e => {
        let reader = new FileReader();
        let file = e.target.files[0];
        setBanner(file);
        setBannerErr('');
        
        reader.addEventListener('load', () => {
            setViewBanner(reader.result);
        }, false);
        if (file) { reader.readAsDataURL(file) }
    }

    const handleValidate = () => {
        let validate = true;
    
        if (!category_name) {
            setCategory_nameErr('Name is required');
            validate = false;
        }

        if(subCats.length){
            let _temp = [...subCats].map(item => ({...item, subcategory_nameErr: !item.subcategory_name ? 'Name is required' : ''}));
            let errRes = _temp.filter(item => item.subcategory_nameErr);
            if(errRes.length){
                setSubCats(_temp);
                validate = false;
            }
        }
    
        return validate;
    }

    const handleSubmit = async () => {
        if (handleValidate()) {
            try {
                setBtnLoader(true);

                let _banner = '';

                if(banner){
                    const formData = new FormData();
                    formData.set("file", banner);
                    formData.set("folderName", 'toolkit-category');
                    const bannerUploadResponse = await uploadFileService(formData);
                    _banner = bannerUploadResponse.data.responseData.file_url;
                }
                const categoryNames_res = await translateContentService({ text: category_name });
                const _translated_name = categoryNames_res.data.responseData.data;

                let trans_sub_cats = [];

                for (const _subCat of subCats) {
                    const res = await translateContentService({ text: _subCat.subcategory_name });
                    trans_sub_cats.push({
                        translated_name: JSON.stringify(res.data.responseData.data),
                        subcategory_name: _subCat.subcategory_name,
                        _id: _subCat._id
                    });
                }
                const params = {
                    category_name,
                    category_type,
                    translated_name: JSON.stringify(_translated_name),
                    subCat: JSON.stringify(trans_sub_cats)
                };

                // if(category_type == 2){
                //     params.show_text = show_text.value;
                //     if(text_color?.value){
                //         params.text_color = text_color.value;
                //     }else{
                //         params.text_color = 'black';
                //     }
                    
                // }

                if(_banner){
                    params.banner = _banner;
                }

                if(id){
                    params._id = id
                }else{
                    params.status = 0;
                }


                let _service = id ? updateToolkitDesignCategoryService : createToolkitDesignCategoryService;
                const _res = await _service(params);
                setBtnLoader(false);
                if (_res.data.statusCode) {
                    navigate("/marking-toolkit-design-category");
                }
            } catch (error) {
                console.error("Error:", error);
            }
        }
    };


    const categoryDetails = () => {
        let query = `/?category_id=${id}`
        getToolkitDesignCategoryDetailService(query).then((res) => {
            let _res = res.data.responseData.data;
            setViewBanner(_res.banner);
            setCategory_name(_res.category_name);
            // if(_res.text_color){
            //     let _colorVal = colorOptions.find(i => i.value == _res.text_color)
            //     setText_color(_colorVal);
            // }
            setCategory_type(_res.category_type);

            // if(_res.show_text){
            //     setShow_text({label: 'Yes', value: true});
            // }else{
            //     setShow_text({label: 'No', value: false});
            // }

           
            setStatus(_res.status);
            if(_res?.sub_category?.length){
                let _temp = _res?.sub_category.map(j => ({...j, subcategory_name2: j.subcategory_name}))

                setSubCats(_temp);
            } 
        })
    }

    useEffect(() => {
        if(id){
            categoryDetails()
        }
    }, [id])


   


    const handleStatusChange = async () => {
        setStatus(status == 1 ? 2 : 1);
        const params = {_id: id, status: status == 1 ? 2 : 1, category_type};
        const _res = await updateStatusToolkitDesignCategoryService(params);
    }



    return (
        <>
            <div className="page_wrapper">
                    <div className="container-box">

                        <div className="d-flex justify-content-between align-items-center">
                            <h4 className="semibold-font mt-5">{id ? "Update" : "Create"} Campaign</h4>
                        </div>


                       
                        <div className="tab-box position-relative">

                            {id ? <div className="cm_swich_abs">
                                    <input
                                        checked={status == 1}
                                        onChange={handleStatusChange}
                                        className="switch-checkbox"
                                        id={`switch`}
                                        type="checkbox"/>
                                    <label
                                        style={{ background: status == 1 ? '#06D6A0' : 'gray' }}
                                        className="switch-label"
                                        htmlFor={`switch`}>
                                        <span className={`switch-button`} />
                                    </label>

                                    <small className="text-center d-block"><b>{status == 1 ? 'Active' : 'Inactive'}</b></small>
                            </div> : ''}


                            <div className="add-product-box">
                                <div className="row">
                                    <div className="col-md-9">

                                    <div className="form-group">
                                        <p className="d-flex align-items-center">
                                            <span>
                                                <input
                                                    type="radio"
                                                    name="category_type"
                                                    checked={category_type == 1}
                                                    onChange={(e) => (setCategory_type(1))} />
                                                    <b className="ms-2">Offers</b>
                                            </span>
                                            <span className="ms-5">
                                                <input
                                                    type="radio"
                                                    name="category_type"
                                                    checked={category_type == 2}
                                                    onChange={(e) => (setCategory_type(2))} />
                                                    <b className="ms-2">Occasion</b>
                                            </span>
                                            <span className="ms-5">
                                                <input
                                                    type="radio"
                                                    name="category_type"
                                                    checked={category_type == 3}
                                                    onChange={(e) => (setCategory_type(3))} />
                                                    <b className="ms-2">Flavour Festival</b>
                                            </span>
                                            {/* <span className="ms-5">
                                                <input
                                                    type="radio"
                                                    name="category_type"
                                                    checked={category_type == 4}
                                                    onChange={(e) => (setCategory_type(4))} />
                                                    <b className="ms-2">Products Used</b>
                                            </span> */}
                                        </p>
                                    </div>

                                    
                                        <div className="form-group">
                                            <label className="mb-2">Name</label>
                                            <input
                                                type="text"
                                                placeholder="Name"
                                                className="form-control product-box mb-0" 
                                                value={category_name}
                                                onChange={(e) => (setCategory_name(e.target.value), setCategory_nameErr(''))} />
                                                
                                                {category_nameErr ? <div className="error-message">{category_nameErr}</div> : ''}
                                        </div>

                                        {/* {
                                            category_type == 2 ? <div className="form-group">
                                            <label className="mb-2">Text Color</label>
                                            <Select
                                               className="product-box mb-0"
                                                options={colorOptions}
                                                value={text_color}
                                                onChange={e => setText_color(e)} />
                                        </div> : ''
                                        }

                                        {
                                            category_type == 2 ? <div className="form-group">
                                            <label className="mb-2">Show Category Name</label>
                                            <Select
                                               className="product-box mb-0"
                                                options={[{label: 'Yes', value: true}, {label: 'No', value: false}]}
                                                value={show_text}
                                                onChange={e => setShow_text(e)} />
                                        </div> : ''
                                        } */}


                                    <Fragment>
                                        <div className="form-group">
                                            <label>Upload Template</label>
                                            <input
                                                multiple
                                                className="form-control product-box mb-0"
                                                onChange={handleFileChange}
                                                type="file" />
                                        </div>

                                        {viewBanner ? <div className="desgin_banner">
                                                <img className="img-fluid" src={viewBanner} />
                                            </div> : ''}
                                        </Fragment>

                                        <div className="mt-5">
                                            <Link className="btn btn-dark me-4 px-5" to="/marking-toolkit-design-category">CANCEL</Link>
                                            <span className="btn btn-primary me-4 px-5" onClick={btnLoader ? null : handleSubmit}>{btnLoader ? <Spinner className="spiner" animation="border" /> : ''} Save</span>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
            </div>
        </>
    )
}
export default CreateMarkingToolkitDesignCategory;