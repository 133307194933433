import { Link, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import SideBar from '../sidebar';
import Header from '../header';
import { getProductByIdService } from '../../services/userService';

import TickMark from "../../assets/images/tick.svg";
import { parseHMTLData } from '../../utils';



const ProductDetail = () => {
    let { id } = useParams();

    const [productData, setProductData] = useState('');
    const [feature, setFeature] = useState([]);
    const [ingredients, setIngredients] = useState("");
    const [recipeIngredient, setRecipeIngredient] = useState([]);
    const [nutritionInfo, setNutritionInfo] = useState("");
    const [preparation, setPreparation] = useState([]);
    const [baking_temperature, setTaking_temperature] = useState("");
    const [baking_time, setBaking_time] = useState("");



    const getProductDetailById = () => {
        let params = {productId: id}
        
        getProductByIdService(params).then(res => {
            if(res.data.statusCode){
                setProductData(res.data.responseData.result);
                setFeature(res?.data?.responseData?.result?.features_details?.en || res?.data?.responseData?.result?.features_details);
                setIngredients(res?.data?.responseData?.result?.ingredients?.en || res?.data?.responseData?.result?.ingredients);
                setRecipeIngredient(res?.data?.responseData?.result?.recipe_ingredients?.en || res?.data?.responseData?.result?.recipe_ingredients);
                setNutritionInfo(res?.data?.responseData?.result?.nutrition_information);
                setPreparation(res?.data?.responseData?.result?.preparation?.data?.en || res?.data?.responseData?.result?.preparation?.data);
                setTaking_temperature(res?.data?.responseData?.result?.preparation?.baking_temperature);
                setBaking_time(res?.data?.responseData?.result?.preparation?.baking_time);
            }
        });
    }

    useEffect(() => {
        if(id){
            getProductDetailById(); 
        }
    }, [id])


    return (
        <div className="page_wrapper">
            <SideBar />
            <div className="container-box">
                <Header />
                <div className="mt-5">
                    <h4 className="semibold-font"> PRODUCT DETAIL</h4>

                    <div className='page_box'>
                        
                        <h3 className='page_box_title'>Product</h3>

                        <div className='row pb-5'>
                            <div className='col-lg-3'>
                                <img width={160} src={productData?.product_image}/>
                            </div>
                            <div className='col-lg-9'>
                                <h5 className="semibold-font">{productData?.name} <span className="shape left-margin-30 white-text">{productData?.dietary}</span></h5>
                                <h6>{productData?.categoryName}</h6>
                                <h6 className="grey-text">Net Weight</h6>
                                <h6>Available in {productData?.net_weight}</h6>
                                <h5 className="semibold-font">Rs.{productData?.price}</h5>
                                <p>{productData?.product_desc?.en || productData?.product_desc?.en}</p>
                                <Link className="cancel-btn white-text next-cancel-btn mt-3 text-decoration-none" to="/products">CANCEL</Link>
                            </div>
                        </div>

                        <hr />

                        <h3 className='page_box_title'>Features</h3>

                        <div className='row pb-5'>
                            <div className='col-lg-12'>
                                <ul>
                                    {
                                        feature.length ? feature?.map((item, i)=>
                                            <li key={i}>
                                                <img src={TickMark} className="tick-icon" alt={'_feature_' + i} />
                                                <span className="product-benefit-text">{item || item.desc}</span>
                                            </li>
                                        ) : ''
                                    }
                                </ul>
                            </div>
                        </div>

                        <hr />

                        <h3 className='page_box_title'>Ingredients</h3>

                        <div className='row pb-5'>
                            <div className='col-lg-12'>
                                <p>{parseHMTLData(ingredients)}</p>
                            </div>
                        </div>

                        <hr />

                        <h3 className='page_box_title'>Recipe Ingredients</h3>

                        <div className='row pb-5'>
                            <div className='col-lg-12'>
                            {recipeIngredient?.length > 0 ? (
                                <ul>
                                {recipeIngredient?.map((item, i) => (
                                    <li key={i} className='recipe_white'>
                                        <span className="product-benefit-text font-black">{item?.desc || item}</span> 
                                        <span className="product-benefit-text font-black">{item?.weight}</span>
                                    </li>
                                ))}
                                </ul>
                            ) : null}
                            </div>
                        </div>

                        <hr />


                        <h3 className='page_box_title'>Nutrition Information</h3>

                        <div className='row pb-5'>
                            <div className='col-lg-6'>
                                <div className="nutri_info">
                                    <div className="row_view border-b big-text">
                                        <p>*Approximate Values</p>
                                        <p>Per 100 g*</p>
                                    </div>
                                    <p className="t-r"><b>Product</b></p>
                                    <div className="row_view big-header border-b">
                                        <p>Energy (kcal)</p>
                                        <p>{nutritionInfo?.energy}</p>
                                    </div>
                                    <div className="row_view heading">
                                        <p>Total Fat</p>
                                        <p>{nutritionInfo?.totalFat}</p>
                                    </div>
                                    {nutritionInfo?.totalFatList?.map((item)=>{
                                        return(
                                            <div className="row_view inner-heading " key={'total_fat_'+item?.id}>
                                            <p>{item.desc?.en}</p>
                                            <p>{item.value}</p>
                                        </div>
                                        )
                                    })}
                                    <div className="row_view heading">
                                        <p>Carbohydrate</p>
                                        <p>{nutritionInfo?.carbohydrate}</p>
                                    </div>
                                    {nutritionInfo?.carbohydrateList && nutritionInfo?.carbohydrateList?.map((carboitem,index)=>{
                                        return(
                                            <div className="row_view inner-heading " key={'carbohydrate_'+carboitem.id}>
                                            <p>{carboitem?.desc?.en}</p>
                                            <p>{carboitem?.value}</p>
                                        </div>
                                        )
                                    })
                                    }
                                
                                    <div className="row_view heading">
                                        <p>Protein</p>
                                        <p>{nutritionInfo?.protein}</p>
                                    </div>
                                    {nutritionInfo?.proteinList&&  nutritionInfo?.proteinList?.map((proteinItem,index)=>{
                                        return(
                                            <div className="row_view inner-heading " key={'protein_'+proteinItem.id}>
                                            <p>{proteinItem.desc?.en}</p>
                                            <p>{proteinItem.value}</p>
                                        </div>
                                        )
                                    })
                                    }
                                    <div className="row_view heading  border-b">
                                    <p>Sodium</p>
                                    <p>{nutritionInfo?.sodium}</p>
                                    </div>
                                    {nutritionInfo?.sodiumList&&  nutritionInfo?.sodiumList?.map((sodiumItem,index)=>{
                                        return(
                                            <div className="row_view inner-heading " key={'sodium_'+sodiumItem.id}>
                                            <p>{sodiumItem.desc?.en}</p>
                                            <p>{sodiumItem.value}</p>
                                        </div>
                                        )
                                    })
                                    }


                                    <p>* The % Daily Value (DV) tells you how much a nutrient in a serving of food contributes to a daily diet. 2,000 calories a day is used for general nutrition advice.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <hr />


                        <h3 className='page_box_title'>Preparation</h3>

                            <div className='row pb-5'>
                                <div className='col-lg-12'>
                                    <div className="head_desc">Baking Temperature: <span>{baking_temperature}</span></div>
                                        <div className="head_desc">Baking Time: <span>{baking_time}</span></div>
                                        <div className="preparation_parent">
                                            <ol>
                                                {preparation.length > 0 && Array.isArray(preparation) && preparation?.map((item,index)=>{
                                                    return(
                                                        <li key={index}>{item?.desc || item}</li>
                                                    )
                                                })}
                                        
                                            </ol>
                                        </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ProductDetail;